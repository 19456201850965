<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Договоры страхования</div>
        <div class="hr"></div>


        <div v-if="contracts.length === 0 && docs.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список договоров пуст" />
        </div>

        <div v-else>
            <ui-button @click="openList" class="btn-ghost btn-select mt-4" :loading="loadingFilter">

                <div class="row">
                    <div class="col px-0">{{ titleSelect }}</div>
                    <div class="col-auto text-end pe-0">
                        <UpIcon v-if="listShow" class="icon" />
                        <DownIcon v-else class="icon" />
                    </div>
                </div>

            </ui-button>

            <div v-if="listShow" class="scroll select-menu">
                <ul class="select-menu-list">
                    <li v-for="item in contracts" @click="setItem(item)" class="select-menu-item">
                        {{ item['ДЛ'] }}
                    </li>
                </ul>
            </div>

            <div v-if="listShow" @click="openList" class="bg-modal"></div>


            <div v-if="contract.length !== 0 && docs.length === 0" class="position-relative no-list-data">
                <ui-no-data-page text="Список договоров пуст" />
            </div>

            <div v-else>

                <div v-if="contract.length !== 0" class="scroll table-responsive mt-3">
                    <table class="table text-nowrap mb-0">
                        <thead>

                            <tr>
                                <th colspan="7">Список полисов по договору № {{ titleSelect }}</th>
                            </tr>
                            <tr>
                                <th>Полис</th>
                                <th>Срок действия</th>
                                <th>Договор лизинга</th>
                                <th>Объект страхования/VIN</th>
                                <th>Менеджер</th>
                                <th>Менеджер по пролонгации</th>
                            </tr>
                        </thead>
                        <tbody>

                            <template v-for="item in contract">
                                <tr>
                                    <td colspan="7">№ {{ item['ПолисНомер'] }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ item['СтраховаяКомпания'] }} <br /> {{ item['ПолисТип'] }}
                                    </td>

                                    <td>
                                        {{ dateMask(item['ПериодС']) }} - {{ dateMask(item['ПериодПо'])
                                        }}
                                    </td>
                                    <td>
                                        {{ item['ДЛ'] }}
                                    </td>

                                    <td>
                                        {{ item['Объект'] }} <br /> {{ item['VIN'] }}
                                    </td>

                                    <td>
                                        {{ item['МенАванп'] }}
                                    </td>

                                    <td>
                                        {{ item['МенПрол'] }}
                                    </td>

                                </tr>
                            </template>

                        </tbody>
                    </table>
                </div>


                <div v-if="docs.length !== 0">
                    <div class="page-title">Документы по страхованию для скачивания</div>
                    <div class="hr"></div>


                    <div class="scroll table-responsive mt-4">
                        <table class="table text-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Наименование</th>
                                    <th>Дата</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item, index in docs">
                                    <td style="width: 70%;">
                                        {{ item['Наименование'] }}
                                    </td>
                                    <td>
                                        {{ dateMask(item['Дата']) }}
                                    </td>
                                    <td class="text-end">
                                        <ui-button @click="download(index, 'da', item['Код'])"
                                            :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                            class="btn-base btn-small">Скачать</ui-button>
                                    </td>

                                </tr>



                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

    </div>


    <ui-modal v-if="modal.msg" :close="modalClose">
        <div class="modal-header">
            <h5 class="modal-title">{{ modal.title }}</h5>
            <button class="btn-close" type="button" @click="modalClose"></button>
        </div>
        <div class="modal-body">
            {{ modal.msg }}
        </div>
        <div class="modal-footer"></div>
    </ui-modal>
</template>



<script>
import { getApi, dateMask, moneyMask, downloadApi } from '@/run'

import DownIcon from "@/assets/svg/down.vue"
import UpIcon from "@/assets/svg/up.vue"

export default {
    name: 'contracts.contracts',
    data() {
        return {
            loading: false,
            loadingFilter: false,
            listShow: false,
            contracts: [],
            contract: [],
            docs: [],
            modal: {},
            titleSelect: null,
            dateMask: dateMask
        }
    },
    components: {
        DownIcon,
        UpIcon
    },
    created() {
        document.title = "Договоры страхования - STONE-XXI"

        this.start()
    },
    computed: {},
    methods: {

        start() {
            this.loading = true

            getApi('/insurance', {}).then(response => {

                if (response.status) {
                    this.contracts = response.contracts
                    this.contract = response.contract
                    this.docs = response.docs
                    this.titleSelect = response.dl
                }

                this.loading = false
            })
        },
        openList() {
            this.listShow = !this.listShow
        },
        setItem(item) {

            this.listShow = false
            this.loadingFilter = true

            getApi('/insuranceItem', {
                dl: item['ДЛ'],
                pref: item.Pref
            }).then(response => {

                if (response.status) {
                    this.contract = response.contract
                    this.docs = response.docs
                    this.titleSelect = item['ДЛ']
                }

                this.loadingFilter = false
            })
        },
        progress(btn, progress) {
            this.docs = this.docs.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        download(btn, type, file) {

            this.docs = this.docs.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })

            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progress).then(response => {

                this.docs = this.docs.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        modalClose() {
            this.modal = {}
        }
    }
}
</script>