import root from '@/router/middleware/root'

import MainIndex from '@/views/main'
import Main from '@/views/main/Main.vue'
import New from '@/views/main/New.vue'
import Help from '@/views/main/Help.vue'
import Maintenance from '@/views/main/Maintenance.vue'

const routes = {
    path: '/main',
    component: MainIndex,
    meta: {
        middleware: [
            root
        ]
    },
    children: [{
        path: '/main',
        name: 'main',
        component: Main,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/new',
        name: 'new',
        component: New,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/help',
        name: 'main.help',
        component: Help,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/maintenance',
        name: 'main.maintenance',
        component: Maintenance,
        meta: {
            middleware: [
                root
            ]
        }
    }]
}



export default routes