<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Бухгалтерские документы</div>

        <div class="hr mb-4"></div>

        <div v-if="getBdOpen" class="login-alert p-3 text-center">
            <div class="text-primary">Внимание!</div>
            <span>По всем вопросам бухгалтерской документации просим обратиться к специалистам по телефону <a
                    href='tel:+74959811990'>+7 495 981 19 90</a></span>
        </div>

        <div v-else>


            <!-- <div  v-if="dlListEva.length > 0" class="alert alert-info mb-4">
                В этом разделе сейчас не отображаются документы по ООО «Стоун Развитие», ведется разработка!
            </div> -->

            <div v-if="main?.eva.length > 0" class="alert alert-info mb-4">
                В этом разделе сейчас не отображаются документы по ООО «Стоун Развитие», ведется разработка!
            </div>


            <ui-button @click="openList" class="btn-ghost btn-select" :loading="loadingFilter">

                <div class="row">
                    <div class="col px-0">{{ yearMonthItem.year }} {{ yearMonthItem.month }}</div>
                    <div class="col-auto text-end pe-0">
                        <UpIcon v-if="listShow" class="icon" />
                        <DownIcon v-else class="icon" />
                    </div>
                </div>

            </ui-button>

            <div v-if="listShow" class="scroll select-menu">
                <ul class="select-menu-list">
                    <li v-for="item in listYearMonth" @click="setYearMonth(item)" class="select-menu-item">
                        {{ item.year }} {{ item.month }}
                    </li>
                </ul>
            </div>

            <div v-if="listShow" @click="openList" class="bg-modal"></div>

            <div v-if="zip.length !== 0">
                <div v-for="item, index in zip" class="mt-2">

                    <ui-button @click="downloadZip(index, 'zip', item.title)"
                        :progress="item.progress ? item.progress : 0" :loading="item.loading"
                        :disabled="item.loading || loadingFilter" class="btn-base btn-small">Скачать
                        все
                        документы «{{
                            item.titleTd }}» в одном архиве</ui-button>
                </div>
                <div class="mt-2">


                    <ui-button @click="downloadZipAll('zipAll')" :progress="progressZipAll ? progressZipAll : 0"
                        :loading="loadingZipAll" :disabled="loadingZipAll || loadingFilter"
                        class="btn-base btn-small">Скачать
                        все документы в одном
                        архиве</ui-button>
                </div>

            </div>


            <div>
                <div class="page-title">Документы</div>
                <div class="hr"></div>
            </div>


            <div class="scroll table-responsive mt-4">
                <table class="table text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>ДЛ</th>
                            <th>Документ</th>
                            <th>Дата</th>
                            <th>Номер</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, index in list.filter((e, i) => i >= (10 * itemListFilter)).slice(0, 10)">
                            <td>{{ item['ДЛ'] }}</td>
                            <td>{{ item.titleTd }}</td>
                            <td>{{ dateMask(item['Дата']) }}</td>
                            <td>{{ item['Номер'] }}</td>
                            <td class="text-end">
                                <ui-button
                                    @click="downloadDoc(item['Код'] + item.title, item.title, item['Код'], item['ДЛ'])"
                                    :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                    class="btn-base btn-small">Скачать</ui-button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <Pagination class="mt-3" :page="setListPage" :item="itemListPage" :total="list.length"
                :loading="loadingListPage" />

            <div v-if="accounts.length !== 0">

                <div class="page-title">Счета на пени</div>

                <div class="hr"></div>

                <div class="scroll table-responsive mt-4">
                    <table class="table text-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Номер</th>
                                <th>Сумма</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr v-for="item, index in accounts">
                                <td>{{ dateMask(item['Дата']) }}</td>
                                <td>П{{ item['НомерЧисло'] }}</td>
                                <td>{{ moneyMask(item['Сумма']) }}</td>
                                <td class="text-end">
                                    <ui-button @click="downloadScp(index, 'scp', item['Код'])"
                                        :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                        class="btn-base btn-small">Скачать</ui-button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <Pagination class="mt-3" :page="accountsPage" :item="itemAccountsPage" :total="accountsTotal"
                    :loading="loadingAccountsPage" />

            </div>


            <div v-if="powersOfAttorney.length !== 0">

                <div class="page-title">Доверенности</div>

                <div class="hr"></div>

                <div class="scroll table-responsive mt-4">
                    <table class="table text-nowrap mb-0">
                        <thead>
                            <tr>
                                <th>Подписант</th>
                                <th>Документ</th>
                                <th>Срок действия</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr v-for="item, index in powersOfAttorney">
                                <td>{{ item['Подписант'] }}</td>
                                <td>№ {{ item['Номер'] }} от {{ dateMask(item['ДатаДокумента']) }}</td>
                                <td>{{ dateMask(item['ДатаС']) }} - {{ dateMask(item['ДатаПо']) }}</td>
                                <td class="text-end">
                                    <ui-button @click="downloadDov(index, 'bdov', item['Код'])"
                                        :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                        class="btn-base btn-small">Скачать</ui-button>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <Pagination class="mt-3" :page="powersOfAttorneyPage" :total="powersOfAttorneyTotal"
                    :item="itemPowersOfAttorneyPage" :loading="loadingPowersOfAttorneyPage" />



            </div>

        </div>

    </div>

    <ui-modal v-if="modal.msg" :close="modalClose">
        <div class="modal-header">
            <h5 class="modal-title">{{ modal.title }}</h5>
            <button class="btn-close" type="button" @click="modalClose"></button>
        </div>
        <div class="modal-body">
            {{ modal.msg }}
        </div>
        <div class="modal-footer"></div>
    </ui-modal>
</template>

<script>
import { getApi, dateMask, moneyMask, downloadApi } from '@/run'

import { mapGetters, mapState } from "vuex";

import DownIcon from "@/assets/svg/down.vue"
import UpIcon from "@/assets/svg/up.vue"

import Pagination from '@/component/pagination'

export default {
    name: 'accounting.photofine',
    data() {
        return {
            dlListEva: [],
            loading: false,
            loadingPage: false,
            loadingFilter: false,
            loadingZipAll: false,
            progressZipAll: null,
            list: [],
            itemListPage: 0,
            itemListFilter: 0,
            itemAccountsPage: 0,
            loadingListPage: false,
            loadingAccountsPage: false,
            zip: [],
            accounts: [],
            accountsTotal: null,
            powersOfAttorney: [],
            powersOfAttorneyTotal: null,
            powersOfAttorneyShow: false,
            itemPowersOfAttorneyPage: 0,
            loadingPowersOfAttorneyPage: false,
            listShow: false,
            listYearMonth: [],
            yearMonthItem: null,
            dateMask: dateMask,
            moneyMask: moneyMask,
            modal: {}
        }
    },
    components: {
        DownIcon,
        UpIcon,
        Pagination
    },
    created() {
        document.title = "Бухгалтерские документы - STONE-XXI"

        this.start()
    },
    computed: {
        ...mapGetters({
            getBdOpen: 'main/getBdOpen'
        }),
        ...mapState({
            main: 'main'
        })
    },
    methods: {
        start() {

            if (!this.getBdOpen) {

                this.loading = true

                getApi('/accounting/documentation', {}).then(response => {

                    if (response.status) {
                        this.list = response.list
                        this.listYearMonth = response.listYearMonth
                        this.yearMonthItem = response.yearMonthItem

                        this.accounts = response.accounts
                        this.accountsTotal = response.accountsTotal
                        this.powersOfAttorney = response.powersOfAttorney
                        this.powersOfAttorneyTotal = response.powersOfAttorneyTotal

                        this.dlListEva = response.dlListEva ? response.dlListEva : []

                        response.list.forEach(element => {
                            if (!this.zip.find(e => e.titleTd === element.titleTd)) {
                                this.zip = [...this.zip, { titleTd: element.titleTd, title: element.title }]
                            }
                        });
                    }

                    this.loading = false

                })

            }

        },
        openList() {

            this.listShow = !this.listShow

        },
        setYearMonth(item) {

            this.listShow = false
            this.loadingFilter = true
            this.powersOfAttorneyShow = false

            getApi('/accounting/documentation', {
                year: item.year,
                monthNumber: item.monthNumber
            }).then(response => {

                this.zip = []

                if (response.status) {
                    this.list = response.list
                    this.yearMonthItem = item
                    this.itemListPage = 0
                    this.itemListFilter = 0
                    this.itemPowersOfAttorneyPage = 0
                    this.powersOfAttorney = response.powersOfAttorney
                    this.powersOfAttorneyTotal = response.powersOfAttorneyTotal

                    response.list.forEach(element => {
                        if (!this.zip.find(e => e.titleTd === element.titleTd)) {
                            this.zip = [...this.zip, { titleTd: element.titleTd, title: element.title }]
                        }
                    });
                }

                this.loadingFilter = false

            })

        },
        setListPage(page) {
            this.loadingListPage = true
            this.itemListPage = page

            setTimeout(() => {
                this.loadingListPage = false
                this.itemListFilter = page
            }, 300)
        },
        powersOfAttorneyPage(page) {

            this.loadingPowersOfAttorneyPage = true
            this.itemPowersOfAttorneyPage = page

            getApi('/accounting/powersOfAttorneyPage', {
                year: this.yearMonthItem.year,
                monthNumber: this.yearMonthItem.monthNumber,
                offset: page
            }).then(response => {

                if (response.status) {
                    this.powersOfAttorney = response.powersOfAttorney
                }

                this.loadingPowersOfAttorneyPage = false

            })
        },
        accountsPage(page) {

            this.loadingAccountsPage = true
            this.itemAccountsPage = page

            getApi('/accounting/accountsPage', {
                offset: page
            }).then(response => {

                if (response.status) {
                    this.accounts = response.accounts
                }

                this.loadingAccountsPage = false

            })
        },
        progressDov(btn, progress) {
            this.powersOfAttorney = this.powersOfAttorney.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        downloadDov(btn, type, file) {

            this.powersOfAttorney = this.powersOfAttorney.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })

            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progressDov).then(response => {

                this.powersOfAttorney = this.powersOfAttorney.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        progressScp(btn, progress) {
            this.accounts = this.accounts.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        downloadScp(btn, type, file) {

            this.accounts = this.accounts.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })

            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progressScp).then(response => {

                this.accounts = this.accounts.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        progressDoc(btn, progress) {
            this.list = this.list.map((item, i) => item['Код'] + item.title === btn ? { ...item, progress: progress } : { ...item })
        },
        downloadDoc(btn, type, file, dl) {

            this.list = this.list.map((item, i) => item['Код'] + item.title === btn ? { ...item, loading: true } : { ...item })

            downloadApi('/download', {
                type: type,
                file: file,
                dl: dl
            }, btn, this.progressDoc).then(response => {

                this.list = this.list.map((item, i) => item['Код'] + item.title === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        progressZip(btn, progress) {
            this.zip = this.zip.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        downloadZip(btn, type, file) {

            this.zip = this.zip.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })

            downloadApi('/download', {
                type: type,
                file: file,
                year: this.yearMonthItem.year,
                monthNumber: this.yearMonthItem.monthNumber
            }, btn, this.progressZip).then(response => {

                this.zip = this.zip.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        progressZipAllFuc(btn, progress) {
            this.progressZipAll = progress
        },
        downloadZipAll(type) {

            this.loadingZipAll = true

            downloadApi('/download', {
                type: type,
                year: this.yearMonthItem.year,
                monthNumber: this.yearMonthItem.monthNumber,
                file: true
            }, true, this.progressZipAllFuc).then(response => {

                this.loadingZipAll = false
                this.progressZipAll = false

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        modalClose() {
            this.modal = {}
        }
    }
}
</script>