export default function guest({ next, store }) {

    if (store.state.main.root) {
        return next({
            name: 'main'
        })
    }

    return next()


}