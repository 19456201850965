import root from '@/router/middleware/root'

import ContractsIndex from '@/views/contracts'
import Contracts from '@/views/contracts/Contracts.vue'
import Contract from '@/views/contracts/Contract.vue'

const routes = {
    path: '/contracts',
    name: 'contracts',
    component: ContractsIndex,
    meta: {
        middleware: [
            root
        ]
    },
    children: [{
        path: '/contracts',
        name: 'contracts.contracts',
        component: Contracts,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/contracts/:id',
        name: 'contracts.contract',
        component: Contract,
        meta: {
            middleware: [
                root
            ]
        }
    }]
}

export default routes