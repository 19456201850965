<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Уведомления</div>
        <div class="hr"></div>


        <!-- <div class="search-filter mt-4">

            <div class="search-filter__item">
                <ui-button :white="true" @click="setFilter('new')" class="search-filter__link"
                    :loading="loadingFilter && filter === 'new'" :disabled="loadingFilter"
                    :class="filter === 'new' && 'active'">
                    Непрочитанные <span v-if="newTotal !== 0">({{ newTotal }})</span>
                </ui-button>
            </div>

            <div class="search-filter__item">
                <ui-button :white="true" @click="setFilter(null)" class="search-filter__link"
                    :loading="loadingFilter && !filter" :disabled="loadingFilter" :class="!filter && 'active'">
                    Архив <span v-if="total !== 0">({{ total }})</span>
                </ui-button>
            </div>

        </div> -->


        <div v-if="notifications.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список уведомлений пуст" />
        </div>

        <div v-else>

            <div class="scroll table-responsive mt-4">
                <table class="table mb-0">
                    <thead>
                        <tr class="text-nowrap">
                            <th>Дата уведомления</th>
                            <th>Тема</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, index in notifications">
                            <td class="text-nowrap">{{ dateMask(item.date) }}</td>
                            <td class="text-truncate" :class="item.readed || getNotificationRead === item.id ? '' : 'fw-bolder'"
                                style="max-width: 350px;" :title="item.subject">
                                {{ item.subject }}
                            </td>
                            <td class="text-end text-nowrap">
                                <!-- <RouterLink @click="clickScroll()" class="btn btn-base btn-small"
                                    :to="'/notifications/' + item.id">Подробнее
                                </RouterLink> -->
                                <ui-button @click="view(item.id)" :progress="false" :loading="item.loading"
                                    :disabled="item.loading" class="btn-base btn-small">Подробнее</ui-button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div v-if="!pageBtn && total > notifications.length" class="text-center mt-4">
                <ui-button @click="getPage" :loading="loadingPage" class="btn-ghost">Загрузить еще</ui-button>
            </div>

            <div v-if="pageBtn === 'new' && newTotal > notifications.length" class="text-center mt-4">
                <ui-button @click="getPage" :loading="loadingPage" class="btn-ghost">Загрузить еще</ui-button>
            </div>

        </div>
    </div>


    <ui-modal v-if="notificationId" :notification="true" :close="() => notificationId = null">
        <div class="modal-header">
            <h5 class="modal-title">{{ notification.subject }}</h5>
            <button class="btn-close" @click="notificationId = null" type="button"></button>
        </div>
        <div id="print" class="modal-body">
            <div class="mb-3">Дата уведомления: {{ dateMask(notification.date) }}</div>
            <div v-html="notification.message"></div>
        </div>
        <div class="modal-footer">
            <ui-button v-if="!notification.readed" @click="read(notificationId)" :loading="loadingRead"
                :disabled="loadingRead" class="btn-base me-2">Прочитано</ui-button> <ui-button @click="print"
                class="btn-ghost">Печать</ui-button>
        </div>
    </ui-modal>
</template>


<script>
import { getApi, postApi, dateMask, moneyMask } from '@/run'

import { mapMutations, mapGetters } from 'vuex'

import './style.css'

export default {
    name: 'notifications.notifications',
    data() {
        return {
            notificationLoading: false,
            loading: false,
            loadingFilter: false,
            loadingPage: false,
            filter: 'new',
            pageBtn: 'new',
            notifications: [],
            notification: {},
            loadingRead: false,
            total: 0,
            newTotal: 0,
            notificationId: null,
            dateMask: dateMask,
            moneyMask: moneyMask
        }
    },
    components: {},
    created() {
        document.title = "Уведомления - STONE-XXI"

        this.start()
    },
    computed: {
        ...mapGetters({
            getNotifications: 'page/getNotifications',
            getNotificationsFilter: 'page/getNotificationsFilter',
            getNotificationsPageBtn: 'page/getNotificationsPageBtn',
            getNotificationsScroll: 'page/getNotificationsScroll',
            getNotification: 'main/getNotification',
            getNotificationRead: 'main/getNotificationRead',
        })
    },
    methods: {
        ...mapMutations({
            setNotifications: 'page/setNotifications',
            setNotificationsFilterCash: 'page/setNotificationsFilter',
            setNotificationsPageBtn: 'page/setNotificationsPageBtn',
            setNotificationsScroll: 'page/setNotificationsScroll',
            setNotificationTotal: 'main/setNotificationTotal'
        }),
        print() {
            let printf = document.getElementById('notification').contentWindow
            printf.postMessage({ ...this.notification })
            setTimeout(() => printf.print(), 300)
        },
        view(id) {
            

            this.notifications = this.notifications.map(d => d.id === id ? { ...d, loading: true } : d)

            getApi('/notification', {
                id: id
            }).then(response => {

                if (response.status) {

                    this.notificationId = id
                    this.notification = response.notification ? response.notification : {}
                }

                if (response.error) {
                    this.error = response.error
                }

                this.notifications = this.notifications.map(d => d.id === id ? { ...d, loading: false } : d)

            })
        },

        read(id) {
            this.loadingRead = true

            postApi('/notificationRead', {
                id: id
            }).then(response => {

                this.notificationId = null

                if (response.status) {
                    this.setNotificationTotal(response.newTotal)
                    this.notifications = this.notifications.map(d => d.id === id ? { ...d, readed: true } : d)
                }


                this.loadingRead = false
            })
        },
        start() {

            this.loading = true

            if (this.getNotifications.length === 0) {
                getApi('/notifications', {
                    filter: this.filter
                }).then(response => {


                    if (response.status) {
                        this.notifications = response.notifications
                        this.total = response.total
                        this.newTotal = response.newTotal
                        this.setNotifications(response)
                    }

                    this.loading = false
                })
            } else {
                this.filter = this.getNotificationsFilter

                if (this.getNotifications.status) {

                    this.notifications = this.getNotifications.notifications
                    this.total = this.getNotifications.total
                    this.newTotal = this.getNotifications.newTotal
                    this.pageBtn = this.getNotificationsPageBtn

                    setTimeout(() => window.scrollTo(0, this.getNotificationsScroll), 0)

                }


                this.loading = false


            }

        },
        setFilter(value) {
            this.filter = value
            this.loadingFilter = true
            this.setNotificationsFilterCash(this.filter)
            getApi('/notifications', {
                filter: value
            }).then(response => {


                if (response.status) {
                    this.notifications = response.notifications
                    this.pageBtn = value
                    this.newTotal = response.newTotal
                    this.total = response.total
                    this.setNotificationsPageBtn(value)
                    this.setNotifications(response)
                    this.setNotificationsScroll(0)
                }

                this.loadingFilter = false

            })
        },
        getPage() {
            this.loadingPage = true

            getApi('/notifications', {
                filter: this.filter,
                offset: this.notifications.length
            }).then(response => {


                if (response.status) {
                    this.notifications = [...this.notifications, ...response.notifications]
                    this.setNotifications({ ...this.getNotifications, notifications: this.notifications })
                }

                this.loadingPage = false

            })
        },
        clickScroll() {
            this.setNotificationsScroll(window.scrollY)
        }
    }
}
</script>