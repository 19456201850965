import axios from 'axios'
import store from '@/store'
import FileSaver from 'file-saver'

const homeUrl = '//dev.asket.ru/GitLkApi/public/lkDev'
// const homeUrl = '//lc.stone-xxi.ru/api_v2/GitLkApi/public/lk'

const controller = new AbortController();

const getApi = async (url, params) => {

    return await axios({
        method: 'get',
        url: homeUrl + url,
        params: params,
        headers: {
            'Content-Type': 'application/json'
        },
        signal: controller.signal,
        withCredentials: true
    }).then(response => {
        return response.data
    }).catch(error => {
        if (error.response.status === 403) {
            store.commit('main/setRoot', false)
            return error.response.data
        }
    });
}

const postApi = async (url, data) => {

    return await axios({
        method: 'post',
        url: homeUrl + url,
        data: data,
        headers: {
            'Content-Type': 'application/json'
        },
        signal: controller.signal,
        withCredentials: true
    }).then(response => {
        if (response.status === 200) {
            return response.data
        }
    }).catch(error => {
        if (error.response.status === 403) {
            store.commit('main/setRoot', false)
            return error.response.data
        }
    });
}

const downloadApi = async (url, params, btn, progress) => {

    return await axios({
        method: 'get',
        url: homeUrl + url,
        params: params,
        headers: {
            'Content-Type': 'application/json'
        },
        signal: controller.signal,
        withCredentials: true,
        responseType: 'blob',
        onDownloadProgress: (response) => {
            progress(btn, Math.ceil(response.progress * 100))
        }
    }).then(async response => {

        if (response.headers['content-type'] === 'application/json') {

            return await response.data.text()

        } else if (response.data !== '' && response.status === 200) {

            let filename = response.headers['content-disposition'].split('filename=')[1].replace(/['"«»]/g, '')
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            FileSaver.saveAs(blob, filename);
            return { status: true }

        } else {

            return await (response.data).text()

        }
    }).catch(error => {
        if (error.response.status === 403) {
            store.commit('main/setRoot', false)
            return error.response.data
        }
    });
}

const uploadApi = async (url, data, btn, progress) => {

    return await axios({
        method: 'post',
        url: homeUrl + url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (response) => {
            progress(btn, Math.floor(response.progress * 99))
        },
        signal: controller.signal,
        withCredentials: true
    }).then(response => {
        if (response.status === 200) {
            return response.data
        }
    }).catch(error => {
        if (error.response.status === 403) {
            // store.commit('main/setRoot', false)
            // return error.response.data
        }
    });
}

const dateMask = (value) => {
    const date = new Date(value)
    return date && value ? ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() : 'Дата не указана'
}

const moneyMask = (value) => {
    const sum = Number(Number(value.replace(',', '.')).toFixed(2)).toLocaleString().split(",")
    const sum1 = sum[0] ? sum[0] : '0'
    const sum2 = sum[1] ? sum[1].padEnd(2, '0') : '00'
    return sum1 + '.' + sum2
}

const moneySrtToNumber = (value) => {
    console.log(Number(value.replace(',', '.')));
    
    return Number(value)
}

export { getApi, postApi, downloadApi, uploadApi, dateMask, moneyMask, moneySrtToNumber }