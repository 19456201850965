<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Счета на пени</div>
        <div class="hr"></div>


        <div v-if="list.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список cчета на пени пуст" />
        </div>


        <div v-else>

            <div class="scroll table-responsive mt-4">
                <table class="table text-nowrap mb-0">
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Номер</th>
                            <th class="text-end">Сумма</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr v-for="item, index in list">
                            <td>{{ dateMask(item['Дата']) }}</td>
                            <td>П{{ item['НомерЧисло'] }}</td>
                            <td class="text-end">{{ moneyMask(item['Сумма']) }}</td>
                            <td class="text-end">
                                <ui-button @click="download(index, 'scp', item['Код'])"
                                    :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                    class="btn-base btn-small">Скачать</ui-button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <Pagination class="mt-3" :page="page" :item="itemPage" :total="ListTotal" :loading="loadingPage" />

        </div>


    </div>


    <ui-modal v-if="modal.msg" :close="modalClose">
        <div class="modal-header">
            <h5 class="modal-title">{{ modal.title }}</h5>
            <button class="btn-close" type="button" @click="modalClose"></button>
        </div>
        <div class="modal-body">
            {{ modal.msg }}
        </div>
        <div class="modal-footer"></div>
    </ui-modal>
</template>

<script>
import { getApi, dateMask, moneyMask, downloadApi } from '@/run'

import Pagination from '@/component/pagination'

export default {
    name: 'request.accounting',
    data() {
        return {
            loading: false,
            loadingPage: false,
            list: [],
            itemPage: 0,
            ListTotal: 0,
            listPage: 0,
            dateMask: dateMask,
            moneyMask: moneyMask,
            modal: {}
        }
    },
    components: {
        Pagination
    },
    created() {
        document.title = "Счета на пени - STONE-XXI"

        this.start()
    },
    methods: {
        start() {
            this.loading = true

            getApi('/debt', {}).then(response => {

                if (response.status) {
                    this.list = response.list
                    this.ListTotal = response.ListTotal
                }

                this.loading = false

            })
        },
        page(page) {

            this.loadingPage = true
            this.itemPage = page

            getApi('/debt', {
                filter: this.filter,
                offset: page
            }).then(response => {

                if (response.status) {
                    this.list = response.list
                    this.ListTotal = response.ListTotal
                }

                this.loadingPage = false

            })
        },
        progress(btn, progress) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        download(btn, type, file) {

            this.list = this.list.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })

            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progress).then(response => {

                this.list = this.list.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        modalClose() {
            this.modal = {}
        }
    }
}
</script>