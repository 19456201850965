import Input from "./Input.vue"
import InputPassword from "./InputPassword.vue"
import InputBox from "./InputBox.vue"
import InputBoxFloating from "./InputBoxFloating.vue"
import InputLabel from "./InputLabel.vue"
import Textarea from "./Textarea.vue"

export default [
    Input,
    InputPassword,
    InputBox,
    InputBoxFloating,
    InputLabel,
    Textarea
]