export const mainModule = {
    state: {
        loading: true,
        root: false,
        companies: [],
        companyId: null,
        bdOpen: false,
        email: null,
        pm: [],
        notificationTotal: 0,
        notification: false,
        notificationRead: null
    },
    getters: {
        getLoading(state) {
            return state.loading
        },
        getRoot(state) {
            return state.root
        },
        getCompanies(state) {
            return state.companies
        },
        getCompanyId(state) {
            return state.companyId
        },
        getCompany(state) {
            return state.companies.find(item => item.id === state.companyId)
        },
        getEmail(state) {
            return state.email
        },
        getPm(state) {
            return state.pm
        },
        getBdOpen(state) {
            return state.bdOpen
        },
        getNotificationTotal(state) {
            return state.notificationTotal
        },
        getNotification(state) {
            return state.notification
        },
        getNotificationRead(state) {
            return state.notificationRead
        }

    },
    mutations: {
        setRoot(state, data) {
            state.root = data
        },
        setLoading(state, data) {
            state.loading = data
        },
        setNotificationTotal(state, data) {
            state.notificationTotal = data
        },
        setNotification(state, data) {
            state.notification = data
        },
        setNotificationRead(state, data) {
            state.notificationRead = data
        },
        setData(state, data) {
            state.root = data.status
            state.companies = data.companies
            state.companyId = data.companyId
            state.email = data.email
            state.bdOpen = data.bdOpen
            state.pm = data.pm
            state.notificationTotal = data.notificationTotal
            state.notification = data.notification
            state.eva = data.eva
        }
    },
    actions: {
    },
    modules: {
    },
    namespaced: true
}