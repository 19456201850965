<template>
  <div class="form-group" :class="{ 'has-error': error }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ui-input-box',
  props: {
    error: String
  }
}
</script>