<template>
    <div class="login-page">
        <div class="login-page__content" style="width: 100%;">
            <div class="login-page__inner">
                <div class="login-inner">
                    <div class="d-flex justify-content-center">
                        <router-link to="/">
                            <Logo />
                        </router-link>
                    </div>
                    <Login />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "@/assets/svg/LogoLogin.vue"

import Login from "./Login.vue"

export default {
    components: {
        Logo,
        Login
    }
}
</script>