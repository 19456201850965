<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Контакты</div>
        <div class="hr"></div>

        <div v-if="contacts.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список контактов пуст" />
        </div>

        <div v-else class="row">


            <div class="col-lg-6 mt-4" v-for="item, index in contacts">
                <div class="manager-item">
                    <img v-if="item.loadingImg" class="manager-item__img"
                        :src="'https://lk.stone-xxi.ru/img/images/' + item.photo" />
                    <img v-else class="manager-item__img" :src="noFoto" />
                    <div class="manager-item__body mt-3">
                        <div class="h5">{{ item.F }} {{ item.I }} {{ item.O }}</div>
                        <div class="text-secondary mb-3">{{ item.jt }}</div>
                        <div class="d-flex align-items-center line-height-1_2 mb-2">
                            <i class="icon-tel me-2"></i>{{ item.phone }}
                        </div>
                        <a class="d-flex align-items-center line-height-1_2" :href="'mailto:' + item.email">
                            <i class="icon-mail me-2"></i>{{ item.email }}</a>
                    </div>
                </div>
            </div>

        </div>

        <div class="hr mt-4"></div>

        <div class="mt-4">

            <div class="manager-item">

                <div class="manager-item__img">
                    <a :href="'https://wa.me/79099810118?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5'"
                        target="_blank"> <img class="manager-item__img" :src="'https://lc.stone-xxi.ru/img/qr.png'" />
                    </a>
                </div>

                <div class="manager-item__body" style="height: 130px;">

                    <div class="page-center">
                        <div class="page-center-box">

                            <div class="h5">
                                Получить ответ на интересующий вопрос теперь можно в WhatsApp.
                            </div>
                            <div>
                                Отсканируйте QR-код телефоном или <a
                                    :href="'https://wa.me/79099810118?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5'"
                                    target="_blank">напишите нам</a> через веб-версию WhatsApp.
                            </div>

                        </div>
                    </div>


                </div>

            </div>

        </div>

    </div>
</template>

<script>
import axios from 'axios'
import noFoto from '@/assets/images/no-foto-avatar.png'

export default {
    name: 'accounting.contacts',
    data() {
        return {
            loading: false,
            contacts: [],
            noFoto: noFoto
        }
    },
    components: {},
    created() {
        document.title = "Контакты - STONE-XXI"

        this.start()
    },
    methods: {
        start() {
            this.loading = true

            axios.post('//dev.asket.ru/api/contacts', {
                id: 4
            }).then(response => {

                this.contacts = response.data

                this.contacts.forEach((item, index) => {
                    this.loadingImg(index, item.photo)
                })

                this.loading = false

            })
        },
        loadingImg(index, image) {
            const loadImage = new Image()
            loadImage.src = 'https://lk.stone-xxi.ru/img/images/' + image
            loadImage.onload = () => {

                this.contacts = this.contacts.map((item, i) => i === index ? { ...item, loadingImg: true } : item)

            }
        }
    }
}
</script>