<template>
    <div class="old_site">
        Добро пожаловать на бета-версию сайта
        <ui-button @click="old" :loading="loadingOld" :disabled="loadingOld" class="btn btn-base btn-small">Старая
            версия</ui-button>
        <!-- <button class="btn btn-base btn-small">Старая версия</button> -->
    </div>
    <header class="header">
        <router-link to="/" class="header-logo">
            <Logo class="login-inner__logo" />
        </router-link>

        <div class="header-inner">
            <div class="d-xl-none ms-auto d-flex align-items-center">
                <div class="menu-bar js-menu-bar" @click="openMenu">
                    <span class="menu-bar__stick"></span>
                    <span class="menu-bar__stick"></span><span class="menu-bar__stick"></span>
                </div>
            </div>
            <div class="header-alert">
                <!-- <div class="header-alert__status"><b class="text-danger fz-16">Важная информация!</b>
                    <div class="text-secondary">12 декабря 2020 14:35</div>
                </div>
                <div class="header-alert__info"><a href=""><b>О субсидии Минпромторга</b></a>
                    <div class="header-alert__info-title">В связи с отказом Минпромторга в предоставленни государственной
                        субсидии очень длинный текст прям очень длинный</div>
                </div> -->
            </div>
            <div class="header-lk text-end">
                <div class="header-lk__title  pt-2 mb-1">
                    Добро пожаловать в личный кабинет, {{ getCompanies.filter(item => item.id === companyId &&
                        item)[0].company }}!
                    <!-- <a class="icon-user ms-2" href=""></a> -->
                </div>
                <div class="d-flex">
                    <div class="form-group flex-grow-1">
                        <select class="form-control form-control-base form-select" @change="company" v-model="companyId">
                            <option v-for="item in getCompanies" :value="item.id">{{ item.company }}</option>
                        </select>
                    </div>
                    <!-- <a class="btn btn-base" href="/signing-contract.html"><i class="icon-loupe"></i></a> -->
                </div>
            </div>
        </div>

    </header>
</template>

<script>

import { postApi } from "@/run"

import Logo from "@/assets/svg/Logo.vue"
import axios from 'axios'

import { mapGetters, mapMutations } from "vuex";

export default {
    data: () => {
        return {
            companyId: null,
            loadingOld: false
        }
    },
    components: {
        Logo
    },
    computed: {
        ...mapGetters({
            getCompanies: 'main/getCompanies',
            getCompanyId: 'main/getCompanyId'
        })
    },
    methods: {
        ...mapMutations({
            setLoading: 'main/setLoading',
            setData: 'main/setData',
            setRoot: 'main/setRoot',
            setContracts: 'page/setContracts',
            setNotifications: 'page/setNotifications'
        }),
        company() {
            this.setLoading(true)
            postApi('/changeCompany', {
                company: this.companyId
            }).then((res) => {

                if (res.status) {
                    this.setContracts([])
                    this.setNotifications([])
                    this.setData(res.root)
                    this.setRoot(true)
                    this.$router.push('/')
                }

                this.setLoading(false)
            })
        },
        openMenu() {
            if (![...document.body.classList].includes('mobile-menu-open')) {
                document.body.classList.add('mobile-menu-open')
            } else {
                document.body.classList.remove('mobile-menu-open')
            }
        },
        old() {

            this.loadingOld = true

            axios.post('//dev.asket.ru/api/old', {
                id: this.companyId
            }).then(response => {

                if (response.data.error) {
                    alert(response.data.error)
                    this.loadingOld = false
                } else {
                    window.location.replace("https://lk.stone-xxi.ru/login.php?UID=" + response.data.uid);
                }
            })
        },
    },
    created() {
        this.companyId = this.getCompanyId
    }
}
</script>


<style>
.old_site {
    text-align: center;
    padding: 15px;
    background: #000;
    color: #fff;
}

.sidebar {
    top: 159px !important;
}

@media (min-width: 433px) {
    .sidebar {
        top: 138px !important;
    }
}

/* @media (min-width: 768px) {} */

/* @media (min-width: 992px) {} */

/* @media (min-width: 1200px) {} */

/* @media (min-width: 1400px) {} */
</style>