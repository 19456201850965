<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else>
        <div class="page-title">Сопровождение договоров страхования</div>

        <div size="2" class="mb-4 hr"></div>

        <div class="alert alert-info mb-4">
            Здесь Вы можете отправить заполненные документы в наше агентство
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-6">

                <div v-if="files.length !== 0">

                    <div v-for="item, index in files" class="mt-1">
                        <div class="row">
                            <div class="text-truncate">
                                <ui-button @click="delFile(index)" class="btn-ghost btn-small me-2"
                                    :disabled="loadingUpload">Удалить</ui-button>
                                <b>{{ size(item.size) }}</b> - {{ item.name }}
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="error" class="login-alert p-3 mt-3 text-center">
                    <div class="text-primary">Внимание!</div>
                    <span>{{ error }}</span>
                </div>

                <div v-if="success" class="success-alert p-3 mb-3 text-center">
                    <div class="text-primary">Внимание!</div>
                    <span>{{ success }}</span>
                </div>

                <input type="file" ref="file" style="display: none;" @change='addFile' multiple />

                <div v-if="files.length === 0">
                    <ui-button class="btn-ghost" @click="$refs.file.click()">Прикрепить файл</ui-button>
                </div>

                <div v-else>
                    <ui-button class="btn-base mt-3 me-2" @click="upload('btnUpload')" :progress="btnUpload ? btnUpload : 0"
                        :loading="loadingUpload">Отправить</ui-button>
                    <ui-button class="btn-ghost mt-3" @click="$refs.file.click()" :disabled="loadingUpload">Прикрепить
                        еще</ui-button>
                </div>

            </div>
        </div>

        <div>
            <h2 class="title">Шаблоны</h2>
            <div size="2" class="hr"></div>
        </div>

        <div class="table-responsive mt-3">
            <table class="table mb-0">
                <tbody>
                    <tr v-for="item, index in samples">
                        <td>{{ item.name }}</td>
                        <td class="text-end">
                            <ui-button @click="download(index, 'sample', item.file)"
                                :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                class="btn-base btn-small">Скачать</ui-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div>
            <h2 class="title">Информация</h2>
            <div size="2" class="hr"></div>
        </div>


        <p style="text-align:justify;" class="mt-4">При необходимости внести какие-либо изменения в действующий договор страхования нашим
            клиентам не обязательно
            обращаться с этим вопросом в страховую компанию и тратить свое время на поездку в офис Страховщика. Вам
            достаточно
            будет только позвонить в наше Агентство, и наш специалист проконсультирует Вас о порядке действий в той или иной
            ситуации.</p>

        <p style="text-align:justify;">С помощью личного кабинета вы можете направить нам сообщение о необходимости внести
            изменения в ваш договор
            страхования, а также получить шаблон заявления, необходимого для внесения изменений в договор.</p>

        <ul>
            <li>При переоформлении полиса</li>
        </ul>

        <p style="text-align:justify;">Например: изменение списка лиц, допущенных к управлению, изменения в документах на
            ТС, смена регистрационных знаков
            ТС, смена фамилии Собственника, Страхователя, изменение наименования юр.лица и др.</p>

        <p style="text-align:justify;">Наш специалист подготовит вам новый полис ОСАГО или дополнительное соглашение с
            изменениями к КАСКО. Курьер
            Агентства заберет у Вас оригинал заявления на внесение изменений в договор страхования и привезет в удобное для
            вас
            время и место, готовые переоформленные договора.</p>

        <ul>
            <li>При расторжении полиса</li>
        </ul>

        <p style="text-align:justify;">При продаже автомобиля новому собственнику или при утрате предмета страхования в
            результате страхового случая Вы
            имеете право расторгнуть договора страхования, возможность наступления страхового случая по которым отпала. При
            этом, наш специалист направит вам список документов, необходимых для расторжения договоров. Курьер Агентства
            подъедет к Вам, заберет у Вас заявление на расторжение страхового полиса и сопутствующие документы, и
            самостоятельно
            передаст документы на расторжение в страховую компанию. Вам останется только получить остаток неиспользованной
            страховой премии от Страховщика на свой расчетный счет.</p>

        <ul>
            <li>При изменении условий договора страхования</li>
        </ul>

        <p style="text-align:justify;" class="mb-0">Ваш полис всегда оформляется на определенных условиях. Если происходят какие-либо
            изменения, необходимо обязательно
            сообщать об этом в страховую компанию, например: изменение условий хранения или эксплуатации ТС, утеря или
            хищение
            ключей от замков зажигания, брелоков от сигнализации, регистрационных документов на ТС, передача ТС в аренду,
            изменение характера эксплуатации ТС и т.д. В этих случаях, Вам необходимо сразу позвонить в нашу компанию или
            отправить сообщение с помощью личного кабинета. Наш специалист объяснит порядок ваших действий, поможет
            составить
            заявление, курьер Агентства передаст ваше заявление в Страховую компанию и привезет вам дополнительное
            соглашение об
            изменении условий страхования.</p>

    </div>
</template>

<script>
import { getApi, postApi, downloadApi, uploadApi } from '@/run'

export default {
    name: 'request.accounting',
    data() {
        return {
            loading: false,
            samples: [],
            files: [],
            loadingUpload: false,
            btnUpload: null,
            success: null,
            error: null,
            controller: new AbortController()
        }
    },
    components: {},
    created() {
        document.title = "Сопровождение договоров страхования - STONE-XXI"
        this.start()
    },
    methods: {
        start() {

            this.loading = true

            getApi('/insurance/maintenance', {}).then(response => {

                if (response.status) {
                    this.samples = response.samples
                }

                this.loading = false

            })

        },
        addFile(e) {
            this.success = null
            this.error = null
            this.files = [...this.files, ...e.target.files]
            this.$refs.file.value = null;
        },
        delFile(index) {
            this.success = null
            this.error = null
            this.files = this.files.filter((item, i) => i !== index)
        },
        size(size) {
            return ((size / 1000000).toFixed(3)) + ' Мбайт'
        },
        progress(btn, progress) {
            this.samples = this.samples.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        download(btn, type, file) {
            this.samples = this.samples.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })
            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progress).then(response => {

                this.samples = this.samples.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

            })
        },
        progressUpload(btn, progress) {

            this[btn] = progress

        },
        upload(btn) {

            this.success = null
            this.error = null

            this.loadingUpload = true

            uploadApi('/insurance/requestMaintenance', {
                files: this.files,
                data: {}
            }, btn, this.progressUpload).then(response => {

                if (response.status) {
                    this.files = []
                    this.success = response.success
                } else {
                    this.error = response.error ? response.error : null
                }

                this[btn] = null
                this.loadingUpload = false

            })

        }
    }
}
</script>