<template>
    <div class="login-page">
        <div class="login-page__content" style="width: 100%;">
            <div class="login-page__inner">
                <div class="login-inner">
                    <div class="d-flex justify-content-center">
                        <h3>Ошибка 404</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>