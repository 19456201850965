<template>
    <div class="modal fade" :style="{ 'display': 'block' }" :class="'show'" @click="close" id="modalManager" tabindex="-1"
        aria-labelledby="modalManager" aria-hidden="true">
        <div class="modal-dialog-centered" :class="notification ? 'modal-dialog-notification' : 'modal-dialog'">
            <div class="modal-content" @click.stop>
                <slot></slot>
            </div>
        </div>
    </div>

    <div class="modal-backdrop fade show"></div>
</template>

<script>
export default {
    name: 'ui-modal',
    props: {
        close: Function,
        notification: Boolean
    },
    beforeMount() {
        document.body.classList.add('modal-open')
        document.body.style.overflowY = 'hidden'
    },
    beforeUnmount() {
        document.body.classList.remove('modal-open')
        document.body.style.overflowY = 'auto'
    }
}
</script>