import root from '@/router/middleware/root'

import DebtIndex from '@/views/debt'
import Debt from '@/views/debt/Debt.vue'
import Info from '@/views/debt/Info.vue'

const routes = {
    path: '/debt',
    name: 'debt',
    component: DebtIndex,
    meta: {
        middleware: [
            root
        ]
    },
    children: [{
        path: '/debt',
        name: 'debt.debt',
        component: Debt,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/debt/info',
        name: 'debt.info',
        component: Info,
        meta: {
            middleware: [
                root
            ]
        }
    }]
}

export default routes