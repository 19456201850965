<template>
    <div class="login-page__img-wrap" :style="{ background: '#0b4680' }">
        <!-- <div class="login-page__img-info"><a class="btn btn-base">Подробнее</a></div> -->
        <div v-if="fon"
            :style="{ background: 'url(' + fon + ')', backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '100%' }">
        </div>
        <div v-else class="page-center">
            <div class="page-center-box text-center">
                <ui-loading-page :white="true" />
            </div>
        </div>
    </div>
</template>

<script>
import image from "@/assets/images/fon.jpeg"

export default {

    data: () => {
        return {
            fon: null
        }
    },
    created() {
        const loadImage = new Image()
        loadImage.src = image
        loadImage.onload = () => {
            this.fon = loadImage.src
        }
    }

}
</script>