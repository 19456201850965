<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>


    <div v-else class="page-inner">

        <div class="page-title">Вам требуется консультация?</div>

        <div class="hr"></div>

        <div v-if="false" class="position-relative no-list-data">
            <ui-no-data-page text="Список договоров пуст" />
        </div>

        <div v-else>

            <div class="row mt-4">
                <div class="col-md-6 col-lg-6">

                    <ui-input-box-floating :error="errors.type">
                        <select v-model="type" class="form-control form-select">
                            <option :value="null">Не выбран</option>

                            <option v-for="item in pl">{{ item['ТипТС'] }}: {{ item['МаркаТС'] }}: {{ item['ИН'] }}: {{
                                item['РегНомерТС'] }}: {{ item['Номер'] }}</option>
                        </select>
                        <ui-input-label>Предмет договора лизинга</ui-input-label>
                    </ui-input-box-floating>


                    <ui-input-box class="mt-3" :error="errors.message">
                        <ui-textarea v-model="message" placeholder="Ваш вопрос" rows="3" />
                    </ui-input-box>


                    <div class="row">

                        <div class="col-6">

                            <ui-input-box-floating class="mt-3" :error="errors.name">
                                <ui-input v-model="name" class="form-control" placeholder="Номер выплатного дела" />
                                <ui-input-label>Имя</ui-input-label>
                            </ui-input-box-floating>

                        </div>

                        <div class="col-6">

                            <ui-input-box-floating class="mt-3" :error="errors.phone">
                                <ui-input v-maska data-maska="+7 (###) ###-##-##" v-model="phone" class="form-control"
                                    placeholder="Номер выплатного дела" />
                                <ui-input-label>Телефон</ui-input-label>
                            </ui-input-box-floating>

                        </div>

                    </div>

                    <ui-input-box-floating class="mt-3" :error="errors.email">
                        <ui-input v-model="email" class="form-control" placeholder="Номер выплатного дела" />
                        <ui-input-label>E-mail</ui-input-label>
                    </ui-input-box-floating>


                    <div v-if="error" class="login-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ error }}</span>
                    </div>

                    <div v-if="success" class="success-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ success }}</span>
                    </div>

                </div>
            </div>

            <ui-button @click="send" class="btn-base mt-3 me-2" :loading="loadingSend"
                :disabled="loadingSend">Отправить</ui-button>


        </div>


        <Info />


    </div>
</template>

<script>
import { getApi, postApi } from '@/run'

import Info from './info.vue'

import { mapGetters } from "vuex";

export default {
    name: 'request.accounting',
    data() {
        return {
            loading: false,
            loadingSend: false,
            pl: [],
            phone: '+7',
            type: null,
            message: null,
            name: null,
            email: null,
            error: null,
            errors: [],
            success: null
        }
    },
    components: {
        Info
    },
    created() {
        document.title = "Страховой случай - STONE-XXI"
        this.email = this.getEmail
        this.start()
    },
    computed: {
        ...mapGetters({
            getEmail: 'main/getEmail'
        })
    },
    methods: {
        start() {
            this.loading = true

            getApi('/insurance/insurancePl', {}).then(response => {

                if (response.status) {
                    this.pl = response.pl
                }

                this.loading = false
            })
        },
        send() {
            this.loadingSend = true
            this.error = null
            this.success = null

            postApi('/insurance/requestInsurancecase', {
                type: this.type,
                message: this.message,
                name: this.name,
                phone: this.phone,
                email: this.email
            }).then(response => {

                if (response.error) {
                    this.error = response.error
                    this.errors = response.errors ? response.errors : []
                }

                if (response.status) {
                    this.success = response.success
                    this.errors = []
                }

                this.loadingSend = false
            })
        }
    }
}
</script>