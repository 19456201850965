<template>
    <div class="login-page">
        <div class="login-page__content">
            <div class="login-page__inner">
                <div class="login-inner">
                    <div class="d-flex justify-content-center">
                        <router-link to="/">
                            <Logo />
                        </router-link>
                    </div>
                    <router-view />
                </div>
            </div>
        </div>
        <Slide />
    </div>
</template>

<script>
import Logo from "@/assets/svg/LogoLogin.vue"
import Slide from "./components/Slide.vue"

export default {
    components: {
        Logo,
        Slide
    }
}
</script>