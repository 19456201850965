<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Сопровождение договора</div>
        <div class="hr"></div>


        <div class="row">
            <div class="col-md-6 col-lg-6 mt-4">

                <ui-input-box-floating :error="errors.subject">
                    <select v-model="subject" class="form-control form-select">
                        <option>Плановое окончание Договора Лизинга</option>
                        <option>Досрочное расторжение Договора Лизинга</option>
                        <option>Изменение условий Договора Лизинга</option>
                        <option>Расчет выкупной стоимости</option>
                        <option>Перерасчет Договора Лизинга в связи со страховым случаем</option>
                        <option>Пролонгация Договора Лизинга</option>
                    </select>
                    <ui-input-label>Тема обращения</ui-input-label>
                </ui-input-box-floating>

                <ui-input-box-floating class="mt-3" :error="errors.dl">
                    <select v-model="dl" class="form-control form-select">
                        <option :value="null">Не выбран</option>
                        <option v-for="item in list">{{ item['Номер'] }}</option>
                    </select>
                    <ui-input-label>Договор лизинга</ui-input-label>
                </ui-input-box-floating>

                <ui-input-box class="mt-3" :error="errors.message">
                    <ui-textarea v-model="message" placeholder="Сообщение" rows="3" />
                </ui-input-box>


                <div v-if="error" class="login-alert p-3 text-center mt-3">
                    <div class="text-primary">Внимание!</div>
                    <span>{{ error }}</span>
                </div>

                <div v-if="success" class="success-alert p-3 text-center mt-3">
                    <div class="text-primary">Внимание!</div>
                    <span>{{ success }}</span>
                </div>
                <ui-button @click="send" class="btn-base mt-3 me-2" :loading="loadingSend"
                    :disabled="loadingSend">Отправить</ui-button>
            </div>
            <div class="col-md-6 col-lg-6 mt-4">
                <h2>Обратиться по вопросам</h2>
                <ul style="margin-bottom: 0;">
                    <li>Досрочного расторжения Договора Лизинга</li>
                    <li>Планового окончания срока Договора Лизинга</li>
                    <li class="mb-0 pb-0">Изменения условий Договора Лизинга</li>
                </ul>
            </div>
        </div>


        <div class="page-title">Контакты</div>
        <div class="hr"></div>

        <div v-if="contacts.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список контактов пуст" />
        </div>

        <div v-else class="row">


            <div class="col-lg-6 mt-4" v-for="item, index in contacts">
                <div class="manager-item">
                    <img v-if="item.loadingImg" class="manager-item__img"
                        :src="'https://lk.stone-xxi.ru/img/images/' + item.photo" />
                    <img v-else class="manager-item__img" :src="noFoto" />
                    <div class="manager-item__body mt-3">
                        <div class="h5">{{ item.F }} {{ item.I }} {{ item.O }}</div>
                        <div class="text-secondary mb-3">{{ item.jt }}</div>
                        <div class="d-flex align-items-center line-height-1_2 mb-2">
                            <i class="icon-tel me-2"></i>{{ item.phone }}
                        </div>
                        <a class="d-flex align-items-center line-height-1_2" :href="'mailto:' + item.email">
                            <i class="icon-mail me-2"></i>{{ item.email }}</a>
                    </div>
                </div>
            </div>



        </div>

    </div>
</template>

<script>
import axios from 'axios'
import { postApi, getApi } from '@/run'
import noFoto from '@/assets/images/no-foto-avatar.png'

export default {
    name: 'accounting.contacts',
    data() {
        return {
            loading: false,
            loadingSend: false,
            dl: null,
            subject: 'Плановое окончание Договора Лизинга',
            list: [],
            contacts: [],
            error: null,
            errors: [],
            success: null,
            noFoto: noFoto
        }
    },
    components: {},
    created() {
        document.title = "Сопровождение договора - STONE-XXI"

        this.start()
    },
    methods: {
        start() {
            this.loading = true

            getApi('/dl', {}).then(response => {

                this.list = response.dl

            }).then(response => {
                axios.post('//dev.asket.ru/api/contacts', {
                    id: 2
                }).then(response => {

                    this.contacts = response.data

                    this.contacts.forEach((item, index) => {
                        this.loadingImg(index, item.photo)
                    })

                    this.loading = false

                })
            })
        },
        send() {
            this.loadingSend = true
            this.error = null
            this.success = null

            postApi('/maintenance', {
                subject: this.subject,
                dl: this.dl,
                message: this.message,
                contacts: this.contacts
            }).then(response => {

                if (response.error) {
                    this.error = response.error
                    this.errors = response.errors ? response.errors : []
                }

                if (response.status) {
                    this.success = response.success
                    this.errors = []
                }

                this.loadingSend = false
            })
        },
        loadingImg(index, image) {
            const loadImage = new Image()
            loadImage.src = 'https://lk.stone-xxi.ru/img/images/' + image
            loadImage.onload = () => {

                this.contacts = this.contacts.map((item, i) => i === index ? { ...item, loadingImg: true } : item)

            }
        }
    }
}
</script>