<template>
    <textarea :value="modelValue" @input="updateInput" class="form-control"></textarea>
</template>

<script>
export default {
    name: 'ui-textarea',
    props: {
        modelValue: [String, Number]
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.value)
        }
    }
}
</script>