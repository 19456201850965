<template>
    <div class="login-inner__nav">
        <RouterLink class="login-inner__nav-item" to="/login">Вход</RouterLink>
        <RouterLink class="login-inner__nav-item active" to="/registration">Регистрация</RouterLink>
    </div>

    <div class="login-alert p-3 text-center mb-3">
        <div class="text-primary">Внимание!</div><span>Регистрация временно недоступна</span>
    </div>

    <div class="form-group mb-3 form-floating">
        <input class="form-control" placeholder="Фамилия" disabled>
        <label for="E-mail">Фамилия</label>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group mb-3 form-floating">
                <input class="form-control" placeholder="Имя" disabled>
                <label for="E-mail">Имя</label>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group mb-3 form-floating">
                <input class="form-control" placeholder="Отчество" disabled>
                <label for="E-mail">Отчество</label>
            </div>
        </div>
    </div>
    <div class="form-group mb-3 form-floating">
        <input class="form-control" id="E-mail" placeholder="E-mail" disabled>
        <label for="E-mail">E-mail</label>
    </div>
    <div class="note text-secondary mb-3">Пароль – не менее 8 знаков, включающих цифры, буквы и символы верхнего и
        нижнего регистров.</div>
    <div class="form-group mb-3">
        <div class="custom-group custom-group-append form-floating">
            <input class="form-control" type="password" placeholder="Пароль" disabled>
            <label for="E-mail">Пароль</label><a class="custom-group-icon js-drop-eye" href="javascript: void(0);"><i
                    class="icon-eye"></i></a>
        </div>
    </div>
    <PrivacyPolicy />
    <div class="row align-items-center">
        <div class="col-auto mb-2">
            <button class="btn btn-base btn-large" disabled> Зарегистрироваться</button>
        </div>
        <!-- <div class="col-auto text-secondary note mb-2" style="max-width: 200px">
            * — поля, обязательные для заполнения
        </div> -->
    </div>
    <!-- <div class="mt-3 d-flex align-items-center justify-content-center">
        <b class="me-3 my-0">Войти через:</b>
        <div class="socs mb-n2">
            <a class="soc soc_small mb-2" href="#" target="_blank"><i class="icon-soc-vk"></i></a>
            <a class="soc soc_small mb-2" href="#" target="_blank"><i class="icon-soc-facebook"></i></a>
        </div>
    </div> -->
</template>
  
<script>

import PrivacyPolicy from "./components/PrivacyPolicy.vue";

export default {
    name: 'Registration',
    components: {
        PrivacyPolicy
    },
    created() {
        document.title = "Регистрация - STONE-XXI"
    }
}
</script>