<template>
  <input :value="modelValue" @input="updateInput" class="form-control">
</template>

<script>
export default {
  name: 'ui-input',
  props: {
    modelValue: [String, Number]
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>