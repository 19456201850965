<template>
    <div class="login-inner__nav mb-4">
        <RouterLink class="login-inner__nav-item active" to="/login">Вход</RouterLink>
        <RouterLink class="login-inner__nav-item" to="/registration">Регистрация</RouterLink>
    </div>

    <div v-if="error" class="login-alert p-3 text-center mb-3">
        <div class="text-primary">Внимание!</div>
        <span>{{ error }}</span>
    </div>

    <ui-input-box-floating class="mb-3" :error="errors.email">
        <ui-input v-model="email" placeholder="E-mail" type="E-mail" />
        <ui-input-label>E-mail</ui-input-label>
    </ui-input-box-floating>

    <ui-input-box-floating class="mb-3 custom-group custom-group-append" :error="errors.password">
        <ui-input-password v-model="password" />
        <ui-input-label>Пароль</ui-input-label>
    </ui-input-box-floating>

    <div class="row">
        <div class="col-auto mb-3">
            <RouterLink to="/password_recovery">Забыли пароль?</RouterLink>
        </div>
        <div class="col-auto mb-3">
            <RouterLink to="/error_login">Сообщить о проблеме со входом</RouterLink>
        </div>
    </div>
    <PrivacyPolicy />
    <ui-button @click="login" :loading="loading" class="btn-base btn-large">Войти</ui-button>
    <!-- <div class="mt-3 d-flex align-items-center justify-content-center">
        <b class="me-3 my-0">Войти через:</b>
        <div class="socs mb-n2">
            <a class="soc soc_small mb-2" href="#" target="_blank"><i class="icon-soc-vk"></i></a>
            <a class="soc soc_small mb-2" href="#" target="_blank"><i class="icon-soc-facebook"></i></a>
        </div>
    </div> -->
</template>
  
<script>
import { postApi } from '@/run'
import router from '@/router'
import { mapMutations } from "vuex";

import PrivacyPolicy from "./components/PrivacyPolicy.vue";

export default {
    name: 'Login',
    data() {
        return {
            loading: false,
            error: null,
            errors: [],
            email: null,
            password: null
        }
    },
    components: {
        PrivacyPolicy
    },
    created() {
        document.title = "Вход - STONE-XXI"
    },
    methods: {
        ...mapMutations({
            setRoot: 'main/setRoot',
            setData: 'main/setData'
        }),
        login() {

            this.loading = true

            postApi('/login', {
                email: this.email,
                password: this.password
            }).then(response => {

                if (response.error) {
                    this.error = response.error
                    this.errors = response.errors ? response.errors : []
                }

                if (response.status) {
                    this.setData(response.root)
                    this.setRoot(true)
                    if (this.$route.path === '/' || this.$route.path === '/login') router.push('main')
                }

                this.loading = false
            })

        }
    }
}
</script>