<template>
  <label>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'ui-input-label'
}
</script>