<template>
    <div class="no-data-page">
        <div class="page-center">
            <div class="page-center-box text-center">
                <div>
                    <Page class="icon" />
                </div>
                <span>{{ text }}</span>
            </div>
        </div>
    </div>
</template>
  
<script>
import Page from '@/assets/svg/page.vue'

export default {
    name: 'ui-no-data-page',
    components: {
        Page
    },
    props: {
        text: String
    }

}
</script>