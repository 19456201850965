<template>
  <div v-if="getRoot" class="page">
    <Header />
    <main class="main">

      <Menu />

      <div class="content-page">
        <div class="content-main">
          <router-view />
        </div>
        <div class="content-promo">
          <Chat />
        </div>
      </div>

        <div v-if="scroll" class="back-to-top js-back-to-top shown" @click="scrollTop">Вверх</div>

    </main>
  </div>
  <Login v-else />
</template>

<script>
import Header from "@/component/header"
import Menu from "@/component/menu"
import Login from "./login/LoginPage.vue"

import Chat from "@/component/chat"

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      scroll: false
    }
  },
  components: {
    Header,
    Menu,
    Login,
    Chat
  },
  created() {
    addEventListener("scroll", (event) => { 
      this.scroll = window.scrollY >= 700
    });
  },
  computed: {
    ...mapGetters({
      getRoot: 'main/getRoot'
    })
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>