<template>
    <div style="max-width: 1000px;margin: 0 auto;" class="mt-5">
        <div class="mb-4">
            <h2 class="title">Пользовательское cоглашение</h2>
            <div size="2" class="hr"></div>
        </div>

        <p style="text-align: justify;">Настоящим я (Пользователь), во исполнение требований законодательства о персональных дынных, а также
            законодательства о рекламе, действуя свободно, своей волей и в своем интересе даю своё согласие Обществу с
            ограниченной ответственностью «Лизинговая компания «СТОУН-XXI» (далее – ООО «СТОУН-XXI») (ОГРН
            1027739641710, ИНН 7710329843), с местом нахождения: Российская Федерация, 123056, г. Москва, Малый
            Тишинский пер. д.14-16, почтовым адресом Российская Федерация, 117105, г. Москва, ул. Нагатинская, д. 1, на
            обработку своих персональных данных, указанных при регистрации и/или оставлении заявки на получение расчета
            лизинга и/или на получение рекламной информации, связанной с договором лизинга и лизинговой деятельностью
            (включая рекламу) по любым каналам связи, включая: SMS-оповещение, почтовое письмо, телеграмму, голосовое
            сообщение (далее – Рассылка) путем заполнения веб-формы на сайте ООО «СТОУН-XXI» (https://www.stone-xxi.ru/)
            (далее – «Сайт»).</p>
        <hr size="2">
        <h6 class="mt-4">Что такое персональные данные</h6>
        <p style="text-align: justify;">Под персональными данными понимается любая информация, относящаяся ко мне как к субъекту персональных данных,
            в том числе:</p>
        <div class="row">
            <div class="col-lg-4">
                <ul>
                    <li class="mb-1">фамилия, имя, отчество,</li>
                    <li class="mb-1">дата и место рождения,</li>
                    <li class="mb-1">гражданство,</li>
                    <li class="mb-1">пол,</li>
                    <li class="mb-1">место работы и должность,</li>
                    <li class="mb-1">почтовый адрес;</li>
                    <li class="mb-1">номера рабочего и мобильного телефонов,</li>
                    <li class="mb-1">адреса электронной почты,</li>
                </ul>
            </div>
            <div class="col-lg-4">
                <ul>
                    <li class="mb-1">данные водительского удостоверения,</li>
                    <li class="mb-1">адрес регистрации по месту жительства,</li>
                    <li class="mb-1">адрес фактического проживания,</li>
                    <li class="mb-1">паспортные данные,</li>
                    <li class="mb-1">данные, которые относятся (могут быть отнесены) к категории биометрические
                        персональные данные,</li>
                </ul>
            </div>
            <div class="col-lg-4">
                <ul>
                    <li class="mb-1">идентификационный номер налогоплательщика,</li>
                    <li class="mb-1">номер страхового свидетельства обязательного пенсионного страхования,</li>
                    <li class="mb-1">иные персональные данные, в том числе полученные посредством заполнения заявки
                        расчета лизинга на Сайте.</li>
                </ul>
            </div>
        </div>
        <hr size="2">
        <h6 class="mt-4">Цели сбора и обработки персональных данных</h6>
        <p style="text-align: justify;">Целями обработки, в том числе целями сбора, персональных данных являются: определение возможности заключения
            договоров лизинга, договоров страхования (страховых полисов), договоров купли-продажи, любых иных договоров,
            необходимых для реализации договора лизинга, их заключения, изменения, исполнения и прекращения, продвижения
            лизинговых и иных услуг посредством любых средств связи, а также для обеспечения соблюдения законов и иных
            нормативно-правовых актов.</p>
        <hr size="2">
        <h6 class="mt-4">Соглашение</h6>
        <p style="text-align: justify;">Я согласен (а) с тем, что в рамках обработки персональных данных ООО «СТОУН-XXI» вправе осуществлять сбор,
            запись, систематизацию, накопление, анализ, использование, извлечение, распространение, передачу любым иным
            третьим лицам, в том числе и трансграничную передачу, получение, обработку, хранение, уточнение (обновление,
            изменение), обезличивание, блокирование, удаление, уничтожение моих персональных данных путем ведения баз
            данных автоматизированным, механическим, ручным способами в целях: ведения и актуализации клиентской базы;
            получения и исследования статистических данных об объемах продаж и качестве оказываемых услуг; проведения
            маркетинговых программ; проведения опросов и исследований, направленных на выявление
            удовлетворенности/неудовлетворенности Пользователя, постоянного совершенствования уровня предоставляемых
            услуг; информирования меня о предлагаемых ООО «СТОУН-XXI» автомобилях, оказываемых услугах, проводимых
            бонусных мероприятий, акций и т.д.; рекламирования и иного любого продвижения товаров и услуг на рынке путем
            осуществления прямых контактов со мной и иными потребителями; технической поддержки при обработке информации
            и персональных данных с использованием средств автоматизации и без такого использования.</p>
        <p style="text-align: justify;">Я выражаю согласие на получение рекламы и разрешаю ООО «СТОУН-XXI» осуществлять в мой адрес смс-рассылки, а
            также иные виды рассылок и уведомлений, в том числе рекламного характера, с использованием любых средств
            связи, в том числе электронной почты.</p>
        <p style="text-align: justify;">Я ознакомлен (а), что: настоящее Согласие на обработку моих персональных данных и получение рекламы действует
            в течение 20 (двадцати) лет, а в части персональных данных, содержащихся в документах и на иных носителях
            информации, срок хранения которых по действующему законодательству превышает двадцать лет, Согласие на
            обработку персональных данных действует в течение сроков хранения таких документов и иных носителей
            информации, установленных действующим законодательством. Согласие на обработку персональных данных может
            быть отозвано посредством направления соответствующего письменного уведомления в адрес ООО «СТОУН-XXI» по
            адресу места нахождения, указанному в настоящем согласии. В этом случае лицо, получившее отзыв согласия на
            обработку персональных данных или согласия на получение рекламы, прекращает обработку персональных данных, а
            персональные данные подлежат уничтожению или обезличиванию, если отсутствуют иные правовые основания для
            обработки, установленные законодательством РФ или документами лица, получившего отзыв согласия на обработку
            персональных данных, регламентирующими вопросы обработки персональных данных. Настоящее Согласие считается
            отозванным по истечении 30 (тридцати) дней с момента получения ООО «СТОУН-XXI» письменного уведомления об
            отзыве настоящего согласия. День получения уведомления не включается в 30-тидневный срок.</p>
        <p style="text-align: justify;">При изменении адресов электронной почты и/или номеров телефонов, по которым осуществляется Рассылка рекламы,
            я обязуюсь незамедлительно проинформировать об этом ООО «СТОУН-XXI» об изменении адресов электронной почты
            и/или номеров телефонов. До указанного уведомления Рассылка осуществляется по адресам электронной почты и
            номерам телефонов, указанным в веб-форме заявки расчета лизинга на Сайте, а риск связанных с этих
            последствий принимаю на себя.</p>
        <p style="text-align: justify;">Настоящим Согласием я подтверждаю, что являюсь субъектом предоставляемых персональных данных, а также
            подтверждаю достоверность предоставляемых данных.</p>
        <p style="text-align: justify;" class="pb-5">Я осознаю, что, регистрируясь и авторизируясь на Сайте, оставляя заявку на получение расчета лизинга
            и/или
            подписываясь на получение рекламной информации на Сайте, я выражаю ООО «СТОУН-XXI» своё письменное согласие
            с условиями, описанными в настоящем Согласии.</p>

    </div>
</template>