<template>
<svg width="200" height="45" viewBox="0 0 200 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M0.000142248 39.1173C-0.0117662 38.8965 -0.0117662 38.6753 0.000142248 38.4545V6.54545C0.000142248 4.80949 0.693564 3.14463 1.92786 1.91712C3.16216 0.689609 4.83623 0 6.5818 0L38.6674 0C39.6344 0.000395973 40.5894 0.212702 41.4646 0.621818C41.1384 0.560491 40.8035 0.560491 40.4773 0.621818C39.5641 0.711818 38.6674 0.818182 37.7624 0.965455C34.6759 1.44085 31.627 2.13257 28.6386 3.03545C25.2755 4.02958 21.9782 5.23192 18.7661 6.63545C18.7661 6.63545 13.0483 9.15545 10.4238 11.1027C7.7994 13.05 4.03963 16.1182 5.40532 19.3664C5.91412 20.3143 6.73239 21.0617 7.72536 21.4855C9.96266 22.5173 12.4367 22.9353 14.8911 22.6964C19.1692 22.5 23.8586 20.9209 27.9064 22.9091C27.9064 22.9091 31.1149 24.3 29.4119 28.5382C29.4119 28.5382 28.5892 31.0664 23.4391 34.6418C20.5156 36.5955 17.4173 38.2771 14.1836 39.6655C11.6495 40.7645 8.98839 41.5478 6.26094 41.9973C5.12844 42.1373 3.97876 41.9851 2.92257 41.5552C1.86639 41.1253 0.939275 40.4323 0.2305 39.5427L0.000142248 39.1173Z" fill="#fff"/>
<path d="M17.5977 44.9998H6.58161C5.84244 45.0019 5.10819 44.8802 4.40967 44.6398C4.40967 44.6398 4.88684 44.5089 4.96911 44.5089C8.60794 43.8426 12.204 42.9629 15.7383 41.8743C16.5199 41.6207 17.2933 41.3507 18.0748 41.0562C19.8519 40.4343 21.6125 39.7962 23.3648 39.1007C25.2735 38.348 27.1328 37.4643 28.9675 36.5643C29.6668 36.2125 30.3661 35.8607 31.0571 35.4925C33.5549 34.2563 35.8158 32.5949 37.7375 30.5834C37.8774 30.428 38.009 30.2725 38.1406 30.1089C38.091 31.6256 37.6181 33.0986 36.7749 34.3634C35.3088 36.3167 33.4898 37.9814 31.4109 39.2725C27.36 41.8331 22.9127 43.7128 18.2476 44.8362L17.5977 44.9998Z" fill="#fff"/>
<path d="M25.7513 45.0006H38.6678C40.4133 45.0006 42.0874 44.311 43.3217 43.0835C44.556 41.856 45.2494 40.1911 45.2494 38.4551V12.0442L45.1918 11.9051C44.9537 11.4262 44.6272 10.996 44.2293 10.637C42.8817 9.5734 41.2109 8.99645 39.4905 9.0006C37.1908 8.91048 34.8902 9.15581 32.662 9.72878C31.6994 9.95787 30.7369 10.2279 29.799 10.547C25.0211 11.9616 20.5434 14.2325 16.5863 17.2479C16.4876 17.3215 14.7517 18.6551 14.9903 18.8842C15.0744 18.9569 15.1623 19.0252 15.2535 19.0888C15.6094 19.3413 15.9931 19.5526 16.3971 19.7188C17.2915 20.071 18.225 20.3155 19.1778 20.447L20.0005 20.537C20.922 20.627 21.8599 20.6433 22.7895 20.6515C23.3736 20.6515 23.9742 20.6515 24.5666 20.6515C28.1536 20.4551 31.864 20.1606 35.4016 20.9461C37.047 21.3142 38.8488 22.1488 39.655 23.7361C39.8993 24.2484 40.0337 24.8056 40.0499 25.3724C40.0678 26.9737 39.5106 28.5287 38.4785 29.7579C38.6824 30.5458 38.7738 31.3583 38.75 32.1715C38.7099 33.0995 38.5067 34.0133 38.1495 34.8715C37.9954 35.2256 37.8196 35.5699 37.6229 35.9024C37.2128 36.6166 36.7245 37.2834 36.1667 37.8906L35.6813 38.3897C35.2124 38.8397 34.7517 39.2815 34.2581 39.6988L33.822 40.0588C33.4765 40.337 33.1145 40.5988 32.7443 40.877C31.0495 42.0551 29.4534 42.9551 28.6307 43.4706C27.808 43.986 25.7348 45.0006 25.7348 45.0006" fill="#fff"/>
<path d="M45.2485 7.27355V6.54537C45.249 6.20312 45.2242 5.86129 45.1744 5.52264C45.1744 5.52264 44.2612 3.019 38.9054 3.0681C38.0253 3.08299 37.1466 3.14307 36.2727 3.2481C34.1848 3.46232 32.1251 3.89284 30.1271 4.53264C27.6066 5.34735 25.1732 6.40796 22.8626 7.699C22.2044 8.06719 21.5627 8.45173 20.9375 8.86082C19.0156 10.0791 17.2715 11.5548 15.7544 13.2463C14.7271 14.2254 14.1224 15.5631 14.0679 16.9772C14.0849 17.218 14.1262 17.4564 14.1913 17.689C14.2662 17.9461 14.3829 18.1894 14.5368 18.409C14.5954 18.4989 14.6614 18.5837 14.7343 18.6626C15.2584 17.8563 15.8771 17.1148 16.5771 16.4536C18.5685 14.5326 20.8064 12.882 23.2328 11.5445C23.9239 11.1435 24.6314 10.7263 25.339 10.3908C28.1336 8.88992 31.1042 7.73846 34.183 6.96264C35.5067 6.6614 36.8517 6.46172 38.2061 6.36537C38.9924 6.31623 39.781 6.31623 40.5672 6.36537C41.5442 6.41529 42.5154 6.5439 43.4714 6.74991C44.0351 6.86923 44.5861 7.04183 45.1168 7.26537L45.2485 7.27355Z" fill="#fff"/>
<path d="M67.6011 7.55146C66.5862 7.32273 65.5483 7.21018 64.5077 7.216C59.0861 7.216 55.1618 10.546 54.1334 15.3487C52.924 21.0269 56.3629 23.3424 60.715 23.3424C62.1096 23.3462 63.4966 23.1393 64.8286 22.7287L64.0059 26.7051C62.3891 27.0938 60.7329 27.2969 59.0696 27.3105C53.0556 27.3105 47.2062 23.9805 49.0408 15.4142C50.6698 7.31418 57.416 3.27237 64.9931 3.27237C66.1402 3.26599 67.2864 3.33707 68.4238 3.48509L67.6011 7.55146Z" fill="#fff"/>
<path d="M85.9652 3.51807L85.0766 7.68261H79.5974L75.4839 27.0408H70.5476L74.6612 7.68261H69.2148L70.1034 3.51807H85.9652Z" fill="#fff"/>
<path d="M106.278 15.2839C104.452 23.8175 97.837 27.3111 91.8559 27.3111C85.8748 27.3111 80.7576 23.8175 82.584 15.2839C84.4104 6.75022 91.025 3.27295 96.9978 3.27295C102.971 3.27295 108.104 6.74204 106.278 15.2839ZM87.7588 15.2839C86.064 23.2448 90.7452 23.9484 92.5799 23.9484C94.4145 23.9484 99.3919 23.2448 101.095 15.2839C102.798 7.32295 98.1167 6.61113 96.2574 6.61113C94.3981 6.61113 89.4618 7.31477 87.7588 15.2839Z" fill="#fff"/>
<path d="M111.624 3.51759L114.29 13.5321L114.792 15.9867H114.899L116.47 13.4667L123.2 3.48486H128.037L114.051 23.514C112.11 26.304 110.069 27.2776 107.511 27.2776C106.303 27.2874 105.107 27.0362 104.006 26.5412L105.322 22.974C105.918 23.3193 106.599 23.4948 107.288 23.4812C107.898 23.5443 108.513 23.4284 109.057 23.1478C109.601 22.8671 110.05 22.434 110.349 21.9021L111.377 20.323L106.128 3.51759H111.624Z" fill="#fff"/>
<path d="M133.615 3.51807L131.633 12.9926H141.447L143.43 3.51807H148.366L143.34 27.0408H138.403L140.575 16.8626H130.81L128.589 27.0408H123.652L128.712 3.51807H133.615Z" fill="#fff"/>
<path d="M154.134 12.9678L153.311 16.8705H147.881L148.704 12.9841L154.134 12.9678Z" fill="#fff"/>
<path d="M159.333 3.51807L161.415 10.0635L161.99 12.0435H162.073L163.496 10.0635L168.432 3.51807H173.253L164.278 14.7272L168.918 26.9999H163.718L161.143 19.5381L160.6 17.7299H160.501L159.185 19.5381L153.27 26.9999H148.449L158.321 14.7272L154.117 3.51807H159.333Z" fill="#fff"/>
<path d="M180.123 3.51807L182.204 10.0635L182.78 12.0435H182.871L184.286 10.0717L189.263 3.52625H194.084L185.059 14.7272L189.708 26.9999H184.483L181.917 19.5381L181.365 17.7299H181.275L179.958 19.5381L174.043 26.9999H169.222L179.095 14.7272L174.899 3.51807H180.123Z" fill="#fff"/>
<path d="M195.089 3.51807H200.025L194.982 27.0408H190.045L195.089 3.51807Z" fill="#fff"/>
<path d="M55.7714 41.6125H54.126L55.5657 34.8707H53.3691L53.0153 36.507C52.7438 37.7752 52.5381 38.6261 52.3983 39.0679C52.2048 39.766 51.8562 40.4118 51.3781 40.9579C51.1369 41.2055 50.8459 41.3998 50.5241 41.5281C50.2023 41.6563 49.857 41.7158 49.5106 41.7025C49.3819 41.712 49.2526 41.712 49.1239 41.7025C48.9904 41.6895 48.8582 41.6648 48.729 41.6289L49.0828 40.3361H49.2884H49.5106C49.9713 40.3361 50.3333 40.017 50.6541 39.3789C50.9158 38.6216 51.1383 37.8515 51.3205 37.0716L52.1432 33.3979H57.5566L55.7714 41.6125Z" fill="#fff"/>
<path d="M64.1706 41.6134H62.5252L63.4302 37.3997L63.8991 35.4197L63.0188 36.6634L59.0534 41.5724H57.0542L58.7983 33.3906H60.4438L59.5717 37.637L59.1027 39.6334L59.983 38.4552L63.932 33.4479H65.9394L64.1706 41.6134Z" fill="#fff"/>
<path d="M71.428 39.4696C71.3381 39.886 71.1439 40.2731 70.8635 40.5949C70.583 40.9167 70.2254 41.1629 69.8237 41.3105C68.4315 41.8363 66.8903 41.8159 65.5127 41.2532L65.8171 39.7969C66.7879 40.2714 68.2112 40.7378 69.1902 40.0178C69.4394 39.8476 69.6156 39.5907 69.6838 39.2978C69.7561 39.0928 69.7499 38.8685 69.6664 38.6678C69.5829 38.4671 69.4281 38.304 69.2313 38.2096C68.9328 38.0836 68.609 38.0276 68.2852 38.046H67.3144L67.5941 36.7042H68.5978C68.9667 36.7217 69.3319 36.6244 69.6427 36.426C69.7772 36.33 69.891 36.2082 69.9772 36.0676C70.0635 35.927 70.1203 35.7707 70.1445 35.6078C70.1781 35.4976 70.1836 35.3809 70.1607 35.2681C70.1377 35.1553 70.0869 35.0499 70.0129 34.9614C69.8787 34.8453 69.7215 34.7583 69.5515 34.7062C69.3815 34.654 69.2023 34.6379 69.0257 34.6587C68.3333 34.6689 67.6503 34.8193 67.0182 35.1005L67.2404 33.636C67.6268 33.535 68.02 33.4612 68.4169 33.4151C68.8273 33.3469 69.243 33.314 69.6591 33.3169C70.3629 33.2496 71.066 33.4515 71.6254 33.8814C71.8207 34.0724 71.9599 34.3128 72.0281 34.5766C72.0962 34.8404 72.0907 35.1178 72.0121 35.3787C71.9214 35.8137 71.7171 36.2172 71.4197 36.5487C71.1105 36.89 70.7022 37.1273 70.2515 37.2278C70.4571 37.2596 70.6536 37.3344 70.828 37.4472C71.0024 37.56 71.1508 37.7083 71.2634 37.8823C71.3977 38.1219 71.4816 38.3862 71.5099 38.6591C71.5382 38.932 71.5103 39.2078 71.428 39.4696Z" fill="#fff"/>
<path d="M79.2437 41.6134H77.5983L78.5033 37.3997L78.9804 35.4197L78.1577 36.6634L74.1923 41.5724H72.1108L73.855 33.3906H75.5004L74.6283 37.637L74.1594 39.6334L75.0397 38.4552L78.9804 33.4315H80.9878L79.2437 41.6134Z" fill="#fff"/>
<path d="M87.2071 41.613H85.5617L86.3186 38.0702H83.0278L82.2709 41.613H80.6255L82.3778 33.4312H84.0233L83.3157 36.7039H86.6066L87.3141 33.4312H88.9595L87.2071 41.613Z" fill="#fff"/>
<path d="M94.4637 34.8712H91.5925L90.1527 41.613H88.5073L90.2515 33.4312H94.801L94.4637 34.8712Z" fill="#fff"/>
<path d="M101.842 37.5222C101.62 38.7926 100.931 39.9355 99.909 40.7295C99.0521 41.3877 97.997 41.7394 96.9143 41.7276C96.4422 41.761 95.9685 41.6911 95.5265 41.5229C95.0845 41.3546 94.6849 41.0922 94.3557 40.754C94.0023 40.3059 93.7649 39.7784 93.6645 39.2177C93.5641 38.657 93.6037 38.0804 93.7798 37.5386C94.01 36.28 94.6983 35.1499 95.7132 34.364C96.5722 33.7116 97.6271 33.3658 98.7078 33.3822C99.1803 33.3499 99.6543 33.4195 100.097 33.5861C100.54 33.7528 100.942 34.0125 101.275 34.3476C101.618 34.7932 101.848 35.3139 101.947 35.8663C102.046 36.4186 102.01 36.9864 101.842 37.5222ZM100.074 37.5222C100.329 36.3195 100.213 35.4767 99.728 34.9776C99.5587 34.8174 99.3582 34.6934 99.1389 34.6132C98.9196 34.533 98.6861 34.4983 98.4528 34.5113C97.93 34.5094 97.4201 34.6727 96.9966 34.9776C96.2052 35.6111 95.6872 36.5214 95.5486 37.5222C95.2936 38.7249 95.4088 39.5676 95.8942 40.0667C96.0634 40.2269 96.2639 40.351 96.4832 40.4312C96.7025 40.5114 96.9361 40.5461 97.1694 40.5331C97.6922 40.535 98.2021 40.3717 98.6256 40.0667C99.4169 39.4333 99.9349 38.523 100.074 37.5222Z" fill="#fff"/>
<path d="M108.318 39.3213C108.196 39.9269 107.857 40.4678 107.363 40.8431C106.553 41.403 105.576 41.674 104.591 41.6122H102.016L103.76 33.4304H106.466C107.124 33.3818 107.779 33.5547 108.326 33.9213C108.544 34.1059 108.702 34.3502 108.781 34.6238C108.86 34.8974 108.856 35.188 108.77 35.4595C108.677 35.971 108.42 36.4389 108.038 36.7931C107.752 37.0594 107.405 37.2506 107.026 37.3495C107.25 37.3783 107.466 37.4528 107.66 37.5682C107.854 37.6837 108.022 37.8376 108.153 38.0204C108.267 38.2151 108.34 38.4307 108.368 38.6542C108.397 38.8777 108.379 39.1046 108.318 39.3213ZM106.573 39.1904C106.622 39.0545 106.637 38.9086 106.615 38.7659C106.594 38.6231 106.537 38.4879 106.45 38.3722C106.303 38.2373 106.128 38.1359 105.938 38.0752C105.747 38.0144 105.546 37.9957 105.348 38.0204H104.426L103.941 40.2868H104.862C105.29 40.307 105.712 40.1862 106.063 39.9431C106.319 39.7618 106.5 39.4944 106.573 39.1904ZM107.018 35.7377C107.062 35.5503 107.039 35.3533 106.952 35.1813C106.796 34.9031 106.434 34.764 105.874 34.764H105.134L104.698 36.8013H105.52C105.931 36.8257 106.335 36.6908 106.647 36.4249C106.843 36.2423 106.973 36.0009 107.018 35.7377Z" fill="#fff"/>
<path d="M116.331 41.613H114.447L114.151 39.7721H110.753L109.667 41.613H107.882L112.909 33.4312H114.817L116.331 41.613ZM113.945 38.4548L113.452 35.1084L111.576 38.4548H113.945Z" fill="#fff"/>
<path d="M122.748 41.6125H121.103L121.752 38.5689H120.757L118.47 41.6125H116.471L119.161 38.2416C118.797 38.1336 118.479 37.9093 118.256 37.6034C118.107 37.361 118.009 37.0906 117.97 36.8091C117.93 36.5276 117.949 36.2409 118.026 35.9671C118.092 35.5781 118.239 35.207 118.457 34.8774C118.675 34.5478 118.96 34.2669 119.293 34.0525C120.063 33.6077 120.945 33.3919 121.835 33.4307H124.5L122.748 41.6125ZM121.999 37.2762L122.551 34.7234H121.604C121.356 34.7065 121.106 34.739 120.87 34.8191C120.634 34.8991 120.417 35.0252 120.23 35.1898C120.008 35.4129 119.858 35.6983 119.803 36.008C119.747 36.1711 119.739 36.3463 119.778 36.514C119.817 36.6817 119.903 36.8351 120.025 36.9571C120.318 37.1957 120.692 37.3129 121.07 37.2843L121.999 37.2762Z" fill="#fff"/>
<path d="M135.295 41.613H133.18L131 37.5221L130.095 41.613H128.45L130.202 33.4312H131.847L131.099 36.933L134.859 33.4312H136.841L132.613 37.1375L135.295 41.613Z" fill="#fff"/>
<path d="M143.398 37.5222C143.183 38.7855 142.51 39.9266 141.506 40.7295C140.646 41.3807 139.592 41.7264 138.511 41.7113C138.038 41.7425 137.564 41.6716 137.121 41.5036C136.678 41.3355 136.276 41.0743 135.944 40.7376C135.594 40.2878 135.359 39.76 135.261 39.1997C135.162 38.6395 135.201 38.0637 135.377 37.5222C135.599 36.2753 136.272 35.1522 137.269 34.364C138.128 33.7127 139.183 33.367 140.263 33.3822C140.736 33.3499 141.21 33.4195 141.653 33.5861C142.096 33.7528 142.497 34.0125 142.83 34.3476C143.174 34.7925 143.406 35.3133 143.504 35.8659C143.603 36.4185 143.567 36.9866 143.398 37.5222ZM141.629 37.5222C141.892 36.3195 141.777 35.4767 141.284 34.9776C141.116 34.8177 140.917 34.6937 140.699 34.6135C140.481 34.5333 140.249 34.4985 140.017 34.5113C139.494 34.5094 138.984 34.6727 138.56 34.9776C137.769 35.6111 137.251 36.5214 137.112 37.5222C136.857 38.7249 136.973 39.5676 137.458 40.0667C137.626 40.2267 137.825 40.3507 138.043 40.4309C138.261 40.5111 138.493 40.5459 138.725 40.5331C139.248 40.5438 139.761 40.3891 140.189 40.0913C140.983 39.4505 141.499 38.5306 141.629 37.5222Z" fill="#fff"/>
<path d="M152.909 41.613H151.263L152.76 34.6012L148.853 41.613H147.265L146.565 34.6012L145.052 41.613H143.406L145.159 33.4312H147.98L148.556 39.5839L151.847 33.4312H154.669L152.909 41.613Z" fill="#fff"/>
<path d="M160.806 41.613H159.161L160.625 34.7648H157.384L155.919 41.613H154.274L156.026 33.4312H162.608L160.806 41.613Z" fill="#fff"/>
<path d="M169.922 41.613H168.046L167.742 39.7721H164.352L163.258 41.613H161.473L166.508 33.4312H168.408L169.922 41.613ZM167.544 38.4548L167.009 35.0757L165.167 38.4548H167.544Z" fill="#fff"/>
<path d="M177.269 41.613H175.624L176.381 38.0702H173.09L172.333 41.613H170.688L172.432 33.4312H174.077L173.37 36.7039H176.66L177.368 33.4312H179.013L177.269 41.613Z" fill="#fff"/>
<path d="M185.644 41.6134H183.998L184.895 37.3997L185.364 35.4197L184.5 36.6634L180.535 41.5724H178.527L180.28 33.3906H181.925L181.037 37.637L180.559 39.6334L181.44 38.4552L185.397 33.4479H187.396L185.644 41.6134Z" fill="#fff"/>
<path d="M192.843 41.6128H191.198L191.848 38.5691H190.852L188.606 41.6128H186.615L189.305 38.2419C188.948 38.1389 188.631 37.9272 188.401 37.6364C188.251 37.3942 188.154 37.1234 188.115 36.8416C188.077 36.5598 188.099 36.2731 188.178 36C188.245 35.6153 188.39 35.2482 188.605 34.9217C188.82 34.5952 189.101 34.3161 189.429 34.1019C190.198 33.6549 191.081 33.439 191.971 33.48H194.628L192.843 41.6128ZM192.094 37.2764L192.637 34.7237H191.691C191.443 34.7063 191.193 34.7386 190.957 34.8187C190.721 34.8988 190.504 35.025 190.317 35.19C190.091 35.4109 189.942 35.6973 189.89 36.0082C189.834 36.1713 189.825 36.3465 189.865 36.5142C189.904 36.6819 189.99 36.8354 190.112 36.9573C190.405 37.196 190.779 37.3132 191.157 37.2846L192.094 37.2764Z" fill="#fff"/>
<path d="M195.014 29.9868H50.2017V30.9605H195.014V29.9868Z" fill="#fff"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="200" height="45" fill="white"/>
</clipPath>
</defs>
</svg>

</template>
