import { createRouter, createWebHistory } from 'vue-router'
import middlewarePipeline from './middlewarePipeline'
import router from '@/router'
import store from '@/store'
import { postApi } from '@/run'

import Root from './views/root/index.js'
import Login from './views/login.js'

import NotFound from '@/views/NotFound.vue'
import TermsOfUse from '@/views/TermsOfUse.vue'

import Sign from '@/views/Sign.vue'

const routes = [
  Root,
  Login,
  {
    path: "/terms_of_use",
    component: TermsOfUse
  },
  {
    path: "/sign",
    component: Sign
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound
  }
]

const createRouters = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

createRouters.beforeEach((to, from, next) => {

  if (to.meta.middleware) {

    const middleware = to.meta.middleware

    const context = {
      to,
      from,
      next,
      store
    }

    if (store.state.main.loading) {

      const loadPage = new Date()
      const params = (new URL(document.location)).searchParams;

      postApi('/main', {
        loginUID: params.get('LoginUID')
      }).then(response => {

        console.log(to.path);

        if (response.status) {
          store.commit('main/setData', response)

       

          // if (to.path === '/' || to.path === '/login' || to.path === '/registration') router.push('main')
        }

        // setTimeout(() => {
          store.commit('main/setLoading', false)
        // }, new Date() - loadPage >= 300 ? 0 : 300)

        return middleware[0]({
          ...context,
          next: middlewarePipeline(context, middleware, 1)
        })

      })

    } else {

      const middleware = to.meta.middleware
      return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
      })

    }

  } else {
    store.commit('main/setLoading', false)
    return next()
  }

})

export default createRouters