<template>
    <div class="chat">
        <!-- <div class="h">Чат с менеджером</div>
        <div class="b">
            <div class="login-alert p-3 text-center mb-3">
            <div class="text-primary">Внимание!</div>
            <span>ddd</span>
        </div>
        </div>
        <div class="f"></div> -->
    </div>
</template>

<script>

import './style.css'

export default {}

</script>