<template>
    <div class="sign-header p-3">
        <Logo class="sign-logo" />
    </div>

    <div style="max-width: 1000px;margin: 0 auto;" class="mb-3 px-3">
        <div class="mb-4">
            <h2 class="title">Тестирование подписания документов ЭЦП</h2>
            <div size="2" class="hr"></div>
        </div>

        <div class="row">

            <div class="col-md-12 col-lg-5 sign-title mt-3">
                Заявка на лизинг
            </div>
            <div class="col-sm-12 col-md-9 col-lg-5 sign-file">
                <Pdf class="sign-pdf" /> <a download href="https://lc1.asket.ru/STONE-XXI%20-%20%D0%97%D0%B0%D1%8F%D0%B2%D0%BA%D0%B0-%D0%B0%D0%BD%D0%BA%D0%B5%D1%82%D0%B0%20%D0%BB%D0%B8%D0%B7%D0%B8%D0%BD%D0%B3%D0%BE%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B0%D1%82%D0%B5%D0%BB%D1%8F.pdf">STONE-XXI - Заявка-анкета лизингополучателя.pdf</a>
            </div>
            <div class="col-auto text-end mt-3">
                <button class="btn btn-ghost">Подпись ЭЦП</button>
            </div>

        </div>

        <div class="row">

            <div class="col-md-12 col-lg-5 sign-title mt-3">
                Cогласие на обработку персональных данных
            </div>
            <div class="col-sm-12 col-md-9 col-lg-5 sign-file">
                <Pdf class="sign-pdf" /> <a download href="https://lc1.asket.ru/STONE-XXI%20%D0%A1%D0%9E%D0%9F%D0%94%20%D0%9D%D0%B8%D0%BA%D0%BE%D0%BB%D0%B8%D0%BD%20%D0%92.%D0%90..pdf">STONE-XXI СОПД Николин В.А.pdf</a>

            </div>
            <div class="col-auto text-end mt-3">
                <button class="btn btn-ghost">Подпись ЭЦП</button>
            </div>

        </div>
        <!-- <div size="2" class="hr mt-3"></div> -->
    </div>
</template>

<script>
import './style.css'
import Logo from "@/assets/svg/LogoWhite.vue"
import Pdf from "@/assets/svg/pdfIcon.vue"

export default {
    components: {
        Logo,
        Pdf
    },
}
</script>