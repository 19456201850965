<template>
    <button ref="button" class="btn" :disabled="loading" :style="(loading || progress) && { minWidth: width + 'px' }">
        <div v-if="loading">
            <span v-if="progress">{{ progress }}%</span>
            <div v-else :class="white ? 'spinner-white' : 'spinner'"></div>
        </div>
        <slot v-else></slot>
    </button>
</template>
  
<script>
export default {
    name: 'ui-button',
    data() {
        return {
            width: null
        }
    },
    props: {
        white: Boolean,
        loading: Boolean,
        progress: Number
    },
    mounted() {
        this.width = this.$refs.button.offsetWidth
    },
    watch() {
        console.log('ddd');
    },
    beforeUpdate() {
        this.width = this.$refs.button.offsetWidth
    }

}
</script>