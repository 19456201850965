import Loading from "./Loading.vue"
import Button from "./Button.vue"
import NoData from "./NoData.vue"
import Modal from "./Modal.vue"
import Input from "./input"

export default [
    Loading,
    Button,
    NoData,
    Modal,
    ...Input
]