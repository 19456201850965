<template>
    <div v-if="list.length > 1" class="position-relative" style="display: inline-block;">
        <ul class="pagination">

            <li v-for="item in list" class="page-item"
                :class="item === pageItem && 'active', typeof item !== 'number' && 'disabled', loading && 'disabled'">

                <button @click="updateInput(item)" class="page-link" :disabled="item === pageItem || loading">
                    {{ typeof item === 'number' ? item + 1 : '...' }}
                </button>
            </li>

        </ul>
        <div v-if="loading" class="spinner-page"></div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {

            pageItem: 0,
            quantity: 10,
            list: []

        }
    },
    props: {
        page: Function,
        total: Number,
        item: Number,
        loading: Boolean
    },
    created() {

        this.pageItem = this.item
        this.list = this.pagination(Math.ceil(this.total / this.quantity), this.pageItem)

    },
    methods: {
        pagination(totalcount, currentPage) {

            if (totalcount < 7) return [...Array(totalcount).keys()];

            const result = [7]
            result[0] = 0
            result[6] = totalcount - 1

            currentPage = (Math.min(Math.max(0, currentPage), totalcount - 1))

            if (currentPage < 3) {

                for (let i = 1; i < 5; i++) {
                    result[i] = i
                }

                result[5] = false

                return result
            }

            if (currentPage >= totalcount - 3) {

                for (let i = 2; i < 6; i++) {
                    result[i] = totalcount - 7 + i
                }

                result[1] = false

                return result
            }

            for (let i = 2; i < 5; i++) {

                result[i] = currentPage - 3 + i

            }

            result[1] = false
            result[5] = false

            return result

        },
        updateInput(item) {

            this.page(item)
            this.pageItem = item
            this.list = this.pagination(Math.ceil(this.total / this.quantity), item)

        }
    },
    beforeUpdate() {
        this.pageItem = this.item
        this.list = this.pagination(Math.ceil(this.total / this.quantity), this.item)
    }
}
</script>