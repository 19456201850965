import root from '@/router/middleware/root'

import RequestIndex from '@/views/request'
import Accounting from '@/views/request/Accounting.vue'
import Balance from '@/views/request/Balance.vue'
import MutualSettlements from '@/views/request/MutualSettlements.vue'
import BuySell from '@/views/request/BuySell.vue'
import Photofine from '@/views/request/Photofine.vue'

const routes = {
    path: '/request/accounting',
    name: 'request',
    component: RequestIndex,
    meta: {
        middleware: [
            root
        ]
    },
    children: [{
        path: '/request/accounting',
        name: 'request.accounting',
        component: Accounting,
        meta: {
            middleware: [
                root
            ]
        }
    }, {
        path: '/request/balance',
        name: 'request.balance',
        component: Balance,
        meta: {
            middleware: [
                root
            ]
        }
    }, {
        path: '/request/mutualsettlements',
        name: 'request.mutualsettlements',
        component: MutualSettlements,
        meta: {
            middleware: [
                root
            ]
        }
    }, {
        path: '/request/buysell',
        name: 'request.buysell',
        component: BuySell,
        meta: {
            middleware: [
                root
            ]
        }
    }, {
        path: '/request/photofine',
        name: 'request.photofine',
        component: Photofine,
        meta: {
            middleware: [
                root
            ]
        }
    }]
}



export default routes