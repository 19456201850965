import root from '@/router/middleware/root'

import NotificationsIndex from '@/views/notifications'
import Notifications from '@/views/notifications/Notifications.vue'
import Notification from '@/views/notifications/Notification.vue'

const routes = {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsIndex,
    meta: {
        middleware: [
            root
        ]
    },
    children: [{
        path: '/notifications',
        name: 'notifications.notifications',
        component: Notifications,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/notifications/:id',
        name: 'notifications.notification',
        component: Notification,
        meta: {
            middleware: [
                root
            ]
        }
    }]
}

export default routes