<template>
    <router-view />
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'contracts',
    methods: {
        ...mapMutations({
            setNotifications: 'page/setNotifications',
            setNotificationsFilterCash: 'page/setNotificationsFilter',
            setNotificationsPageBtn: 'page/setNotificationsPageBtn',
            setNotificationsScroll: 'page/setNotificationsScroll'

        })
    },
    beforeUnmount() {
        this.setNotificationsScroll(0)
        this.setNotifications([])
        this.setNotificationsPageBtn('new')
        this.setNotificationsFilterCash('new')
    }
}
</script>