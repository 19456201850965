<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Контакты</div>
        <div class="hr"></div>

        <div v-if="contacts.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список контактов пуст" />
        </div>

        <div v-else class="row">


            <div class="col-lg-6 mt-4" v-for="item, index in contacts">
                <div class="manager-item">
                    <img v-if="item.loadingImg" class="manager-item__img"
                        :src="'https://lk.stone-xxi.ru/img/images/' + item.photo" />
                    <img v-else class="manager-item__img" :src="noFoto" />
                    <div class="manager-item__body mt-3">
                        <div class="h5">{{ item.F }} {{ item.I }} {{ item.O }}</div>
                        <div class="text-secondary mb-3">{{ item.jt }}</div>
                        <div class="d-flex align-items-center line-height-1_2 mb-2">
                            <i class="icon-tel me-2"></i>{{ item.phone }}
                        </div>
                        <a class="d-flex align-items-center line-height-1_2" :href="'mailto:' + item.email">
                            <i class="icon-mail me-2"></i>{{ item.email }}</a>
                    </div>
                </div>
            </div>



        </div>

        <div class="page-title">Информация</div>
        <div class="hr"></div>

        <p style="text-align:justify;" class="mt-4">
            ООО СТОУН-XXI» готово приложить все силы и использовать все допустимые действующим законодательством возможности
            для
            урегулирования вопроса возникающей задолженности по договорам лизинга заключенным с нашими партнерами.
        </p>

        <p style="text-align:justify;">
            И поскольку судебные разбирательства являются крайней мерой и на некоторых этапах являются невыгодными для нашей
            и
            Вашей компании, мы хотели бы предложить Вам несколько возможных направлений по решению наступающей или уже
            наступившей задолженности по лизинговым платежам:
        </p>

        <p style="text-align:justify;">
        <ul>
            <li style="text-align:justify;">
                Вы можете самостоятельно и в необходимый срок оплачивать лизинговые платежи, в следствии чего Вы будете
                нашим
                постоянным клиентом с рейтингом А, данная категория клиентов может получать от нас новости по скидкам
                автодилеров,
                специальные предложения, бонусы и другие лояльные условия для успешного ведения Вашего бизнеса. Но даже при
                возникновении неблагоприятных финансовых обстоятельств мы готовы оказать помощь и рассмотреть всевозможные
                варианты
                по урегулированию возникающей задолженности.
            </li>

            <li style="text-align:justify;">
                Вы можете при любых сложившихся обстоятельствах обратится в отдел по работе с дебиторской задолженностью и
                предложить организовать рабочую встречу и провести переговоры между Вами и ООО СТОУН-XXI» для уточнения
                суммы
                задолженности и согласования дальнейших действий по оплате и урегулированию задолженности. Обращаем Ваше
                внимание,
                что просрочка оплаты Лизингополучателем платежей по Графику порядка расчетов (Приложение №2) влечет за собой
                право
                ООО СТОУН-XXI» начислять пени в размере 0,25% от суммы задолженности за каждый календарный день просрочки
                платежа в
                соответствии с п.2.2.2 Договора лизинга.
            </li>

            <li style="text-align:justify;">
                Вы можете, в случае задержки очередного платежа более, чем на 30 (Тридцать) календарных дней получить от ООО
                СТОУН-XXI» требование, предусмотренное п. 2.2.1 в течение 10-и (Десяти) календарных дней сдать предмет
                лизинга
                уполномоченному представителю нашей компании и оплатить задолженность. В этом случае Договор лизинга
                считается
                расторгнутым. Обращаем Ваше внимание, что невыполнение нашего требования о сдаче предмета лизинга влечет за
                собой
                право ООО СТОУН-XXI» в соответствии с п. 2.2.2 Договора лизинга начислять Вам пени в размере 0,2% (ноль
                целых
                две
                десятых процента) от СУММЫ ДОГОВОРА ЛИЗИНГА (п.3.2.) за каждый календарный день просрочки передачи. И важно!
                Расторжение договора не освобождает Лизингополучателя от оплаты образовавшейся задолженности до момента
                сдачи
                предмета лизинга.
            </li>


            <li style="text-align:justify;">

                В случае, если техника, полученная в лизинг, не используется, либо если для погашения возникшей
                задолженности
                нет
                других средств, как вариант решения сложившейся ситуации, Вы можете, по согласованию с ООО СТОУН-XXI»,
                поставить
                технику на нашу площадку для ее дальнейшей реализации Вашими и нашими силами. Денежные средства, полученные
                от
                реализации будут использованы для покрытия задолженности по Договору лизинга, а излишки будут перечислены
                Вам.
                ООО
                СТОУН-XXI» окажет Вам максимальное содействие в реализации техники, в т.ч. задействует собственные каналы
                реализации, организует рекламную поддержку с использованием собственного сайта в интернете. (по вопросам
                оценки
                и
                реализации техники Вы можете обратится в отдел по работе с дебиторской задолженностью, наши специалисты
                проконсультируют и помогут решить ваши вопросы)
            </li>

            <li style="text-align:justify;">
                И в самом неблагоприятном случае, если Вами будут задержаны более, чем два лизинговых платежа, ООО
                СТОУН-XXI»
                будет вынуждено использовать процедуры судебного и последующего исполнительного производства.

            </li>
        </ul>
        </p>
        <p style="text-align:justify;">
            <b>Также:</b>

            Обращаем Ваше внимание, что Ваши действия (бездействия) в отношении задолженности, возникшей в результате
            неисполнения Вашей компанией обязательств по Договору лизинга, могут квалифицироваться в соответствии со ст. УК
            РФ:
            159 (мошенничество) и другими.
        </p>
        <p style="text-align:justify;">
            <b>В случае, если Вас не удовлетворяет ни один из вышеперечисленных вариантов, предлагаем Вам разработать
                собственную
                схему урегулирования вопроса Вашей задолженности и связаться с Отделом по работе с дебиторской
                задолженностью.</b>
        </p>
        <p style="text-align:justify;">
            <b>Надеемся на взаимопонимание и сотрудничество с учетом интересов всех сторон!</b>
        </p>

    </div>
</template>

<script>
import axios from 'axios'
import noFoto from '@/assets/images/no-foto-avatar.png'


export default {
    name: 'debt.info',
    data() {
        return {
            loading: false,
            contacts: [],
            noFoto: noFoto
        }
    },
    components: {},
    created() {
        document.title = "Общая информация - STONE-XXI"

        this.start()
    },
    methods: {
        start() {
            this.loading = true

            axios.post('//dev.asket.ru/api/contacts', {
                id: 3
            }).then(response => {

                this.contacts = response.data

                this.contacts.forEach((item, index) => {
                    this.loadingImg(index, item.photo)
                })

                this.loading = false

            })
        },
        loadingImg(index, image) {
            const loadImage = new Image()
            loadImage.src = 'https://lk.stone-xxi.ru/img/images/' + image
            loadImage.onload = () => {

                this.contacts = this.contacts.map((item, i) => i === index ? { ...item, loadingImg: true } : item)

            }
        }
    }
}
</script>