export default function root({ to, next, store }) {

    if (to.path === '/' && !store.state.main.root) {
        return next({
            name: 'login'
        })
    }

    return next()

}