import RootView from '@/views/RootView.vue'

import root from '@/router/middleware/root'

import home from './home'
import contracts from './contracts'
import debt from './debt'
import insurance from './insurance'
import accounting from './accounting'
import request from './request'
import notifications from './notifications'


const routes = {
    path: '/main',
    component: RootView,
    meta: {
        middleware: [
            root
        ]
    },
    children: [
        home,
        contracts,
        debt,
        insurance,
        accounting,
        request,
        notifications
    ]
}



export default routes