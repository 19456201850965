<template>
    <div class="login-inner__nav">
        <div class="login-inner__nav-item active">Восстановление пароля</div>
    </div>

    <div v-if="error" class="login-alert p-3 text-center mb-3">
        <div class="text-primary">Внимание!</div>
        <span>{{ error }}</span>
    </div>

    <ui-input-box-floating class="mb-3" :error="errors.email">
        <ui-input v-model="email" placeholder="E-mail" type="E-mail" />
        <ui-input-label>E-mail</ui-input-label>
    </ui-input-box-floating>

    <ui-input-box class="mb-3" :error="errors.message">
        <ui-textarea v-model="message" placeholder="Сообщение" rows="4" />
    </ui-input-box>

    <ui-button @click="rasswordRecovery" :loading="loading" class="btn-base btn-large">Отправить</ui-button>

    <div class="pt-3">
        <router-link to="/login" class="icon icon-arrow-back">Авторизоваться</router-link>
    </div>
</template>

<script>
import { postApi } from '@/run'
import router from '@/router'
import { mapMutations } from "vuex";

import PrivacyPolicy from "./components/PrivacyPolicy.vue";

export default {
    name: 'Login',
    data() {
        return {
            loading: false,
            error: null,
            errors: [],
            email: null,
            message: null
        }
    },
    components: {
        PrivacyPolicy
    },
    created() {
        document.title = "Вход - STONE-XXI"
    },
    methods: {
        ...mapMutations({
            setRoot: 'main/setRoot'
        }),
        rasswordRecovery() {

            this.loading = true

            postApi('/error_login', {
                email: this.email,
                message: this.message
            }).then(response => {

                if (response.error) {
                    this.error = response.error
                    this.errors = response.errors ? response.errors : []
                }

                this.loading = false
            })

        }
    }
}
</script>