import root from '@/router/middleware/root'

import AccountingIndex from '@/views/accounting'
import Documentation from '@/views/accounting/Documentation.vue'
import Photofine from '@/views/accounting/Photofine.vue'
import Contacts from '@/views/accounting/Contacts.vue'

const routes = {
    path: '/accounting',
    name: 'accounting',
    component: AccountingIndex,
    meta: {
        middleware: [
            root
        ]
    },
    children: [{
        path: '/accounting/documentation',
        name: 'accounting.documentation',
        component: Documentation,
        meta: {
            middleware: [
                root
            ]
        }
    }, {
        path: '/accounting/photofine',
        name: 'accounting.photofine',
        component: Photofine,
        meta: {
            middleware: [
                root
            ]
        }
    }, {
        path: '/accounting/contacts',
        name: 'accounting.contacts',
        component: Contacts,
        meta: {
            middleware: [
                root
            ]
        }
    }]
}

export default routes