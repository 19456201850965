import guest from '@/router/middleware/guest'

import LoginIndex from '@/views/login'
import Login from '@/views/login/Login.vue'
import Registration from '@/views/login/Registration.vue'
import PasswordRecovery from '@/views/login/PasswordRecovery.vue'
import ErrorLogin from '@/views/login/ErrorLogin.vue'

const routes = {
    path: '/',
    component: LoginIndex,
    meta: {
        middleware: [
            guest
        ]
    },
    children: [{
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            middleware: [
                guest
            ]
        }
    }, {
        path: '/login',
        name: 'login.login',
        component: Login,
        meta: {
            middleware: [
                guest
            ]
        }
    }, {
        path: '/registration',
        name: 'login.registration',
        component: Registration,
        meta: {
            middleware: [
                guest
            ]
        }
    }, {
        path: '/password_recovery',
        name: 'login.password_recovery',
        component: PasswordRecovery,
        meta: {
            middleware: [
                guest
            ]
        }
    }, {
        path: '/error_login',
        name: 'login.error_login',
        component: ErrorLogin,
        meta: {
            middleware: [
                guest
            ]
        }
    }]
}


export default routes