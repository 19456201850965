export const pageModule = {
    state: {
        contracts: [],
        filter: 'act',
        pageBtn: 'act',
        scroll: 0,
        notifications: [],
        notificationsFilter: 'new',
        notificationsPageBtn: 'new',
        notificationsScroll: 0
    },
    getters: {
        getContracts(state) {
            return state.contracts
        },
        getFilter(state) {
            return state.filter
        },
        getPageBtn(state) {
            return state.pageBtn
        },
        getScroll(state) {
            return state.scroll
        },
        getNotifications(state) {
            return state.notifications
        },
        getNotificationsFilter(state) {
            return state.notificationsFilter
        },
        getNotificationsPageBtn(state) {
            return state.notificationsPageBtn
        },
        getNotificationsScroll(state) {
            return state.notificationsScroll
        }
    },
    mutations: {
        setContracts(state, data) {
            state.contracts = data
        },
        setFilter(state, data) {
            state.filter = data
        },
        setPageBtn(state, data) {
            state.pageBtn = data
        },
        setScroll(state, data) {
            state.scroll = data
        },
        setNotifications(state, data) {
            state.notifications = data
        },
        setNotificationItem(state, data) {
            state.notifications = state.notifications && state.notifications.notifications ? { ...state.notifications, notifications: state.notifications.notifications.map(item => item.id === data ? { ...item, readed: 'read' } : item) } : []
        },
        setNotificationsFilter(state, data) {
            state.notificationsFilter = data
        },
        setNotificationsPageBtn(state, data) {
            state.notificationsPageBtn = data
        },
        setNotificationsScroll(state, data) {
            state.notificationsScroll = data
        }
    },
    actions: {
    },
    modules: {
    },
    namespaced: true
}