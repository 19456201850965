<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">


        <div v-if="title">
            <div class="page-title">{{ title }}</div>
            <div class="hr"></div>
        </div>

        <div v-if="error" class="position-relative no-list-data">
            <ui-no-data-page :text="error" />
        </div>

        <div v-else>


            <div class="row">
                <div class="col">
                    <div class="search-filter mt-4">
                        <div class="search-filter__item">
                            <RouterLink class="btn btn-ghost btn-back" to="/notifications">
                                <LeftIcon class="icon" />
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="mt-4" style="padding-top: 7px;">
                        Дата уведомления: <span v-if="date">{{ dateMask(date) }}</span>
                    </div>
                </div>
            </div>

            <div>

                <div class="mt-4 mb-4" v-html="message"></div>

            </div>

        </div>

    </div>
</template>


<script>
import { getApi, postApi, dateMask, moneyMask } from '@/run'
import { mapGetters, mapMutations } from "vuex"

import LeftIcon from "@/assets/svg/left.vue"

export default {
    name: 'notifications.notification',
    data() {
        return {
            loading: false,
            error: null,
            notification: {},
            title: null,
            message: null,
            date: null,
            dateMask: dateMask,
            moneyMask: moneyMask,
        }
    },
    components: {
        LeftIcon
    },
    created() {
        document.title = "Уведомление - STONE-XXI"

        this.start()
    },
    computed: {},
    methods: {
        ...mapMutations({
            setNotificationTotal: 'main/setNotificationTotal',
            setNotificationItem: 'page/setNotificationItem'
        }),
        start() {

            this.loading = true

            getApi('/notification', {
                id: this.$route.params.id
            }).then(response => {

                if (response.status) {
                    this.notification = response.notification ? response.notification : {}
                    this.title = response.notification.subject ? response.notification.subject : null
                    this.message = response.notification.message ? response.notification.message : null
                    this.date = response.notification.date ? response.notification.date : null
                    document.title = this.notification.subject ? "Уведомление (" + this.notification.subject + ") - STONE-XXI" : "Уведомление - STONE-XXI"
                    this.setNotificationItem(this.$route.params.id)
                    this.setNotificationTotal(response.newTotal)
                }

                if (response.error) {
                    this.error = response.error
                }

                this.loading = false

            })

        }
    }
}
</script>