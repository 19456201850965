<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else>
        <div class="page-title">Запрос в бухгалтерию</div>

        <div size="2" class="mb-4 hr"></div>

        <div class="alert alert-info mb-4">
            Здесь Вы можете отправить заполненные документы в отдел по работе с бухгалтерскими документами
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-6">

                <div v-if="files.length !== 0">

                    <div v-for="item, index in files" class="mt-1">
                        <div class="row">
                            <div class="text-truncate">
                                <ui-button @click="delFile(index)" class="btn-ghost btn-small me-2"
                                    :disabled="loadingUpload">Удалить</ui-button>
                                <b>{{ size(item.size) }}</b> - {{ item.name }}
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="error" class="login-alert p-3 mt-3 text-center">
                    <div class="text-primary">Внимание!</div>
                    <span>{{ error }}</span>
                </div>

                <div v-if="success" class="success-alert p-3 mb-3 text-center">
                    <div class="text-primary">Внимание!</div>
                    <span>{{ success }}</span>
                </div>

                <input type="file" ref="file" style="display: none;" @change='addFile' multiple />

                <div v-if="files.length === 0">
                    <ui-button class="btn-ghost" @click="$refs.file.click()">Прикрепить файл</ui-button>
                </div>

                <div v-else>
                    <ui-button class="btn-base mt-3 me-2" @click="upload('btnUpload')" :progress="btnUpload ? btnUpload : 0"
                        :loading="loadingUpload">Отправить</ui-button>
                    <ui-button class="btn-ghost mt-3" @click="$refs.file.click()" :disabled="loadingUpload">Прикрепить
                        еще</ui-button>
                </div>

            </div>
        </div>

        <div v-if="edo" class="mt-3">
            <label class="label">
                <div class="toggle">
                    <input class="toggle-state" v-model="invoicesByEmail" type="checkbox" @change="setInvoicesByEmail"
                        :checked="invoicesByEmail" :disabled="sendInvoicesByEmail" />
                    <div class="toggle-inner" :class="sendInvoicesByEmail && 'disabled'">
                        <div class="indicator" :class="invoicesByEmail && 'active-toggle'"></div>
                    </div>
                    <div class="active-bg"></div>
                </div>
                <div class="label-text">Отправлять счета по email</div>
            </label>
        </div>


        <div>
            <h2 class="title">Образцы запросов</h2>
            <div size="2" class="hr"></div>
        </div>

        <div class="table-responsive mt-3">
            <table class="table mb-0">
                <tbody>
                    <tr v-for="item, index in samples">
                        <td>{{ item.name }}</td>
                        <td class="text-end">
                            <ui-button @click="download(index, 'sample', item.file)"
                                :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                class="btn-base btn-small">Скачать</ui-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</template>

<script>
import { getApi, postApi, downloadApi, uploadApi } from '@/run'

export default {
    name: 'request.accounting',
    data() {
        return {
            loading: false,
            samples: [],
            files: [],
            loadingUpload: false,
            btnUpload: null,
            edo: false,
            invoicesByEmail: false,
            sendInvoicesByEmail: false,
            success: null,
            error: null,
            controller: new AbortController()
        }
    },
    components: {},
    created() {
        document.title = "Запрос в бухгалтерию - STONE-XXI"
        this.start()
    },
    methods: {
        start() {

            this.loading = true

            getApi('/request/accounting', {}).then(response => {

                if (response.status) {
                    this.samples = response.samples
                    this.edo = response.edo
                    this.invoicesByEmail = response.invoicesByEmail
                }

                this.loading = false

            })

        },
        setInvoicesByEmail(e) {
            this.sendInvoicesByEmail = true
            this.invoicesByEmail = e.target.checked
            postApi('/request/invoicesByEmail', {
                invoicesByEmail: this.invoicesByEmail
            }).then(response => {

                if (!response.status) {
                    this.invoicesByEmail = !this.invoicesByEmail
                }

                this.sendInvoicesByEmail = false

            })
        },
        addFile(e) {
            this.success = null
            this.error = null
            this.files = [...this.files, ...e.target.files]
            this.$refs.file.value = null;
        },
        delFile(index) {
            this.success = null
            this.error = null
            this.files = this.files.filter((item, i) => i !== index)
        },
        size(size) {
            return ((size / 1000000).toFixed(3)) + ' Мбайт'
        },
        progress(btn, progress) {
            this.samples = this.samples.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        download(btn, type, file) {
            this.samples = this.samples.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })
            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progress).then(response => {

                this.samples = this.samples.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

            })
        },
        progressUpload(btn, progress) {

            this[btn] = progress

        },
        upload(btn) {

            this.success = null
            this.error = null

            this.loadingUpload = true

            uploadApi('/request/requestAccounting', {
                files: this.files,
                data: {}
            }, btn, this.progressUpload).then(response => {

                if (response.status) {
                    this.files = []
                    this.success = response.success
                } else {
                    this.error = response.error ? response.error : null
                }

                this[btn] = null
                this.loadingUpload = false

            })

        }
    },
    beforeUnmount() {
        const controller = new AbortController();
        controller.abort()
    }
}
</script>