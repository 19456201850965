<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>


    <div v-else class="page-inner">

        <div class="page-title">Запрос балансовой справки</div>

        <div class="hr"></div>

        <div v-if="contracts.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список договоров пуст" />
        </div>

        <div v-else>

            <div class="row mt-4">
                <div class="col-md-6 col-lg-6">
                    <ui-input-box-floating class="mb-2">
                        <select class="form-control form-select" @change="selectPref" v-model="pref"
                            :disabled="loadingSend">
                            <option v-for="item in lessor" :value="item.pref">{{ item.name }}</option>
                        </select>
                        <ui-input-label>Лизингодатель</ui-input-label>
                    </ui-input-box-floating>

                    <div class="mb-3">
                        <ui-button @click="setTreatie(item, true)" v-for="item in treaties" :disabled="loadingSend"
                            class="btn-base btn-small mt-1 me-1">
                            {{ item }}
                            <Trash class="icon" />
                        </ui-button>
                    </div>

                    <ui-button @click="show" class="btn-ghost btn-select">

                        <div class="row">
                            <div class="col px-0">
                                Список договоров ({{ treaties.length }}/{{ contracts.filter(item => item.pref ===
                                    pref).length }})
                            </div>
                            <div class="col-auto text-end pe-0">
                                <UpIcon v-if="listTreaties" class="icon" />
                                <DownIcon v-else class="icon" />
                            </div>
                        </div>

                    </ui-button>

                    <div style="position: relative;">

                        <div v-if="listTreaties" class="row mt-3 mx-0 list-select scroll"
                            :style="{ overflowY: loadingSend ? 'hidden' : 'auto' }">

                            <div class="disabled" v-if="loadingSend"></div>

                            <div class="col-6 col-md-4 dl-request"
                                v-for="item in contracts.filter(item => item.pref === pref)"
                                @click="setTreatie(item.name, treaties.some(e => item.name === e))"
                                :class="item.status === 'Действует' && 'active'">

                                <div class="form-group">
                                    <label @click.stop>
                                        <input type="checkbox" @change="checkbox" :value="item.name"
                                            :checked="treaties.some(e => item.name === e)">
                                        <span>{{ item.name }}</span>
                                    </label>
                                </div>

                                <span class="info">{{ item.status }}</span>

                            </div>

                        </div>

                    </div>

                    <div v-if="error" class="login-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ error }}</span>
                    </div>

                    <div v-if="success" class="success-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ success }}</span>
                    </div>

                </div>
            </div>

            <ui-button @click="send" class="btn-base mt-3 me-2" :loading="loadingSend" :disabled="loadingSend">Отправить
                запрос</ui-button>
            <ui-button v-if="treaties.length !== 0"
                @click="treaties = [], success = null, error = null, listTreaties = true" class="btn-ghost mt-3"
                :disabled="loadingSend">Очистить список</ui-button>
        </div>
    </div>
</template>

<script>
import { getApi, postApi } from '@/run'

import DownIcon from "@/assets/svg/down.vue"
import UpIcon from "@/assets/svg/up.vue"
import Trash from "@/assets/svg/trash.vue"

export default {
    name: 'request.accounting',
    data() {
        return {
            loading: false,
            loadingSend: false,
            listTreaties: true,
            pref: null,
            lessor: [],
            contracts: [],
            treaties: [],
            error: null,
            success: null
        }
    },
    components: {
        DownIcon,
        UpIcon,
        Trash
    },
    created() {
        document.title = "Запрос балансовой справки - STONE-XXI"
        this.start()
    },
    methods: {
        start() {

            this.loading = true

            getApi('/request/balance', {}).then(response => {

                if (response.status) {
                    this.pref = response.pref
                    this.lessor = response.lessor
                    this.contracts = response.contracts
                }

                this.loading = false

            })

        },
        send() {

            this.loadingSend = true
            this.error = null
            this.success = null

            postApi('/request/requestBalance', {
                pref: this.pref,
                treaties: this.treaties
            }).then(response => {

                if (response.status) {
                    this.success = response.success
                    this.listTreaties = false
                }

                if (response.error) {
                    this.error = response.error
                }

                this.loadingSend = false

            })

        },
        selectPref() {
            this.error = null
            this.listTreaties = true
            this.success = null
            this.treaties = []
        },
        show() {
            this.listTreaties = !this.listTreaties
        },
        checkbox(event) {
            this.error = null
            this.success = null
            this.treaties = event.target.checked ? [...this.treaties, event.target.value] : this.treaties.filter(item => item !== event.target.value)
        },
        setTreatie(e, del) {
            this.error = null
            this.success = null
            this.treaties = !del ? [...this.treaties, e] : this.treaties.filter(item => item !== e)
        }
    }
}
</script>