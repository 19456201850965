import root from '@/router/middleware/root'

import InsuranceIndex from '@/views/insurance'
import Insurance from '@/views/insurance/Insurance.vue'
import Maintenance from '@/views/insurance/Maintenance.vue'
import InsuranceCase from '@/views/insurance/InsuranceCase.vue'
import RequestInsurance from '@/views/insurance/RequestInsurance.vue'
import Request from '@/views/insurance/Request.vue'

const routes = {
    path: '/insurance',
    name: 'insurance',
    component: InsuranceIndex,
    meta: {
        middleware: [
            root
        ]
    },
    children: [{
        path: '/insurance',
        name: 'insurance.insurance',
        component: Insurance,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/insurance/maintenance',
        name: 'insurance.maintenance',
        component: Maintenance,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/insurance/insurancecase',
        name: 'insurance.insurancecase',
        component: InsuranceCase,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/insurance/request',
        name: 'insurance.request',
        component: RequestInsurance,
        meta: {
            middleware: [
                root
            ]
        }
    },{
        path: '/insurance/new',
        name: 'insurance.requestNew',
        component: Request,
        meta: {
            middleware: [
                root
            ]
        }
    }]
}

export default routes