<template>
  <div v-if="getLoading" class="page-center">
    <div class="page-center-box text-center">
      <Logo />
      <ui-loading-page class="mt-4" />
    </div>
  </div>
  <router-view v-else />

</template>

<script>
import Logo from "@/assets/svg/LogoLogin.vue"

import { mapGetters } from "vuex";

export default {
  components: {
    Logo
  },
  created() {
    document.title = "STONE-XXI"
  },
  computed: {
    ...mapGetters({
      getLoading: 'main/getLoading',
      getRoot: 'main/getRoot'
    })
  }
}
</script>