<template>
    <router-view />
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'contracts',
    methods: {
        ...mapMutations({
            setContracts: 'page/setContracts',
            setFilterCash: 'page/setFilter',
            setPageBtn: 'page/setPageBtn',
            setScroll: 'page/setScroll'

        })
    },
    beforeUnmount() {
        this.setScroll(0)
        this.setContracts([])
        this.setPageBtn('act')
        this.setFilterCash('act')
    }
}
</script>