<template>
    <svg width="179" height="40" viewBox="0 0 179 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path
                d="M0.000156978 34.7709C-0.010469 34.5747 -0.010469 34.378 0.000156978 34.1818V5.81818C0.000156978 4.2751 0.618903 2.79523 1.72028 1.70411C2.82165 0.612985 4.31544 0 5.87302 0L34.5032 0C35.3661 0.000351976 36.2183 0.189069 36.9992 0.552727C36.7081 0.498214 36.4093 0.498214 36.1182 0.552727C35.3034 0.632727 34.5032 0.727273 33.6957 0.858182C30.9416 1.28075 28.2211 1.89562 25.5544 2.69818C22.5535 3.58184 19.6114 4.6506 16.7451 5.89818C16.7451 5.89818 11.6431 8.13818 9.3013 9.86909C6.9595 11.6 3.60463 14.3273 4.82324 17.2145C5.27725 18.0572 6.00739 18.7215 6.89343 19.0982C8.88979 20.0154 11.0974 20.387 13.2875 20.1745C17.1049 20 21.2893 18.5964 24.9011 20.3636C24.9011 20.3636 27.7641 21.6 26.2445 25.3673C26.2445 25.3673 25.5104 27.6145 20.9149 30.7927C18.3063 32.5293 15.5417 34.0241 12.6562 35.2582C10.3949 36.2352 8.02044 36.9313 5.58672 37.3309C4.57618 37.4554 3.55031 37.3201 2.60786 36.938C1.66542 36.5558 0.838153 35.9398 0.205707 35.1491L0.000156978 34.7709Z"
                fill="white" />
            <path
                d="M15.7033 40.002H5.87359C5.21402 40.0039 4.55884 39.8957 3.93555 39.682C3.93555 39.682 4.36133 39.5656 4.43474 39.5656C7.6817 38.9734 10.8905 38.1914 14.0442 37.2238C14.7416 36.9983 15.4317 36.7583 16.1291 36.4965C17.7147 35.9438 19.2857 35.3765 20.8494 34.7583C22.5525 34.0893 24.2116 33.3038 25.8487 32.5038C26.4726 32.1911 27.0966 31.8784 27.7133 31.5511C29.9421 30.4522 31.9595 28.9754 33.6742 27.1874C33.799 27.0493 33.9165 26.9111 34.034 26.7656C33.9896 28.1138 33.5677 29.4232 32.8153 30.5474C31.5071 32.2837 29.884 33.7634 28.029 34.9111C24.4143 37.1872 20.446 38.858 16.2832 39.8565L15.7033 40.002Z"
                fill="white" />
            <path
                d="M22.9786 40.0005H34.5041C36.0617 40.0005 37.5555 39.3875 38.6569 38.2964C39.7582 37.2053 40.377 35.7254 40.377 34.1823V10.706L40.3256 10.5823C40.1131 10.1566 39.8218 9.77418 39.4667 9.45508C38.2642 8.50969 36.7733 7.99684 35.2382 8.00053C33.1862 7.92043 31.1334 8.1385 29.1451 8.6478C28.2862 8.85144 27.4273 9.09144 26.5905 9.37508C22.3271 10.6325 18.3316 12.6511 14.8007 15.3314C14.7126 15.3969 13.1636 16.5823 13.3765 16.786C13.4516 16.8506 13.53 16.9113 13.6114 16.9678C13.929 17.1922 14.2714 17.3801 14.6318 17.5278C15.4299 17.8409 16.2629 18.0582 17.1131 18.1751L17.8472 18.2551C18.6694 18.3351 19.5063 18.3496 20.3359 18.3569C20.8571 18.3569 21.393 18.3569 21.9215 18.3569C25.1222 18.1823 28.4331 17.9205 31.5897 18.6187C33.0579 18.946 34.6656 19.6878 35.3851 21.0987C35.603 21.5541 35.723 22.0495 35.7374 22.5533C35.7534 23.9766 35.2562 25.3589 34.3353 26.4514C34.5172 27.1518 34.5988 27.874 34.5775 28.5969C34.5417 29.4218 34.3604 30.234 34.0416 30.9969C33.9042 31.3116 33.7473 31.6177 33.5718 31.9133C33.2059 32.5481 32.7701 33.1408 32.2724 33.6805L31.8393 34.1242C31.4209 34.5242 31.0098 34.9169 30.5693 35.2878L30.1802 35.6078C29.8719 35.8551 29.5489 36.0878 29.2186 36.3351C27.7063 37.3823 26.2821 38.1824 25.548 38.6405C24.8139 39.0987 22.964 40.0005 22.964 40.0005"
                fill="white" />
            <path
                d="M40.3754 6.46538V5.81811C40.3759 5.51388 40.3538 5.21004 40.3093 4.90901C40.3093 4.90901 39.4945 2.68356 34.7154 2.7272C33.9301 2.74044 33.146 2.79384 32.3663 2.8872C30.5032 3.07762 28.6653 3.4603 26.8825 4.02901C24.6334 4.7532 22.4621 5.69597 20.4003 6.84356C19.8131 7.17083 19.2405 7.51265 18.6825 7.87629C16.9676 8.95924 15.4114 10.2709 14.0577 11.7745C13.141 12.6448 12.6014 13.8339 12.5527 15.0908C12.5679 15.3049 12.6048 15.5168 12.6629 15.7236C12.7297 15.9521 12.8339 16.1683 12.9712 16.3636C13.0234 16.4435 13.0824 16.5189 13.1474 16.589C13.6151 15.8723 14.1671 15.2131 14.7918 14.6254C16.5687 12.9179 18.5656 11.4507 20.7307 10.2617C21.3473 9.90538 21.9787 9.53447 22.61 9.23629C25.1037 7.90215 27.7544 6.87863 30.5017 6.18901C31.6828 5.92125 32.8829 5.74375 34.0914 5.65811C34.7931 5.61443 35.4967 5.61443 36.1983 5.65811C37.07 5.70248 37.9367 5.8168 38.7897 5.99992C39.2927 6.10598 39.7844 6.25941 40.2579 6.45811L40.3754 6.46538Z"
                fill="white" />
            <path
                d="M60.3203 6.71024C59.4147 6.50692 58.4886 6.40688 57.56 6.41205C52.7223 6.41205 49.2206 9.37205 48.3029 13.6411C47.2238 18.6884 50.2924 20.7466 54.1758 20.7466C55.4201 20.75 56.6578 20.5661 57.8463 20.2011L57.1122 23.7357C55.6696 24.0812 54.1917 24.2618 52.7076 24.2739C47.3413 24.2739 42.1218 21.3139 43.7588 13.6993C45.2124 6.49933 51.232 2.9066 57.9932 2.9066C59.0167 2.90093 60.0395 2.96411 61.0544 3.09569L60.3203 6.71024Z"
                fill="white" />
            <path
                d="M76.7062 3.125L75.9134 6.82682H71.0242L67.3537 24.0341H62.949L66.6196 6.82682H61.7598L62.5526 3.125H76.7062Z"
                fill="white" />
            <path
                d="M94.8318 13.5865C93.2021 21.172 87.2999 24.2774 81.9629 24.2774C76.626 24.2774 72.0598 21.172 73.6895 13.5865C75.3193 6.00107 81.2215 2.91016 86.5511 2.91016C91.8807 2.91016 96.4616 5.99379 94.8318 13.5865ZM78.3071 13.5865C76.7948 20.6629 80.9719 21.2883 82.609 21.2883C84.246 21.2883 88.6874 20.6629 90.207 13.5865C91.7266 6.51016 87.5495 5.87743 85.8904 5.87743C84.2313 5.87743 79.8267 6.50288 78.3071 13.5865Z"
                fill="white" />
            <path
                d="M99.6025 3.12675L101.981 12.0286L102.429 14.2104H102.524L103.926 11.9704L109.931 3.09766H114.248L101.768 20.9013C100.036 23.3813 98.2151 24.2467 95.932 24.2467C94.854 24.2555 93.787 24.0321 92.8047 23.5922L93.9793 20.4213C94.5113 20.7282 95.1183 20.8842 95.7338 20.8722C96.2776 20.9283 96.8262 20.8253 97.3115 20.5758C97.7969 20.3263 98.1978 19.9414 98.4647 19.4686L99.3823 18.0649L94.6987 3.12675H99.6025Z"
                fill="white" />
            <path
                d="M119.226 3.125L117.457 11.5468H126.215L127.984 3.125H132.389L127.903 24.0341H123.498L125.437 14.9868H116.723L114.741 24.0341H110.336L114.851 3.125H119.226Z"
                fill="white" />
            <path d="M137.535 11.5273L136.801 14.9964H131.956L132.69 11.5419L137.535 11.5273Z" fill="white" />
            <path
                d="M142.173 3.125L144.03 8.94318L144.544 10.7032H144.618L145.888 8.94318L150.292 3.125H154.594L146.585 13.0886L150.726 23.9977H146.086L143.788 17.365L143.304 15.7577H143.216L142.041 17.365L136.763 23.9977H132.461L141.27 13.0886L137.519 3.125H142.173Z"
                fill="white" />
            <path
                d="M160.725 3.125L162.582 8.94318L163.096 10.7032H163.177L164.44 8.95045L168.881 3.13227H173.183L165.13 13.0886L169.277 23.9977H164.616L162.325 17.365L161.833 15.7577H161.753L160.578 17.365L155.3 23.9977H150.998L159.807 13.0886L156.063 3.125H160.725Z"
                fill="white" />
            <path d="M174.078 3.125H178.483L173.983 24.0341H169.578L174.078 3.125Z" fill="white" />
            <path
                d="M49.7654 36.9893H48.2972L49.5819 30.9966H47.6218L47.3061 32.4511C47.0639 33.5784 46.8804 34.3348 46.7556 34.7275C46.5829 35.348 46.2718 35.9221 45.8453 36.4075C45.63 36.6276 45.3703 36.8002 45.0832 36.9143C44.7961 37.0283 44.488 37.0811 44.1788 37.0693C44.064 37.0777 43.9487 37.0777 43.8338 37.0693C43.7147 37.0577 43.5967 37.0358 43.4814 37.0039L43.7971 35.8548H43.9806H44.1788C44.5899 35.8548 44.913 35.5711 45.1993 35.0039C45.4328 34.3308 45.6312 33.6462 45.7939 32.953L46.528 29.6875H51.3584L49.7654 36.9893Z"
                fill="white" />
            <path
                d="M57.2602 36.9888H55.792L56.5995 33.2433L57.0179 31.4833L56.2324 32.5888L52.694 36.9524H50.9102L52.4665 29.6797H53.9347L53.1565 33.4542L52.7381 35.2288L53.5236 34.1815L57.0473 29.7306H58.8385L57.2602 36.9888Z"
                fill="white" />
            <path
            d="M63.7353 35.0858C63.6551 35.456 63.4818 35.8 63.2316 36.0861C62.9813 36.3722 62.6622 36.591 62.3038 36.7222C61.0615 37.1896 59.6863 37.1714 58.457 36.6713L58.7287 35.3767C59.5949 35.7986 60.8649 36.2131 61.7385 35.5731C61.9609 35.4218 62.118 35.1935 62.179 34.9331C62.2434 34.7509 62.2379 34.5516 62.1634 34.3731C62.0889 34.1947 61.9507 34.0497 61.7752 33.9658C61.5088 33.8538 61.2199 33.8041 60.931 33.8204H60.0647L60.3143 32.6277H61.2099C61.5391 32.6432 61.865 32.5568 62.1422 32.3804C62.2623 32.2951 62.3638 32.1868 62.4408 32.0618C62.5177 31.9369 62.5685 31.7979 62.5901 31.6531C62.62 31.5552 62.6249 31.4514 62.6044 31.3512C62.5839 31.2509 62.5386 31.1572 62.4726 31.0786C62.3528 30.9753 62.2126 30.898 62.0609 30.8517C61.9092 30.8053 61.7493 30.791 61.5917 30.8095C60.9739 30.8186 60.3644 30.9522 59.8004 31.2022L59.9987 29.9004C60.3435 29.8106 60.6943 29.745 61.0484 29.704C61.4147 29.6434 61.7856 29.6142 62.1569 29.6167C62.7849 29.557 63.4123 29.7364 63.9114 30.1186C64.0857 30.2883 64.2099 30.502 64.2707 30.7365C64.3315 30.971 64.3266 31.2175 64.2565 31.4495C64.1756 31.8361 63.9933 32.1948 63.7279 32.4895C63.452 32.7928 63.0877 33.0037 62.6855 33.0931C62.8689 33.1214 63.0443 33.1879 63.1999 33.2881C63.3555 33.3884 63.488 33.5203 63.5884 33.6749C63.7083 33.8879 63.7831 34.1229 63.8084 34.3654C63.8336 34.608 63.8087 34.8531 63.7353 35.0858Z"
            fill="white" />
        <path
            d="M70.7085 36.9888H69.2403L70.0478 33.2433L70.4736 31.4833L69.7394 32.5888L66.201 36.9524H64.3438L65.9001 29.6797H67.3683L66.5901 33.4542L66.1717 35.2288L66.9572 34.1815L70.4736 29.7161H72.2648L70.7085 36.9888Z"
            fill="white" />
        <path
            d="M77.8162 36.9915H76.348L77.0234 33.8424H74.087L73.4116 36.9915H71.9434L73.507 29.7188H74.9752L74.3439 32.6278H77.2803L77.9116 29.7188H79.3799L77.8162 36.9915Z"
            fill="white" />
        <path d="M84.2915 30.9987H81.7295L80.4448 36.9915H78.9766L80.5329 29.7188H84.5925L84.2915 30.9987Z"
            fill="white" />
        <path
            d="M90.8748 33.3548C90.6761 34.484 90.0614 35.5 89.1496 36.2057C88.385 36.7908 87.4436 37.1034 86.4775 37.093C86.0562 37.1226 85.6335 37.0605 85.2391 36.9109C84.8447 36.7614 84.4881 36.5282 84.1944 36.2275C83.879 35.8292 83.6672 35.3603 83.5776 34.8619C83.488 34.3636 83.5234 33.851 83.6805 33.3693C83.8859 32.2506 84.5 31.2461 85.4057 30.5475C86.1722 29.9676 87.1134 29.6602 88.0778 29.6748C88.4994 29.6461 88.9223 29.708 89.3176 29.8561C89.7129 30.0042 90.0713 30.2351 90.3682 30.533C90.6741 30.929 90.8798 31.3919 90.9679 31.8829C91.056 32.3738 91.0241 32.8786 90.8748 33.3548ZM89.2964 33.3548C89.524 32.2857 89.4212 31.5366 88.9881 31.093C88.8371 30.9506 88.6582 30.8403 88.4625 30.769C88.2668 30.6977 88.0584 30.6669 87.8502 30.6784C87.3837 30.6768 86.9287 30.8219 86.5509 31.093C85.8447 31.656 85.3825 32.4652 85.2588 33.3548C85.0313 34.4239 85.134 35.173 85.5672 35.6166C85.7182 35.759 85.8971 35.8693 86.0928 35.9406C86.2885 36.0119 86.4968 36.0427 86.705 36.0312C87.1715 36.0328 87.6265 35.8877 88.0044 35.6166C88.7105 35.0536 89.1727 34.2444 89.2964 33.3548Z"
            fill="white" />
        <path
            d="M96.6526 34.9505C96.5438 35.4889 96.2412 35.9697 95.801 36.3033C95.0777 36.8009 94.2059 37.0418 93.3271 36.9869H91.0293L92.5856 29.7142H95.0008C95.5876 29.671 96.172 29.8247 96.6599 30.1505C96.8543 30.3146 96.9956 30.5318 97.0661 30.775C97.1366 31.0182 97.1332 31.2765 97.0563 31.5178C96.9736 31.9725 96.7443 32.3884 96.403 32.7033C96.1482 32.9399 95.8378 33.1099 95.5 33.1978C95.7 33.2235 95.8925 33.2896 96.0656 33.3922C96.2386 33.4948 96.3884 33.6317 96.5058 33.7942C96.6074 33.9673 96.6727 34.1589 96.6979 34.3575C96.7232 34.5562 96.7077 34.7579 96.6526 34.9505ZM95.0963 34.8342C95.1398 34.7133 95.1526 34.5837 95.1334 34.4568C95.1142 34.3299 95.0636 34.2097 94.9861 34.1069C94.855 33.987 94.6991 33.8969 94.5292 33.8429C94.3593 33.7888 94.1795 33.7722 94.0024 33.7942H93.1802L92.7471 35.8087H93.5693C93.9508 35.8267 94.3276 35.7193 94.6411 35.5033C94.8695 35.3421 95.0312 35.1044 95.0963 34.8342ZM95.4927 31.7651C95.5325 31.5985 95.5117 31.4234 95.4339 31.2705C95.2945 31.0233 94.9715 30.8996 94.4723 30.8996H93.8116L93.4225 32.7105H94.1566C94.5229 32.7323 94.8835 32.6123 95.1623 32.376C95.337 32.2137 95.453 31.999 95.4927 31.7651Z"
            fill="white" />
        <path
            d="M103.803 36.9915H102.122L101.858 35.3551H98.8257L97.8567 36.9915H96.2637L100.749 29.7188H102.452L103.803 36.9915ZM101.674 34.1842L101.234 31.2097L99.5598 34.1842H101.674Z"
            fill="white" />
        <path
            d="M109.529 36.9876H108.061L108.641 34.2822H107.752L105.712 36.9876H103.928L106.328 33.9912C106.003 33.8952 105.72 33.6959 105.521 33.424C105.388 33.2085 105.3 32.9681 105.265 32.7179C105.23 32.4676 105.247 32.2129 105.315 31.9694C105.374 31.6236 105.505 31.2938 105.7 31.0008C105.895 30.7079 106.149 30.4582 106.446 30.2676C107.133 29.8723 107.92 29.6804 108.714 29.7149H111.093L109.529 36.9876ZM108.861 33.1331L109.353 30.864H108.509C108.287 30.8489 108.064 30.8778 107.853 30.949C107.643 31.0202 107.449 31.1322 107.283 31.2785C107.084 31.4768 106.95 31.7305 106.901 32.0058C106.851 32.1507 106.844 32.3065 106.879 32.4556C106.914 32.6046 106.99 32.741 107.099 32.8494C107.361 33.0616 107.694 33.1657 108.031 33.1403L108.861 33.1331Z"
            fill="white" />
        <path
            d="M120.725 36.9915H118.838L116.893 33.3551L116.085 36.9915H114.617L116.181 29.7188H117.649L116.981 32.8315L120.336 29.7188H122.105L118.332 33.0133L120.725 36.9915Z"
            fill="white" />
        <path
            d="M127.955 33.3548C127.763 34.4777 127.162 35.4921 126.266 36.2057C125.499 36.7846 124.558 37.0919 123.594 37.0784C123.172 37.1062 122.749 37.0432 122.354 36.8938C121.958 36.7444 121.6 36.5122 121.304 36.213C120.991 35.8131 120.782 35.3439 120.694 34.8459C120.606 34.3479 120.641 33.8361 120.797 33.3548C120.996 32.2464 121.596 31.2481 122.486 30.5475C123.253 29.9686 124.194 29.6613 125.158 29.6748C125.58 29.6461 126.002 29.708 126.398 29.8561C126.793 30.0042 127.151 30.2351 127.448 30.533C127.755 30.9284 127.962 31.3913 128.05 31.8825C128.138 32.3737 128.105 32.8787 127.955 33.3548ZM126.377 33.3548C126.611 32.2857 126.509 31.5366 126.068 31.093C125.919 30.9508 125.741 30.8406 125.546 30.7693C125.352 30.698 125.145 30.667 124.938 30.6784C124.471 30.6768 124.016 30.8219 123.638 31.093C122.932 31.656 122.47 32.4652 122.346 33.3548C122.119 34.4239 122.221 35.173 122.655 35.6166C122.804 35.7588 122.982 35.869 123.176 35.9403C123.371 36.0116 123.578 36.0426 123.785 36.0312C124.252 36.0407 124.709 35.9032 125.092 35.6384C125.8 35.0688 126.26 34.2512 126.377 33.3548Z"
            fill="white" />
        <path
            d="M136.442 36.9915H134.974L136.31 30.7588L132.823 36.9915H131.406L130.782 30.7588L129.431 36.9915H127.963L129.527 29.7188H132.045L132.558 35.1878L135.495 29.7188H138.013L136.442 36.9915Z"
            fill="white" />
        <path
            d="M143.489 36.9915H142.021L143.327 30.9042H140.435L139.128 36.9915H137.66L139.224 29.7188H145.097L143.489 36.9915Z"
            fill="white" />
        <path
            d="M151.623 36.9915H149.949L149.678 35.3551H146.653L145.677 36.9915H144.084L148.577 29.7188H150.272L151.623 36.9915ZM149.502 34.1842L149.025 31.1806L147.38 34.1842H149.502Z"
            fill="white" />
        <path
            d="M158.178 36.9915H156.709L157.385 33.8424H154.448L153.773 36.9915H152.305L153.861 29.7188H155.329L154.698 32.6278H157.634L158.266 29.7188H159.734L158.178 36.9915Z"
            fill="white" />
        <path
            d="M165.651 36.9888H164.183L164.983 33.2433L165.401 31.4833L164.63 32.5888L161.092 36.9524H159.301L160.864 29.6797H162.333L161.54 33.4542L161.114 35.2288L161.9 34.1815L165.431 29.7306H167.214L165.651 36.9888Z"
            fill="white" />
        <path
            d="M172.075 36.9874H170.607L171.186 34.2819H170.298L168.294 36.9874H166.518L168.918 33.991C168.599 33.8995 168.316 33.7113 168.111 33.4528C167.977 33.2375 167.89 32.9969 167.856 32.7464C167.822 32.4958 167.841 32.241 167.912 31.9983C167.972 31.6563 168.101 31.33 168.293 31.0398C168.485 30.7495 168.735 30.5015 169.028 30.311C169.715 29.9137 170.502 29.7218 171.297 29.7583H173.668L172.075 36.9874ZM171.407 33.1328L171.891 30.8637H171.047C170.825 30.8483 170.602 30.877 170.392 30.9482C170.181 31.0194 169.987 31.1316 169.821 31.2783C169.619 31.4746 169.486 31.7292 169.439 32.0056C169.39 32.1505 169.382 32.3063 169.417 32.4554C169.452 32.6044 169.528 32.7408 169.638 32.8492C169.899 33.0613 170.233 33.1655 170.57 33.1401L171.407 33.1328Z"
            fill="white" />
        <path d="M174.013 26.6562H44.7949V27.5217H174.013V26.6562Z" fill="white" />
    </g>
    <defs>
        <clipPath id="clip0">
            <rect width="178.462" height="40" fill="white" />
        </clipPath>
    </defs>
</svg></template>
