<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Запросы на сверку взаиморасчетов</div>

        <div class="hr"></div>

        <div v-if="contracts.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список договоров пуст" />
        </div>

        <div v-else>

            <div v-if="main?.eva.length > 0" class="alert alert-info mt-4">
                В этом разделе сейчас не отображаются документы по ООО «Стоун Развитие», ведется разработка!
            </div>

            <div class="mt-4 row">
                <div class="col-md-6 col-lg-6">
                    <ui-input-box-floating :error="errors.pref">
                        <select class="form-control form-select" @change="selectPref" v-model="pref"
                            :disabled="loadingSend">
                            <option v-for="item in lessor" :value="item.pref">{{ item.name }}</option>
                        </select>
                        <ui-input-label>Лизингодатель</ui-input-label>
                    </ui-input-box-floating>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mt-3">
                                <ui-input-box-floating class="datepicker-wrapper" :error="errors.dateStart">
                                    <ui-input v-model="dateStart" placeholder="Начало периода" autocomplete="off"
                                        :disabled="loadingSend" type="date" />
                                    <label>Начало периода</label>
                                </ui-input-box-floating>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mt-3">
                                <ui-input-box-floating class="datepicker-wrapper" :error="errors.dateEnd">
                                    <ui-input v-model="dateEnd" placeholder="Конец периода" autocomplete="off"
                                        :disabled="loadingSend" type="date" />
                                    <label>Конец периода</label>
                                </ui-input-box-floating>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 mt-2">
                        <ui-button @click="setTreatie(item, true)" v-for="item in treaties" :disabled="loadingSend"
                            class="btn-base btn-small mt-1 me-1">
                            {{ item }}
                            <Trash class="icon" />
                        </ui-button>
                    </div>

                    <ui-button @click="show" class="btn-ghost btn-select">

                        <div class="row">
                            <div class="col px-0">
                                Список договоров ({{ treaties.length }}/{{ contracts.filter(item => item.pref ===
                                    pref).length }})
                            </div>
                            <div class="col-auto text-end pe-0">
                                <UpIcon v-if="listTreaties" class="icon" />
                                <DownIcon v-else class="icon" />
                            </div>
                        </div>

                    </ui-button>

                    <div class="position-relative">

                        <div v-if="listTreaties" class="row mt-3 mx-0 list-select scroll"
                            :style="{ overflowY: loadingSend ? 'hidden' : 'auto' }">

                            <div class="disabled" v-if="loadingSend"></div>

                            <div class="col-6 col-md-4 dl-request"
                                v-for="item in contracts.filter(item => item.pref === pref)"
                                @click="setTreatie(item.name, treaties.some(e => item.name === e))"
                                :class="item.status === 'Действует' && 'active'">

                                <div class="form-group">
                                    <label @click.stop>
                                        <input type="checkbox" @change="checkbox" :value="item.name"
                                            :checked="treaties.some(e => item.name === e)">
                                        <span>{{ item.name }}</span>
                                    </label>
                                </div>

                                <span class="info">{{ item.status }}</span>

                            </div>

                        </div>

                    </div>

                    <div v-if="error" class="login-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ error }}</span>
                    </div>

                    <div v-if="success" class="success-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>{{ success }}</span>
                    </div>

                    <div v-if="!chekTime" class="login-alert p-3 text-center mt-3">
                        <div class="text-primary">Внимание!</div>
                        <span>Ваш запрос принят и будет выполнен в течение примерно 20 минут.
                            Сообщение о выполнении будет выслано на Ваш email {{ this.getEmail }}</span>
                    </div>

                </div>
            </div>

            <ui-button @click="send" class="btn-base mt-3 me-2" :loading="loadingSend" :disabled="loadingSend || !chekTime">Отправить
                запрос</ui-button>
            <ui-button v-if="treaties.length !== 0" @click="treaties = [], success = null, error = null, errors = []"
                class="btn-ghost mt-3" :disabled="loadingSend">Очистить список</ui-button>


            <div>
                <h2 class="title">Список запросов</h2>
                <div size="2" class="hr"></div>
            </div>

            <div v-if="list.length !== 0">

                <div class="scroll table-responsive mt-4">
                    <table class="table text-nowrap mb-0" style="vertical-align: top;">
                        <thead>
                            <tr>
                                <th>Дата запроса</th>
                                <th>Дата начала</th>
                                <th>Дата окончания</th>
                                <th>Номера договоров лизинга</th>
                                <th>Статус</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item, index in list">
                                <td class="tdt">{{ dateMask(item['ДатаПолучения']) }}</td>
                                <td class="tdt">{{ dateMask(item['ДатаНачПлан']) }}</td>
                                <td class="tdt">{{ dateMask(item['ДатаКонПлан']) }}</td>
                                <td style="min-width: 300px;">

                                    <ul class="mini-btn-items mt-1" :style="item.more && { 'flex-wrap': 'wrap' }">

                                        <span v-for="dl, indexDl in item.dl" style="margin-bottom: 1px;">
                                            <li class="mini-btn-item" v-if="indexDl < 4 || item.more">
                                                <span class="mini-btn-item-name">{{ dl }}</span>
                                            </li>
                                        </span>

                                        <li class="mini-btn-item btn-more" @click="more(index)"
                                            v-if="item.dl.length > 4 && !item.more" title="Развернуть список">
                                            <PointsIcon class="icon p-0" />
                                        </li>

                                    </ul>

                                </td>
                                <td class="tdt">{{ item.status }}</td>
                                <td class="text-end">

                                    <div v-if="item.fileName === 'Ошибка проверки. Надо обратиться в отдел Рассылки.'" class="text-start">
                                        <div>Ошибка формирования</div> Инструкция выслана на {{ getEmail }}
                                    </div>

                                    <ui-button v-else @click="download(index, 'sv', item.recId)"
                                        :disabled="item.status === 'Не выполнен' || item.status === 'Ошибка' || item.loading"
                                        :progress="item.progress ? item.progress : 0" :loading="item.loading"
                                        class="btn-base btn-small">
                                        {{ item.status === 'Ошибка' ? 'Ошибка' : 'Скачать' }}
                                    </ui-button>


                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <Pagination class="mt-3" :page="page" :total="listTotal" :item="itemPage" :loading="loadingPage" />

            </div>

            <div v-else class="position-relative no-list-data">
                <ui-no-data-page text="Список запросов пуст" />
            </div>

        </div>

    </div>


    <ui-modal v-if="modal.msg" :close="modalClose">
        <div class="modal-header">
            <h5 class="modal-title">{{ modal.title }}</h5>
            <button class="btn-close" type="button" @click="modalClose"></button>
        </div>
        <div class="modal-body">
            {{ modal.msg }}
        </div>
        <div class="modal-footer"></div>
    </ui-modal>
</template>

<script>
import { getApi, postApi, dateMask, downloadApi } from '@/run'
import moment from 'moment'
import { mapGetters, mapState } from "vuex"

import DownIcon from "@/assets/svg/down.vue"
import UpIcon from "@/assets/svg/up.vue"
import Trash from "@/assets/svg/trash.vue"
import PointsIcon from "@/assets/svg/points.vue"

import Pagination from '@/component/pagination'

export default {
    name: 'request.accounting',
    data() {
        return {
            loading: false,
            loadingSend: false,
            loadingPage: false,
            listTreaties: false,
            modal: {},
            list: [],
            listTotal: null,
            itemPage: 0,
            pref: null,
            lessor: [],
            contracts: [],
            treaties: [],
            error: null,
            errors: [],
            success: null,
            dateStart: moment().format('YYYY-01-01'),
            dateEnd: moment().format('YYYY-MM-DD'),
            selectMenu: null,
            dateMask: dateMask,
            chekTime: true
        }
    },
    components: {
        DownIcon,
        UpIcon,
        Trash,
        PointsIcon,
        Pagination
    },
    created() {
        document.title = "Запросы на сверку взаиморасчетов - STONE-XXI"
        this.start()
    },
    computed: {
        ...mapGetters({
            getEmail: 'main/getEmail'
        }),
        ...mapState({
            main: 'main'
        })
    },
    methods: {
        start() {

            this.loading = true

            getApi('/request/mutualsettlements', {}).then(response => {

                if (response.status) {
                    this.pref = response.pref
                    this.lessor = response.lessor
                    this.contracts = response.contracts
                    this.list = response.list
                    this.listTotal = response.total
                    this.listTreaties = this.list.length !== 0 ? false : true
                    this.chekTime = response.chekTime
                }

                this.loading = false

            })

        },
        more(e) {
            this.list = this.list.map((item, i) => i === e ? { ...item, more: true } : item)
        },
        page(page) {

            this.loadingPage = true

            this.itemPage = page

            getApi('/request/mutualsettlements', {
                offset: page
            }).then(response => {

                if (response.status) {

                    this.listTotal = response.total
                    this.list = response.list

                }

                this.loadingPage = false

            })

        },
        send() {

            this.loadingSend = true
            this.error = null
            this.errors = []
            this.success = null

            postApi('/request/requestMutualsettlements', {
                pref: this.pref,
                treaties: this.treaties,
                dateStart: this.dateStart,
                dateEnd: this.dateEnd
            }).then(response => {

                if (response.status) {
                    this.itemPage = 0
                    this.success = response.success
                    this.listTreaties = false
                    this.listTotal = response.total
                    this.list = response.list
                    this.chekTime = false
                }

                if (response.error) {
                    this.error = response.error
                    this.errors = response.errors ? response.errors : []
                }

                this.loadingSend = false

            })

        },
        progress(btn, progress) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, progress: progress } : { ...item })
        },
        download(btn, type, file) {
            this.list = this.list.map((item, i) => i === btn ? { ...item, loading: true } : { ...item })
            downloadApi('/download', {
                type: type,
                file: file
            }, btn, this.progress).then(response => {

                this.list = this.list.map((item, i) => i === btn ? { ...item, progress: false, loading: false } : { ...item })

                const jsonData = (typeof response === "string") ? JSON.parse(response) : response

                if (jsonData.error) {
                    this.modal = {
                        title: 'Ошибка',
                        msg: jsonData.error
                    }
                }

            })
        },
        selectPref() {
            this.error = null
            this.errors = []
            this.listTreaties = true
            this.success = null
            this.treaties = []
        },
        show() {
            this.listTreaties = !this.listTreaties
        },
        checkbox(event) {
            this.error = null
            this.errors = []
            this.success = null
            this.treaties = event.target.checked ? [...this.treaties, event.target.value] : this.treaties.filter(item => item !== event.target.value)
        },
        setTreatie(e, del) {
            this.error = null
            this.errors = []
            this.success = null
            this.treaties = !del ? [...this.treaties, e] : this.treaties.filter(item => item !== e)
        },
        modalClose() {
            this.modal = {}
        }
    }
}
</script>