<template>
  <div class="form-group form-floating" :class="{ 'has-error': error }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ui-input-box-floating',
  props: {
    error: String
  }
}
</script>