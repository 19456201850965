<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">
        <div class="page-title">Главная</div>

        <div size="2" class="hr"></div>

        <!-- <div class="header-alert mt-3">
            <div class="header-alert__status"><b class="text-danger fz-16">Важная информация!</b>
                <div class="text-secondary">{{ news.DATE }}</div>
            </div>
            <div class="header-alert__info">
                <a href=""><b>О субсидии Минпромторга</b></a>
                <div class="header-alert__info-title">В связи с отказом Минпромторга в предоставленни государственной
                    субсидии очень длинный текст прям очень длинный</div>
            </div>
        </div> -->

        <!-- <div v-if="news.NAME && news.LINK" class="alert alert-info mt-4 mb-0 "> -->
        <div v-if="news.NAME && news.LINK" class="mt-3 mb-2 ">
            <div>
                <span class="text-danger fz-16">Важная информация!</span> <span class="text-secondary"> {{ news.DATE
                    }}</span>
            </div>

            <a :href="news.LINK" target="_blank" style=" text-decoration: none ">{{ news.NAME }}</a>

        </div>

        <!-- <h1>Заявки</h1> -->
        <!-- <div class="row">
        <div class="col-auto mb-3">
            <div class="applications-tag me-2">Новых КП: <b>1</b></div>
        </div>
        <div class="col-auto mb-3">
            <div class="applications-tag">Загрузить файлов: <b>7</b></div>
        </div>
        <div class="col-auto mb-3">
            <div class="applications-tag">Документов на проверке: <b>15</b></div>
        </div>
        <div class="col-auto mb-3">
            <div class="applications-tag">Заявок отклонено: <b>0</b></div>
        </div>
        <div class="col-auto mb-3">
            <div class="applications-tag">Заявок на рассмотрении: <b>13</b></div>
        </div>
    </div> -->
        <!-- <div class="row mb-3">
        <div class="col-auto">
            <RouterLink class="btn btn-base me-2" to="">Подать новую заявку</RouterLink>

        </div>
    </div> -->
        <div class="row">

            <div v-if="dicAppliedLimits" class="col-md-12 mt-3">
                <div class="index-info">

                    <div class="row justify-content-between align-items-center">
                        <div class="col-auto">
                            <div class="h4 mb-3 mt-0 text-primary">Cтатус "Надежный клиент"</div>
                        </div>
                    </div>

                    <div class="row align-items-center justify-content-between">
                        <div class="col-12">
                            В благодарность за наше плодотворное сотрудничество Вам присвоен статус "Надежный клиент".
                            Вы
                            можете оформить лизинг автотранспорта или спецтехники в рамках заранее одобренного лимита по
                            финансированию без проверок и документов.
                        </div>
                        <div class="col-12 mt-3">Ваш лимит: <b>{{ moneyMask(dicAppliedLimits) }}</b> ₽</div>
                    </div>

                </div>
            </div>


            <div class="col-md-6 mt-3">
                <RouterLink to="/contracts" class="link_none">
                    <div class="index-info">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-auto">
                                <div class="h4 mb-1 mt-0 text-primary">Договоры</div>
                            </div>
                            <div class="col-auto">
                                <div class="note text-secondary mb-1">Действующих договоров: <RouterLink
                                        to="/contracts">{{
                                            contracts.count }}</RouterLink>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div class="col-auto mb-2">Сумма действующих договоров:</div>
                            <div class="col-auto mb-2">
                                <div class="h6 mb-0 mt-0">{{ moneyMask(contracts.sum) }} ₽</div>
                            </div>
                        </div>
                        <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div class="col-auto mb-2">Выплачено:</div>
                            <div class="col-auto mb-2">
                                <div class="h6 mb-0 mt-0">{{ moneyMask(contracts.paid) }} ₽</div>
                            </div>
                        </div>

                        <div v-if="moneySrtToNumber(contracts.debt) !== 0">

                            <div v-if="moneySrtToNumber(contracts.debt) < 0"
                                class="row align-items-center justify-content-between mb-n2 m-t-10">
                                <div class="col-auto mb-2 text-success">Переплата:</div>
                                <div class="col-auto mb-2">
                                    <div class="h6 mb-0 mt-0">{{ moneyMask(contracts.debt).replace('-', '') }} ₽</div>
                                </div>
                            </div>

                            <div v-else class="row align-items-center justify-content-between mb-n2 m-t-10">
                                <div class="col-auto mb-2 text-danger">Задолженность:</div>
                                <div class="col-auto mb-2">
                                    <div class="h6 mb-0 mt-0">{{ moneyMask(contracts.debt) }} ₽</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </RouterLink>
            </div>
            <!-- <div class="col-md-6 mb-4">
            <div class="index-info">
                <div class="row justify-content-between align-items-center">
                    <div class="h4 mb-1 mt-0 text-primary">Вознаграждения</div>
                </div>
                <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                    <div class="col-auto mb-2">Планируемые выплаты:</div>
                    <div class="col-auto mb-2"><a class="h6 mb-0 mt-0" href="">49 900 000,00 ₽</a></div>
                </div>
                <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                    <div class="col-auto mb-2">На подписании:</div>
                    <div class="col-auto mb-2"><a class="h6 mb-0 mt-0" href="">23 750 000,00 ₽</a></div>
                </div>
                <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                    <div class="col-auto mb-2 text-success">Ожидает перечисления:</div>
                    <div class="col-auto mb-2"><a class="h6 mb-0 mt-0" href="">25 500,00 ₽</a></div>
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-4">
            <div class="index-info">
                <div class="row justify-content-between align-items-center">
                    <div class="h4 mb-1 mt-0 text-primary">Сделки</div>
                </div>
                <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                    <div class="col-auto mb-2">Сделок заключено: <b>5</b></div>
                    <div class="col-auto mb-2"><a class="h6 mb-0 mt-0" href="">49 900 000,00 ₽</a></div>
                </div>
                <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                    <div class="col-auto mb-2">Авто отгружено: <b>37</b></div>
                    <div class="col-auto mb-2"><a class="h6 mb-0 mt-0" href="">23 750 000,00 ₽</a></div>
                </div>
                <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                    <div class="col-auto mb-2">Авто к поставке: <b>5</b></div>
                    <div class="col-auto mb-2"><a class="h6 mb-0 mt-0" href="">25 500,00 ₽</a></div>
                </div>
            </div>
        </div> -->
            <div class="col-md-6 mt-3">
                <RouterLink to="/accounting/photofine" class="link_none">
                    <div class="index-info">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-auto">
                                <div class="h4 mb-1 mt-0 text-primary">Фотоштрафы</div>
                            </div>
                            <!-- <div class="col-auto">
                        <div class="note text-secondary mb-1">Последний выставленный штраф: 29.12.19 на 500 ₽</div>
                    </div> -->
                        </div>
                        <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div class="col-auto mb-2">Новых штрафов: <b>{{ photofine.countNew }}</b></div>
                            <div class="col-auto mb-2">
                                <div class="h6 mb-0 mt-0">{{ moneyMask(photofine.sumNew) }} ₽</div>
                            </div>
                        </div>
                        <div class="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div class="col-auto mb-2">Не оплачено штрафов: <b>{{ photofine.count }}</b></div>
                            <div class="col-auto mb-2">
                                <div class="h6 mb-0 mt-0">{{ moneyMask(photofine.sum) }} ₽</div>
                            </div>
                        </div>
                    </div>
                </RouterLink>
            </div>

        </div>


        <!-- <div class="hr mb-3"></div>

    Благодарим Вас за сотрудничество с нашей компанией и своевременную оплату счетов!

    <h2 class="mt-3">Используя данный сервис вы можете</h2>

    <ul>
        <li>Получать электронную версию бухгалтерских документов (счета, счета-фактуры и т.д.).</li>
        <li>Получать информацию по ранее выданным бухгалтерским документам (за любой период).</li>
        <li>Просматривать графики договоров: График лизинговых платежей, График порядка расчетов.</li>
    </ul>

    <h2 class="mt-3">Ознакомиться с образцами</h2>
    <ul>
        <li>запроса на дублирование бухгалтерских документов.</li>
        <li>запроса на акт сверки взаиморасчетов.</li>
        <li>уведомления о смене реквизитов, фактического адреса, почтового адреса и электронных адресов.</li>
    </ul>

    <div class="hr mt-3"></div>

    <div class="mt-3">
        <div class="alert alert-info">Вниманию бухгалтерии!</div>
        <p style="text-align: justify;">
            С 01.01.2022 г. вступил в силу стандарт по бухгалтерскому учету ФСБУ 25/2018 «Бухгалтерский учет аренды».
            Это
            означает, что по всем договорам лизинга, действующим на 1 января 2022 г., независимо от того, кто указан в
            договоре в качестве Балансодержателя, полученное в лизинг имущество Лизингополучателю предстоит отразить у
            себя в балансе как Актив в форме права пользования, а также отразить Обязательство по аренде. В дальнейшем
            по Активу начисляется амортизация по сроку договора лизинга, а Обязательство по аренде увеличивается на
            начисленные проценты и уменьшается на оплаченные лизинговые платежи.
        </p>
        <p style="text-align: justify;">
            Если ваша организация обязана применять ФСБУ 25/2018, то мы подготовили для бухгалтера информацию по учету
            на 01.01.2022 – стоимость Актива в форме права пользования и Обязательства по аренде.
            Дисконтирование обязательств по аренде проведено по ставке, равной ставке привлечения денежных средств = 11%
            Информационное письмо с показателями на 01.01.2022 г. и Расчет выложены в Личном кабинете в разделе
            Страничка бухгалтера - Договоры.
        </p>
        <p style="text-align: justify;" class="mb-0">
            По всем вопросам применения ФСБУ 25/2018 можно обращаться на Горячую линию по е-mail: fsbu@stone-xxi.ru,
            наши специалисты готовы Вам помочь разобраться в тонкостях учета.
        </p>
    </div> -->




        <div class="row">
            <div v-for="item in adv" class="col-lg-4 mt-4">
                <div class="index-promo">
                    <div class="index-promo__img-wrap">

                        <img v-if="item.loadingImg" class="index-promo__img" :src="item.IMG">

                        <div v-else class="h-100" style="position: absolute; width: 100%; background: #004680;">
                            <div class="page-center">
                                <div class="page-center-box text-center">
                                    <ui-loading-page :white="true" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="index-promo__inner">
                        <div style="min-height: 58px; height: auto;" class="page-center">

                            <div class="page-center-box text-center">
                                <a class="h5 mb-2 fw-normal d-block" :href="item.LINK" target="_blank">{{ item.NAME
                                    }}</a>
                            </div>

                        </div>
                        <p v-html="item.PREVIEW_TEXT"></p>
                        <a class="btn btn-base" :href="item.LINK" target="_blank">Подробнее</a>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
import axios from 'axios'
import { getApi, moneyMask, moneySrtToNumber } from '@/run'

export default {
    name: 'Main',
    data() {
        return {
            loading: false,
            contracts: {},
            photofine: {},
            moneyMask: moneyMask,
            moneySrtToNumber: moneySrtToNumber,
            news: {},
            adv: [],
            dicAppliedLimits: false
        }
    },
    components: {},
    created() {
        document.title = "Главная - STONE-XXI"
        this.start()
    },
    methods: {
        start() {
            this.loading = true

            getApi('/index', {}).then(response => {

                if (response.status) {
                    this.contracts = response.contracts
                    this.photofine = response.photofine
                    this.dicAppliedLimits = response.dicAppliedLimits
                }

                return true
            }).then(() => {
                axios.get('https://www.stone-xxi.ru/sale/lkAdv.php').then(response => {

                    if (response.data.NEWS) {
                        this.news = response.data.NEWS
                    }

                    if (response.data.ADV_MAIN) {
                        this.adv = response.data.ADV_MAIN
                        this.adv.forEach((item, index) => {
                            this.loadingImg(index, item.IMG)
                        })
                    }

                    this.loading = false

                })
            })
        },
        loadingImg(index, image) {
            const loadImage = new Image()
            loadImage.src = image
            loadImage.onload = () => {
                this.adv = this.adv.map((item, i) => i === index ? { ...item, loadingImg: true } : item)
            }
        }
    }
}
</script>