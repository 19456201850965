<template>
    <ui-input :value="modelValue" @input="updateInput" placeholder="Пароль" :type="show ? 'text' : 'password'"
        autocomplete="off" />
    <button type="button" @click="updateShow" class="custom-group-icon js-drop-eye">
        <i v-if="show" class="icon-eye-closed"></i>
        <i v-else class="icon-eye"></i>
    </button>
</template>

<script>

export default {

    name: 'ui-input-password',
    data: () => {
        return {
            show: false,
        }
    },

    props: {
        modelValue: [String, Number],
        label: String
    },

    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.value)
        },
        updateShow(event) {
            this.show = !this.show
            event.preventDefault()
        }
    }


}

</script>

<style>
.js-drop-eye {
    background: none;
    border: none;
}
</style>