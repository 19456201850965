<template>
    <div :class="white ? 'spinner-white' : 'spinner'"></div>
</template>
  
<script>
export default {
    name: 'ui-loading-page',
    props: {
        white: Boolean
    }
}
</script>