<template>
    <div class="sidebar pb-4">

        <div class="header-lk">
            <div class="header-lk__title mb-1">Добро пожаловать в личный кабинет, {{ getCompanies.filter(item => item.id ===
                getCompanyId &&
                item)[0].company }}!
            </div>
            <div class="d-flex">
                <div class="form-group flex-grow-1">
                    <select class="form-control form-control-base form-select" @change="company" v-model="companyId">
                        <option v-for="item in getCompanies" :value="item.id">{{ item.company }}</option>
                    </select>
                </div>
                <!-- <a class="btn btn-base" href="/signing-contract.html"><i class="icon-loupe"></i></a> -->
            </div>
        </div>


        <nav class="menu-nav">
            <ul class="menu-nav__list">

                <li class="menu-nav__item">
                    <RouterLink class="menu-nav__link" to="/" @click="closeMenu"
                        :class="this.$route.name === 'main' && 'active'">Главная
                    </RouterLink>
                </li>

                <li class="menu-nav__item">
                    <RouterLink class="menu-nav__link" to="/contracts" @click="closeMenu"
                        :class="(this.$route.name === 'contracts.contracts' || this.$route.name === 'contracts.contract') && 'active'">
                        Договоры
                    </RouterLink>
                </li>

                <li class="menu-nav__item">
                    <RouterLink class="menu-nav__link" to="/maintenance" @click="closeMenu"
                        :class="(this.$route.name === 'main.maintenance' || this.$route.name === 'main.maintenance') && 'active'">
                        Сопровождение договора
                    </RouterLink>
                </li>

                <li class="menu-nav__item">
                    <button to="/" @click="open('accounting')" class="menu-nav__link"
                        :class="item === 'accounting' && 'activeRoot'">
                        <div class="row">
                            <div class="col">Бухгалтерия</div>
                            <div class="col-auto">
                                <UpIcon v-if="item === 'accounting'" class="menu-nav__icon" />
                                <DownIcon v-else class="menu-nav__icon" />
                            </div>
                        </div>
                    </button>
                </li>

                <li class="menu-nav__item" v-if="item === 'accounting'">
                    <RouterLink to="/accounting/documentation" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'accounting.documentation' && 'active'">Бухгалтерские документы
                    </RouterLink>
                </li>

                <li class="menu-nav__item" v-if="item === 'accounting'">
                    <RouterLink to="/accounting/photofine" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'accounting.photofine' && 'active'">Фотоштрафы</RouterLink>
                </li>

                <li class="menu-nav__item" v-if="item === 'accounting'">
                    <RouterLink to="/accounting/contacts" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'accounting.contacts' && 'active'">Контакты</RouterLink>
                </li>

                <li class="menu-nav__item">
                    <button to="/" @click="open('debt')" class="menu-nav__link" :class="item === 'debt' && 'activeRoot'">
                        <div class="row">
                            <div class="col">Задолженность</div>
                            <div class="col-auto">
                                <UpIcon v-if="item === 'debt'" class="menu-nav__icon" />
                                <DownIcon v-else class="menu-nav__icon" />
                            </div>
                        </div>
                    </button>
                </li>

                <li class="menu-nav__item" v-if="item === 'debt'">
                    <RouterLink to="/debt" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'debt.debt' && 'active'">Счета на пени
                    </RouterLink>
                </li>

                <li class="menu-nav__item" v-if="item === 'debt'">
                    <RouterLink to="/debt/info" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'debt.info' && 'active'">Общая информация</RouterLink>
                </li>

                <li class="menu-nav__item">
                    <button to="/" @click="open('insurance')" class="menu-nav__link"
                        :class="item === 'insurance' && 'activeRoot'">
                        <div class="row">
                            <div class="col">Страхование</div>
                            <div class="col-auto">
                                <UpIcon v-if="item === 'insurance'" class="menu-nav__icon" />
                                <DownIcon v-else class="menu-nav__icon" />
                            </div>
                        </div>
                    </button>
                </li>

                <li class="menu-nav__item" v-if="item === 'insurance'">
                    <RouterLink to="/insurance" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'insurance.insurance' && 'active'">Договоры страхования
                    </RouterLink>
                </li>

                <li class="menu-nav__item" v-if="item === 'insurance'">
                    <RouterLink to="/insurance/maintenance" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'insurance.maintenance' && 'active'">Сопровождение договора
                    </RouterLink>
                </li>

                <li class="menu-nav__item" v-if="item === 'insurance'">
                    <RouterLink to="/insurance/new" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'insurance.requestNew' && 'active'">Заявка на страхование
                    </RouterLink>
                </li>

                <li class="menu-nav__item" v-if="item === 'insurance'">
                    <RouterLink to="/insurance/request" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'insurance.request' && 'active'">Запрос по страховому случаю
                    </RouterLink>
                </li>

                <li class="menu-nav__item" v-if="item === 'insurance'">
                    <RouterLink to="/insurance/insurancecase" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'insurance.insurancecase' && 'active'">Страховой случай
                    </RouterLink>
                </li>


                <li class="menu-nav__item">
                    <button to="/" @click="open('request')" class="menu-nav__link"
                        :class="item === 'request' && 'activeRoot'">
                        <div class="row">
                            <div class="col">Запросы на документы</div>
                            <div class="col-auto">
                                <UpIcon v-if="item === 'request'" class="menu-nav__icon" />
                                <DownIcon v-else class="menu-nav__icon" />
                            </div>
                        </div>
                    </button>
                </li>

                <li class="menu-nav__item" v-if="item === 'request'">
                    <RouterLink to="/request/accounting" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'request.accounting' && 'active'">Бухгалтерия</RouterLink>
                </li>

                <!-- <li class="menu-nav__item" v-if="item === 'request'">
                    <RouterLink to="/request/balance" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'request.balance' && 'active'">Балансовая справка</RouterLink>
                </li> -->

                <li class="menu-nav__item" v-if="item === 'request'">
                    <RouterLink to="/request/buysell" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'request.buysell' && 'active'">Выписка из книг покупок/продаж
                    </RouterLink>
                </li>

                <li class="menu-nav__item" v-if="item === 'request'">
                    <RouterLink to="/request/mutualsettlements" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'request.mutualsettlements' && 'active'">Сверка взаиморасчетов
                    </RouterLink>
                </li>

                <li class="menu-nav__item" v-if="item === 'request'">
                    <RouterLink to="/request/photofine" class="menu-nav__link activeList" @click="closeMenu"
                        :class="this.$route.name === 'request.photofine' && 'active'">Сверка по фотоштрафам
                    </RouterLink>
                </li>

                <li class="menu-nav__item">
                    <RouterLink class="menu-nav__link" to="/notifications" @click="closeMenu"
                        :class="(this.$route.name === 'notifications.notifications' || this.$route.name === 'notifications.notification') && 'active'">
                        Уведомления <span v-if="getNotificationTotal && getNotificationTotal !== 0"
                            class="badge bg-warning">+{{ getNotificationTotal }}</span>
                    </RouterLink>
                </li>

                <li class="menu-nav__item">
                    <RouterLink class="menu-nav__link" to="/help" @click="closeMenu"
                        :class="(this.$route.name === 'main.help' || this.$route.name === 'main.help') && 'active'">
                        Памятка клиенту
                    </RouterLink>
                </li>

                <li class="menu-nav__item">
                    <button class="menu-nav__link" @click="logout">Выход</button>
                </li>


            </ul>
        </nav>

        <RouterLink to="/new" @click="closeMenu" class="btn btn-base mt-4">Подать новую заявку на лизинг</RouterLink>

        <div class="personal-managers mt-4">
            Ваш персональный менеджер
            <h6 class="mt-2 mb-2">{{ getPm.name }}</h6>
            <a class="d-flex align-items-center link-black" href="tel:8 (495) 981-19-90">
                <i class="icon-tel me-2"></i>

                <span>8 (495) 981-19-90</span>
                <span v-if="getPm.phone && getPm.phone.length < 8" class="ms-2">доб.{{ getPm.phone }}</span>

            </a>
            <a class="d-flex align-items-center" :href="'mailto:' + getPm.email">
                <i class="icon-mail me-2"></i>
                <span class="link-black">{{ getPm.email }}</span>
            </a>
            <button @click="modal = true" class="btn btn-base btn-small mt-3">
                Связаться с менеджером
            </button>
        </div>
        <!-- <a class="give-feedback mt-4" href=""><img class="give-feedback__img" src="images/review-add.svg">Оставьте
            свой отзыв и помогите нам стать лучше!</a> -->
    </div>

    <ui-modal v-if="modal" :close="modalClose">
        <div class="modal-header">
            <h5 class="modal-title">Связаться с менеджером</h5>
            <button class="btn-close" type="button" @click="modalClose"></button>
        </div>
        <div class="modal-body">
            <p>Разберем ваш запрос в течение 3 рабочих дней.</p>

            <ui-input-box :error="errors.message">
                <ui-textarea v-model="message" placeholder="Сообщение" rows="3" />
            </ui-input-box>

            <div v-if="error" class="login-alert p-3 text-center mt-3">
                <div class="text-primary">Внимание!</div>
                <span>{{ error }}</span>
            </div>

            <div v-if="success" class="success-alert p-3 text-center mt-3">
                <div class="text-primary">Внимание!</div>
                <span>{{ success }}</span>
            </div>

            <ui-button @click="send" class="btn-base mt-3 me-2" :loading="loadingSend"
                :disabled="loadingSend">Отправить</ui-button>
        </div>
        <div class="modal-footer"></div>
    </ui-modal>


    <ui-modal v-if="getNotification" :notification="true" :close="notificationsClose">
        <div class="modal-header">
            <h5 class="modal-title">{{ getNotification.subject }}</h5>
            <button class="btn-close" type="button" @click="notificationsClose"></button>
        </div>
        <div id="print" class="modal-body">
            <div class="mb-3">Дата уведомления: {{ dateMask(getNotification.date) }}</div>
            <div v-html="getNotification.message"></div>
        </div>
        <div class="modal-footer">
            <ui-button @click="read" class="btn-base me-2" :loading="loadingSend"
                :disabled="loadingSend">Прочитано</ui-button><ui-button @click="print" class="btn-ghost">Печать</ui-button>
        </div>
    </ui-modal>
</template>

<script>

import { postApi, dateMask } from '@/run'

import DownIcon from "@/assets/svg/down.vue"
import UpIcon from "@/assets/svg/up.vue"

import { mapGetters, mapMutations } from "vuex";

export default {
    data() {
        return {
            loadingSend: false,
            item: null,
            m: false,
            companyId: null,
            modal: false,
            message: null,
            error: null,
            errors: [],
            success: null,
            companyId: null,
            dateMask: dateMask
        }
    },
    components: {
        DownIcon,
        UpIcon
    },
    computed: {
        ...mapGetters({
            getPm: 'main/getPm',
            getCompanies: 'main/getCompanies',
            getCompanyId: 'main/getCompanyId',
            getNotificationTotal: 'main/getNotificationTotal',
            getNotification: 'main/getNotification'
        })
    },
    methods: {
        ...mapMutations({
            setLoading: 'main/setLoading',
            setData: 'main/setData',
            setRoot: 'main/setRoot',
            setContracts: 'page/setContracts',
            setNotification: 'main/setNotification',
            setNotificationTotal: 'main/setNotificationTotal',
            setNotificationRead: 'main/setNotificationRead'
        }),
        company() {
            document.body.classList.remove('mobile-menu-open')
            this.setLoading(true)
            postApi('/changeCompany', {
                company: this.companyId
            }).then((res) => {

                if (res.status) {
                    this.setContracts([])
                    this.setData(res.root)
                    this.setRoot(true)
                }

                this.setLoading(false)
            })
        },
        print() {

            let printf = document.getElementById('notification').contentWindow
            printf.postMessage({ ...this.getNotification })
            setTimeout(() => printf.print(), 300)

        },
        open(item) {
            this.item = this.item === item ? null : item
        },
        closeMenu() {
            document.body.classList.remove('mobile-menu-open')
        },
        logout() {
            this.setLoading(true)
            document.body.classList.remove('mobile-menu-open')
            postApi('/logout', {}).then((res) => {
                if (res.status) {
                    this.setRoot(false)
                }
                this.setLoading(false)
            })
        },
        read() {
            this.loadingSend = true

            postApi('/notificationRead', {
                id: this.getNotification.id
            }).then(response => {

                this.setNotificationRead(this.getNotification.id)
                this.setNotification(false)
                
                if (response.status) {
                    this.setNotificationTotal(response.newTotal)
                }

                this.loadingSend = false
            })
        },
        send() {
            this.loadingSend = true
            this.error = null
            this.success = null

            postApi('/message', {
                message: this.message
            }).then(response => {

                if (response.error) {
                    this.error = response.error
                    this.errors = response.errors ? response.errors : []
                }

                if (response.status) {
                    this.success = response.success
                    this.errors = []
                }

                this.loadingSend = false
            })
        },
        modalClose() {
            this.modal = false
        },
        notificationsClose() {
            this.setNotification(false)
        }
    },
    created() {
        this.item = this.$route.name.split('.')[0]
        this.companyId = this.getCompanyId
    },
    watch: {
        $route(to, from) {
            this.item = to.name.split('.')[0]
        }
    },
    beforeUpdate() {
        this.message = this.success ? null : this.message
        this.error = null
        this.errors = []
        this.success = null
    }
}
</script>