<template>
    <div>
        <h2 class="title">Информация</h2>
        <div size="2" class="hr"></div>
    </div>



    <div class="alert alert-info mt-4">
        При наступлении страхового случая, прежде, чем обратиться в страховую компанию, мы рекомендуем нашим клиентам
        позвонить в наше Агентство. Наши специалисты по урегулированию убытков смогут скоординировать Вас в правильных
        действиях в различных ситуациях.
    </div>



    <div class="accordion mt-4" id="accordionPanelsStayOpenExample">
        <div ref="tab1" class="accordion-item" :class="tab === 1 && 'active'">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button @click="setTab(1)" class="accordion-button" :class="tab !== 1 && 'collapsed'">УГОН/ХИЩЕНИЕ
                    ТС</button>
            </h2>
            <div class="accordion-collapse collapse" :class="tab === 1 && 'show'">

                <div class="accordion-body px-3" style="background: #fff;">
                    <div class="pb-2"><b>1. Сообщить о хищении ТС</b></div>


                    <p style="text-align:justify;">Сообщить о хищении тс в территориальное отделение полиции (овд) по
                        месту угона.</p>

                    <p style="text-align:justify;">
                        02 - со стационарного телефона, 020, 002 или 911 - с мобильного телефона.
                    </p>


                    <div class="pb-2"><b>2. Позвонить в страховую компанию</b></div>

                    <p style="text-align:justify;">
                        Позвонить в диспетчерскую службу страховой компании для регистрации события и получения
                        консультации.
                    </p>

                    <p style="text-align:justify;">
                        Телефон указан в страховом полисе.
                    </p>

                    <div class="pb-2"><b>3. Если есть спутниковая сигнализация</b></div>

                    <p style="text-align:justify;">
                        Если автомобиль оборудован спутниковой противоугонной системой - связаться с диспетчером
                        компании-оператора и сообщить о случившемся.
                    </p>

                    <p style="text-align:justify;">
                        Телефон указан в договоре с компанией-оператором.
                    </p>


                    <div class="pb-2"><b>4. Посетить отделение полиции</b></div>

                    <p style="text-align:justify;">
                        Посетить территориальное отделение полиции по месту угона и подать письменное заявление.
                    </p>

                    <p style="text-align:justify;">
                        По адресу территориального отделения полиции по месту угона.
                    </p>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                Подать письменное заявление в территориальное отделение полиции по месту угона.
                            </p>

                            <p style="text-align:justify;" class="mt-3">
                                Перечень документов для передачи в ОВД (довозятся после подачи письменного заявления для
                                возбуждения уголовного дела)
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>Балансовая справка (выдается в СТОУН по запросу клиента)</li>
                                    <li>Справка об остаточной стоимости ТС (выдается в СТОУН по запросу клиента)</li>
                                    <li>Доверенность на представление интересов в ОВД (выдается в СТОУН по запросу
                                        клиента)</li>
                                    <li>Устав компании и другие документы (при необходимости, по запросу клиента)</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>Талон уведомление (выдается в ОВД при подаче заявления)</li>
                                    <li>Постановление о возбуждении уголовного дела по факту хищения ТС (выдается ОВД в
                                        течение 10 дней с момента угона)</li>
                                    <li>Постановление о возбуждении уголовного дела по факту хищения ТС (выдается ОВД в
                                        течение 10 дней с момента угона)</li>
                                    <li>Справка по форме 3 (выдается ОВД)</li>
                                    <li>Постановление о приобщении к уголовному делу вещественных доказательств (в
                                        случае приобщения к материалам уголовного дела необходимых для передачи
                                        Страховщику регистрационных документов, ключей и/или других материальных
                                        ценностей)</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <p style="text-align:justify;">
                        По адресу территориального отделения полиции по месту угона
                        02 – со стационарного телефона, 020, 002 или 911 – с мобильного телефона
                    </p>

                    <div class="pb-2"><b>5. Посетить Страховую компанию</b></div>

                    <p style="text-align:justify;">
                        Посетить страховую компанию и подать письменное заявление на возмещение вреда
                    </p>

                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                Подать письменное заявление в территориальное отделение полиции по месту угона.
                            </p>

                            <p style="text-align:justify;" class="mt-3">
                                Перечень документов для передачи в ОВД (довозятся после подачи письменного заявления для
                                возбуждения уголовного дела)
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>Письменное заявление на возмещение вреда (предоставить в течение 3 рабочих дней)
                                    </li>
                                    <li>Документы, удостоверяющие личность Страхователя(Выгодоприобретателя) или лица,
                                        являющегося представителем Страхователя (Паспорт гражданина)</li>
                                    <li>Свидетельство о регистрации ТС(Передается в оригинале)</li>
                                    <li>Паспорт транспортного средства (Передается в оригинале)</li>
                                    <li>Страховой полис (дополнительные соглашения к полису, если выпускались)</li>
                                    <li>Водительское удостоверение лица, управлявшего транспортным средством последний раз
                                        перед обнаружением хищения</li>
                                    <li>Доверенность на право управления транспортным средством или путевой лист</li>
                                    <li>Талон технического осмотра ТС</li>
                                    <li>Все комплекты ключей, брелоков, карточек активных и пассивных активаторов
                                        электронных и электронно-механических противоугонных систем (которыми было оснащено
                                        похищенное транспортное средство)</li>
                                    <li>Договор лизинга (копия)</li>
                                    <li>Копия акта приема-передачи к договору лизинга или копия договора купли-продажи</li>
                                    <li>Доверенность на представление интересов организации в страховой компании с правом
                                        подписи документов, выданная организацией своему представителю (выдается в Стоун по
                                        запросу клиента)</li>
                                    <li>Распорядительное письмо Выгодоприобретателя (выдается в Стоун по запросу клиента или
                                        Страховщика)</li>
                                    <li>Предоставить документы компетентных органов по мере получения документа в ОВД</li>

                                    <ul>
                                        <li>Талон уведомление (выдается в ОВД при подаче заявления)</li>
                                        <li>Постановление о возбуждении уголовного дела по факту хищения ТС (выдается
                                            ОВД в течение 10 дней с момента угона)</li>
                                        <li>Постановление о приостановлении предварительного следствия (выдается ОВД в
                                            течение 60 дней с момента возбуждения уголовного дела)</li>
                                        <li>Справка по форме 3 (выдается ОВД)</li>
                                        <li>Постановление о приобщении к уголовному делу вещественных доказательств (в
                                            случае приобщения к материалам уголовного дела необходимых для передачи
                                            Страховщику регистрационных документов, ключей и/или других материальных
                                            ценностей)</li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>Соглашение об отказе от прав собственности на похищенное ТС (выдается Страховщиком
                                        после проверки документов и признания случая Страховым для подписания со стороны
                                        Страхователя и/или Выгодоприобретателя)</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <p style="text-align:justify;">
                        Адрес указан в страховом полисе.
                        Телефон указан в страховом полисе.
                    </p>

                    <div class="pb-2"><b>6. Оповестить лизинговую компанию</b></div>

                    <p style="text-align:justify;">
                        Сообщить об угоне в лизинговую компанию, подать и получить необходимые документы
                    </p>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                Подать письменное заявление в территориальное отделение полиции по месту угона.
                            </p>

                            <p style="text-align:justify;" class="mt-3">
                                Перечень документов для передачи в ОВД (довозятся после подачи письменного заявления для
                                возбуждения уголовного дела)
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>Уведомительное письмо в свободной форме в ООО «СТОУН-XXI» о страховом событии с
                                        указанием исх.№, даты письма, номера договора лизинга, гос.номера ТС
                                        <a target="_blank"
                                            href="https://dev.asket.ru/GitLkApi/public/lk/download?type=sample&file=shablon_na_vydachu_dublikata_polisa.doc">
                                            Скачать шаблон уведомительного письма при ХИЩЕНИИ имущества
                                        </a>
                                        <br />
                                        (Передается в оригинале в течение 5 рабочих дней с момента наступления страхового
                                        случая)
                                    </li>

                                    <li>Постановление о возбуждении уголовного дела по факту хищения ТС (копия, передается
                                        после выдачи документа ОВД)
                                    </li>

                                    <li>Постановление о приостановлении предварительного следствия (копия, передается после
                                        выдачи документа ОВД)
                                    </li>
                                    <li>Решение Страховщика о выплате страхового возмещения (оригинал)
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Доверенность на представление интересов в страховой компании с правом подписи
                                        документов, выданная организацией своему представителю (выдается в Стоун по запросу
                                        клиента)
                                    </li>
                                    <li>
                                        Балансовая справка (выдается в СТОУН по запросу клиента для передачи в ОВД)
                                    </li>
                                    <li>
                                        Справка об остаточной стоимости ТС (выдается в СТОУН по запросу клиента для передачи
                                        в ОВД)
                                    </li>
                                    <li>
                                        Доверенность на представление интересов в ОВД (выдается в СТОУН по запросу клиента
                                        для передачи в ОВД)
                                    </li>
                                    <li>
                                        Устав компании и другие документы (при необходимости, по запросу клиента для
                                        передачи в ОВД)
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <p style="text-align:justify;">
                        117105, г.Москва, ул.Нагатинская, д.1
                        (495)981-19-90, доб.315 или доб.5463
                    </p>

                    <div class="pb-2"><b>7. Получить страховое возмещение</b></div>

                    <p style="text-align:justify;">
                        В течение порядка 30 дней после передачи последнего необходимого для выплаты документа Страховщику
                    </p>

                    <p style="text-align:justify;">
                        Получить страховое возмещение и произвести взаиморасчеты по договору лизинга.
                    </p>

                </div>
            </div>
        </div>



        <div ref="tab2" class="accordion-item" :class="tab === 2 && 'active'">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button @click="setTab(2)" class="accordion-button" :class="tab !== 2 && 'collapsed'">ДТП</button>
            </h2>
            <div class="accordion-collapse collapse" :class="tab === 2 && 'show'">

                <div class="accordion-body px-3" style="background: #fff;">

                    <div class="pb-2"><b>1. Первичные действия</b></div>

                    <div>
                        <ul>
                            <li>Немедленно остановиться и не трогать автомобиль с места</li>
                            <li>Включить аварийную сигнализацию</li>
                            <li>Выставить знак аварийной остановки (в городе – не менее, чем за 15м, вне населенного пункта
                                – не
                                менее, чем за 30м)</li>
                            <li>Не перемещать предметы, относящиеся к дтп</li>
                        </ul>
                    </div>

                    <div class="pb-2"><b>2. Вызвать сотрудников ГИБДД</b></div>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Свидетельство о регистрации ТС
                                    </li>

                                    <li>
                                        Водительское удостоверение
                                    </li>

                                    <li>
                                        Полис ОСАГО
                                    </li>
                                    <li>
                                        Путевой лист или доверенность на управление
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Выдаются на месте ДТП:
                                    </li>
                                    <ul>
                                        <li>Справка ГИБДД</li>
                                        <li>Протокол осмотра автомобилей с указанием всех повреждений</li>
                                    </ul>
                                    <p style="text-align:justify;">
                                        Если протокол не составлялся, это д.б. отражено в справке о ДТП.
                                    </p>
                                    <li>
                                        Выдаются в органах ГИБДД:
                                    </li>

                                    <ul>
                                        <li>Если вы не виноваты в аварии (без административного правонарушения)</li>
                                        <ul>
                                            <li>определение об отказе в возбуждении дела об административном правонарушении
                                            </li>
                                            <li>результат медицинского освидетельствования (если проводилось)</li>
                                        </ul>
                                    </ul>
                                    <ul>
                                        <li>Если вы виноваты в аварии (при совершении административного правонарушения)</li>
                                        <ul>
                                            <li>копия протокола об административном правонарушении</li>
                                            <li>копия постановления по делу об административном правонарушении</li>

                                            <p style="text-align:justify;">
                                                или копия определения о возбуждении дела об административном правонарушении
                                            </p>
                                            <li>результат медицинского освидетельствования (если проводилось)</li>
                                        </ul>
                                    </ul>


                                </ul>
                            </div>
                        </div>
                    </div>


                    <p style="text-align:justify;">
                        На месте происшествия
                        02 – со стационарного телефона 112 - экстренные службы с мобильного телефона 002 - для абонентов
                        Билайн 020 – для абонентов МТС, Мегафона (495) 937-99-11 – через Службу спасения (495) 624-31-17 –
                        через дежурного ГИБДД Москвы
                    </p>

                    <div class="pb-2"><b>3. Позвонить в Страховую компанию</b></div>

                    <p style="text-align:justify;">
                        Позвонить в диспетчерскую службу страховой компании для регистрации события, получения консультации,
                        вызова эвакуатора
                    </p>

                    <p style="text-align:justify;">
                        На месте происшествия.
                        Телефон указан в страховом полисе
                    </p>

                    <div class="pb-2"><b>4. Что делать не следует</b></div>

                    <ul>
                        <li>
                            Изменять картину происшествия (менять расположение транспортных средств, уничтожать осыпь стекла
                            и грязи и т.п.)
                        </li>

                        <li>
                            Еесли в результате аварии на дороге образовался затор, вы можете освободить проезжую часть, но
                            только после того, как составите схему, в которой в присутствии свидетелей зафиксируете
                            положение транспортных средств на месте дтп и запишете ф. и. о., адреса и телефоны
                        </li>

                    </ul>

                    <div class="pb-2"><b>5. Посетить страховую компанию</b></div>

                    <ul>
                        <li>
                            Подать письменное заявление
                        </li>

                        <li>
                            Предъявить автомобиль на осмотр
                        </li>

                    </ul>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                Подать документы
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Письменное заявление на возмещение вреда (Предоставить в течение 5 рабочих дней)
                                    </li>

                                    <li>
                                        Документы, удостоверяющие личность Страхователя (Выгодоприобретателя) или лица,
                                        являющегося представителем Страхователя (Паспорт гражданина)
                                    </li>

                                    <li>
                                        Свидетельство о регистрации ТСи/илиПаспорт транспортного средства
                                    </li>
                                    <li>
                                        Страховой полис (дополнительные соглашения к полису если выпускались)
                                    </li>
                                    <li>
                                        Водительское удостоверение лица, управлявшего транспортным средством в момент
                                        наступления ДТП
                                    </li>
                                    <li>
                                        Доверенность на право управления транспортным средством или путевой лист
                                    </li>
                                    <li>
                                        Талон технического осмотра ТС
                                    </li>
                                    <li>
                                        Для юридического лица - доверенность на представление интересов организации в
                                        страховой компании с правом подписи документов (выдается в Стоун по запросу клиента)
                                    </li>
                                    <li>
                                        Если транспортное средство находится в лизинге – предоставить договор лизинга
                                        (копия)
                                    </li>
                                    <li>
                                        Копия акта приема-передачи к договору лизинга или копия договора купли-продажи
                                    </li>
                                    <li>
                                        Распорядительное письмо Выгодоприобретателя (выдается в Стоун по запросу клиента)
                                    </li>

                                    <li>
                                        Документы из компетентных органов
                                    </li>

                                    <ul>
                                        <li>
                                            Справка ГИБДД
                                        </li>
                                        <li>
                                            Протокол осмотра автомобилей с указанием всех повреждений
                                        </li>
                                    </ul>

                                    <p style="text-align:justify;">
                                        Если протокол не составлялся, это д.б. отражено в справке о ДТП.
                                    </p>

                                    <li>
                                        Если вы не виноваты в аварии (без административного правонарушения)
                                    </li>

                                    <ul>
                                        <li>
                                            определение об отказе в возбуждении дела об административном правонарушении
                                        </li>
                                        <li>
                                            результат медицинского освидетельствования (если проводилось)
                                        </li>
                                    </ul>

                                    <li>
                                        Если вы виноваты в аварии (при совершении административного правонарушения)
                                    </li>

                                    <ul>
                                        <li>
                                            копия протокола об административном правонарушении
                                        </li>
                                        <li>
                                            копия постановления по делу об административном правонарушении
                                        </li>
                                    </ul>

                                    <p style="text-align:justify;">
                                        или копия определения о возбуждении дела об административном правонарушении
                                    </p>

                                    <ul>
                                        <li>
                                            результат медицинского освидетельствования (если проводилось)
                                        </li>
                                    </ul>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <p style="text-align:justify;">В течение 5 календарных дней</p>


                    <p style="text-align:justify;">
                        Адрес подачи заявления уточняется у Страховой компании
                        Телефон указан в страховом полисе
                    </p>

                    <p style="text-align:justify;"><b>6. Посетить лизинговую компанию</b></p>

                    <ul>
                        <li>
                            Получить доверенность на представление интересов по страховому случаю от собственника ТС
                        </li>
                        <li>
                            При подозрении на возможную тотальную гибель ТС подать уведомление о страховом случае.
                        </li>
                    </ul>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                В случае частичного повреждения транспортного средства – предоставить копию паспорта
                                представителя лизингополучателя (для подготовки доверенности на ведение дел в страховой
                                компании)
                            </p>

                            <p style="text-align:justify;">
                                В случае полного уничтожения транспортного средства в результате ДТП (тотальная гибель ТС)
                                подать:
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Уведомительное письмо в свободной форме в ООО «СТОУН-XXI» о страховом событии с
                                        указанием исх.№, даты письма, номера договора лизинга, гос.номера ТС (Передается в
                                        оригинале в течение 5 рабочих дней с момента наступления страхового случая)
                                    </li>

                                    <li>
                                        Справка о ДТП (копия)
                                    </li>

                                    <li>
                                        Решение страховой компании о признании страхового случая с указанием стоимости
                                        годных остатков ТС (копия)
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Доверенность на представление интересов в страховой компании с правом подписи
                                        документов, выданная организацией своему представителю (выдается в Лизинговой
                                        компании по запросу клиента)
                                    </li>

                                    <li>
                                        Распорядительное письмо с указанием формы страхового возмещения (выдается в
                                        Лизинговой компании по запросу клиента)
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <p style="text-align:justify;">
                        В течение 5 рабочих дней после ДТП
                    </p>

                    <p style="text-align:justify;">
                        117105, г.Москва, ул.Нагатинская, д.1
                        (495)981-19-90, доб.315 или доб.5463
                    </p>

                    <p style="text-align:justify;"><b>7. Получить страховую выплату</b></p>

                    <p style="text-align:justify;">
                        Получить направление на ремонт или страховую выплату
                    </p>

                    <p style="text-align:justify;">
                        В сроки согласно правилам страхования Страховой компании
                    </p>


                </div>
            </div>
        </div>



        <div ref="tab3" class="accordion-item" :class="tab === 3 && 'active'">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button @click="setTab(3)" class="accordion-button" :class="tab !== 3 && 'collapsed'">ПОЖАР</button>
            </h2>
            <div class="accordion-collapse collapse" :class="tab === 3 && 'show'">

                <div class="accordion-body px-3" style="background: #fff;">


                    <p style="text-align:justify;"><b>1. Вызвать пожарных</b></p>

                    <ul>
                        <li>
                            При пожаре – позвонить в отделение пожарной охраны
                        </li>
                        <li>
                            Заявить в ближайшее территориальное ОВД
                        </li>
                        <li>
                            Принять меры к тушению пожара и действовать в соответствии с рекомендациями пожарных
                        </li>
                    </ul>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Свидетельство о регистрации ТС
                                    </li>

                                    <li>
                                        Водительское удостоверение
                                    </li>

                                    <li>
                                        Полис ОСАГО
                                    </li>

                                    <li>
                                        Путевой лист или доверенность на управление
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <p style="text-align:justify;">
                                Документы из ОВД и Государственной пожарной инспекции в случае пожара или взрыва
                            </p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Акт о пожаре (составляется на месте происшествия)
                                    </li>

                                    <li>
                                        Талон-уведомление (Выдается в ОВД при подаче заявления)
                                    </li>

                                    <li>
                                        Справка из ОВД или управления пожарной охраны с указанием причины пожара, марки,
                                        модели, гос.рег.знака, даты, перечня повреждений (оригинал)
                                    </li>

                                    <li>
                                        Копия постановления о возбуждении (отказе в возбуждении) уголовного дела по факту
                                        пожара с указанием причины возгорания, характера и объема повреждений, заверенная
                                        печатью и подписью следователя (дознавателя)
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>



                    <p style="text-align:justify;">
                        Сразу после обнаружения происшествия
                    </p>

                    <p style="text-align:justify;">
                        Противопожарная служба: тел.: 01 – со стационарного телефона Экстренная служба: тел.:010, 112 или
                        911 – с мобильного телефона.
                    </p>

                    <p style="text-align:justify;"><b>2. Позвонить в страховую компанию</b></p>

                    <p style="text-align:justify;">
                        Позвонить в диспетчерскую службу Страховой компании для регистрации события, получения консультации,
                        вызова эвакуатора
                    </p>

                    <p style="text-align:justify;">
                        Сразу после обнаружения происшествия
                    </p>

                    <p style="text-align:justify;">
                        Телефон указан в страховом полисе
                    </p>

                    <p style="text-align:justify;"><b>3. Посетить страховую компанию</b></p>

                    <ul>
                        <li>
                            Подать письменное заявление
                        </li>
                        <li>
                            Предъявить автомобиль на осмотр (Для организации помощи в эвакуации ТС обращайтесь в
                            контакт-центр Страховой компании, возможно также, что специалист Страховщика выедет на осмотр к
                            вам)
                        </li>
                    </ul>



                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Письменное заявление на возмещение вреда (Предоставить в течение 5 рабочих дней)
                                    </li>

                                    <li>
                                        Документы, удостоверяющие личность Страхователя (Выгодоприобретателя) или лица,
                                        являющегося представителем Страхователя (Паспорт гражданина)
                                    </li>

                                    <li>
                                        Свидетельство о регистрации ТСи/илиПаспорт транспортного средства
                                    </li>

                                    <li>
                                        Страховой полис (дополнительные соглашения к полису если выпускались)
                                    </li>

                                    <li>
                                        Водительское удостоверение лица, управлявшего транспортным средством в момент
                                        наступления ДТП
                                    </li>

                                    <li>
                                        Доверенность на право управления транспортным средством или путевой лист
                                    </li>

                                    <li>
                                        Талон технического осмотра ТС
                                    </li>

                                    <li>
                                        Для юридического лица - доверенность на представление интересов организации в
                                        страховой компании с правом подписи документов (выдается в Стоун по запросу клиента)
                                    </li>

                                    <li>
                                        Если транспортное средство находится в лизинге – предоставить договор лизинга
                                        (копия)
                                    </li>

                                    <li>
                                        Копия акта приема-передачи к договору лизинга или копия договора купли-продажи
                                    </li>

                                    <li>
                                        Распорядительное письмо Выгодоприобретателя (выдается в Стоун по запросу клиента)
                                    </li>

                                    <li>
                                        Документы из компетентных органов
                                    </li>

                                    <ul>
                                        <li>Акт о пожаре (составляется на месте происшествия)</li>
                                        <li>Талон-уведомление (Выдается в ОВД при подаче заявления)</li>
                                        <li>Справка из ОВД или управления пожарной охраны с указанием причины пожара, марки,
                                            модели, гос.рег.знака, даты, перечня повреждений (оригинал)</li>
                                        <li>Копия постановления о возбуждении (отказе в возбуждении) уголовного дела по
                                            факту пожара с указанием причины возгорания, характера и объема повреждений,
                                            заверенная печатью и подписью следователя (дознавателя)</li>
                                    </ul>

                                </ul>
                            </div>
                        </div>

                    </div>


                    <p style="text-align:justify;">
                        В течение 5 календарных дней
                    </p>

                    <p style="text-align:justify;">
                        Адрес подачи заявления уточняется у Страховой компании
                        Телефон указан в страховом полисе
                    </p>


                    <p style="text-align:justify;"><b>4. Посетить лизинговую компанию</b></p>

                    <p style="text-align:justify;">
                        Сообщить о страховом случае в лизинговую компанию:
                    </p>

                    <ul>
                        <li>Получить доверенность на представление интересов по страховому случаю от собственника ТС</li>
                        <li>При подозрении на возможную тотальную гибель ТС подать уведомление о страховом случае</li>
                    </ul>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                В случае частичного повреждения транспортного средства – предоставить копию паспорта
                                представителя лизингополучателя (для подготовки доверенности на ведение дел в страховой
                                компании)
                            </p>

                            <p style="text-align:justify;">
                                В случае полного уничтожения транспортного средства в результате пожара (тотальная гибель
                                ТС) подать
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Уведомительное письмо в свободной форме в ООО «СТОУН-XXI» о страховом событии с
                                        указанием исх.№, даты письма, номера договора лизинга, гос.номера ТС (Передается в
                                        оригинале в течение 5 рабочих дней с момента наступления страхового случая)
                                        <a target="_blank"
                                            href="https://dev.asket.ru/GitLkApi/public/lk/download?type=sample&file=shablon_na_vydachu_dublikata_polisa.doc">
                                            Скачать шаблон уведомительного письма при ТОТАЛЬНОЙ ГИБЕЛИ имущества
                                        </a>
                                    </li>

                                    <li>
                                        Справка из ОВД или управления пожарной охраны с указанием причины пожара, марки,
                                        модели, гос.рег.знака, даты, перечня повреждений (копия)
                                    </li>

                                    <li>
                                        Решение страховой компании о признании страхового случая с указанием стоимости
                                        годных остатков ТС (копия)
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Доверенность на представление интересов в страховой компании с правом подписи
                                        документов, выданная организацией своему представителю (выдается в Стоун по запросу
                                        клиента)
                                    </li>

                                    <li>
                                        Распорядительное письмо с указанием формы страхового возмещения (выдается в Стоун по
                                        запросу клиента)
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>


                    <p style="text-align:justify;">
                        В течение 5 рабочих дней
                    </p>

                    <p style="text-align:justify;">
                        117105, г.Москва, ул.Нагатинская, д.1
                        (495)981-19-90, доб.315 или доб.5463
                    </p>


                    <p style="text-align:justify;"><b>5. Получить страховую выплату</b></p>

                    <p style="text-align:justify;">
                        Получить направление на ремонт или страховую выплату
                    </p>

                    <p style="text-align:justify;">
                        В сроки согласно правилам страхования Страховой компании
                    </p>



                </div>
            </div>
        </div>



        <div ref="tab4" class="accordion-item" :class="tab === 4 && 'active'">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button @click="setTab(4)" class="accordion-button" :class="tab !== 4 && 'collapsed'">СТИХИЙНОЕ БЕДСТВИЕ ИЛИ
                    ПАДЕНИЕ ПРЕДМЕТА</button>
            </h2>
            <div class="accordion-collapse collapse" :class="tab === 4 && 'show'">

                <div class="accordion-body px-3" style="background: #fff;">


                    <p style="text-align:justify;"><b>1. Обратиться в органы МЧС РФ</b></p>


                    <ul>
                        <li>
                            При стихийном бедствии обратиться в органы МЧС РФ или в районное отделение Росгидрометеоцентра
                        </li>
                        <li>
                            Вызвать сотрудников ГИБДД (если происшествие произошло в момент движения ТС) или сотрудников ОВД
                            (если происшествие произошло не на дороге)
                        </li>
                    </ul>

                    <p style="text-align:justify;">
                        Если неблагоприятное событие наступило во время движения автомобиля, то необходимо остановиться,
                        включить аварийную световую сигнализацию, выставить знак аварийной остановки. Дождаться сотрудников
                        инспекций
                    </p>



                    <div class="row">
                        <div class="col-md-6 offset-md-6 ">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <p style="text-align:justify;">
                                Документы из ОВД и МЧС РФ или Росгидрометеослужбы в случае стихийного бедствия
                            </p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Справка по форме №3 (Подтверждающая факт обращения в ОВД с указанием полного перечня
                                        поврежденных элементов ТС. Выдается по запросу Страховой компании)
                                    </li>

                                    <li>
                                        Протокол происшествия (оригинал)
                                    </li>

                                    <li>
                                        Справка Росгидрометеоцентра - с указанием перечня поврежденных деталей ТС(оригинал)
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>



                    <p style="text-align:justify;">
                        Сразу после обнаружения происшествия
                    </p>


                    <p style="text-align:justify;">
                        112 или 911 – с мобильного телефона.
                    </p>


                    <p style="text-align:justify;"><b>2. Позвонить в страховую компанию</b></p>

                    <p style="text-align:justify;">
                        Позвонить в диспетчерскую службу Страховой компании для регистрации события, получения консультации,
                        вызова эвакуатора
                    </p>

                    <p style="text-align:justify;">
                        Сразу после обнаружения происшествия
                    </p>

                    <p style="text-align:justify;">
                        Телефон указан в страховом полисе
                    </p>


                    <p style="text-align:justify;"><b>3. Посетить страховую компанию</b></p>

                    <ul>
                        <li>Подать письменное заявление</li>
                        <li>Предъявить автомобиль на осмотр (Для организации помощи в эвакуации ТС обращайтесь в
                            контакт-центр Страховой компании, возможно также, что специалист Страховщика выедет на осмотр к
                            вам)</li>
                    </ul>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                В случае частичного повреждения транспортного средства – предоставить копию паспорта
                                представителя лизингополучателя (для подготовки доверенности на ведение дел в страховой
                                компании)
                            </p>

                            <p style="text-align:justify;">
                                В случае полного уничтожения транспортного средства в результате пожара (тотальная гибель
                                ТС) подать
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Письменное заявление на возмещение вреда (Предоставить в течение 5 рабочих дней)
                                    </li>

                                    <li>
                                        Документы, удостоверяющие личность Страхователя (Выгодоприобретателя) или лица,
                                        являющегося представителем Страхователя (Паспорт гражданина)
                                    </li>

                                    <li>
                                        Свидетельство о регистрации ТСи/илиПаспорт транспортного средства
                                    </li>

                                    <li>
                                        Страховой полис (дополнительные соглашения к полису если выпускались)
                                    </li>

                                    <li>
                                        Водительское удостоверение лица, управлявшего транспортным средством в момент
                                        наступления ДТП
                                    </li>

                                    <li>
                                        Доверенность на право управления транспортным средством или путевой лист
                                    </li>

                                    <li>
                                        Талон технического осмотра ТС
                                    </li>

                                    <li>
                                        Для юридического лица - доверенность на представление интересов организации в
                                        страховой компании с правом подписи документов (выдается в Стоун по запросу клиента)
                                    </li>


                                    <li>
                                        Если транспортное средство находится в лизинге – предоставить договор лизинга
                                        (копия)
                                    </li>

                                    <li>
                                        Копия акта приема-передачи к договору лизинга или копия договора купли-продажи
                                    </li>

                                    <li>
                                        Распорядительное письмо Выгодоприобретателя (выдается в Стоун по запросу клиента)
                                    </li>

                                    <li>
                                        Документы из компетентных органов
                                    </li>

                                    <ul>
                                        <li>Справка по форме №3 (Подтверждающая факт обращения в ОВД с указанием полного
                                            перечня поврежденных элементов ТС. Выдается по запросу Страховой компании)</li>
                                        <li>
                                            Протокол происшествия (оригинал)
                                        </li>
                                        <li>
                                            Справка Росгидрометеоцентра - с указанием перечня поврежденных деталей
                                            ТС(оригинал)
                                        </li>
                                    </ul>

                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Получить запрос на получение справки формы №3 в ОВД
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <p style="text-align:justify;">
                        В течение 5 календарных дней
                    </p>

                    <p style="text-align:justify;">
                        Адрес подачи заявления уточняется у Страховой компании
                        Телефон указан в страховом полисе
                    </p>



                    <p style="text-align:justify;"><b>4. Оповестить лизинговую компанию</b></p>

                    <ul>
                        <li>Получить доверенность на представление интересов по страховому случаю от собственника ТС</li>
                        <li>При подозрении на возможную тотальную гибель ТС подать уведомление о страховом случае</li>
                    </ul>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                случае частичного повреждения транспортного средства – предоставить копию паспорта
                                представителя лизингополучателя (для подготовки доверенности на ведение дел в страховой
                                компании)
                            </p>

                            <p style="text-align:justify;">
                                В случае полного уничтожения транспортного средства в результате стихийного бедствия
                                (тотальная гибель ТС) подать
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Уведомительное письмо в свободной форме в ООО «СТОУН-XXI» о страховом событии с
                                        указанием исх.№, даты письма, номера договора лизинга, гос.номера ТС (Передается в
                                        оригинале в течение 5 рабочих дней с момента наступления страхового случая)
                                        <a target="_blank"
                                            href="https://dev.asket.ru/GitLkApi/public/lk/download?type=sample&file=shablon_na_vydachu_dublikata_polisa.doc">
                                            Скачать шаблон уведомительного письма при ТОТАЛЬНОЙ ГИБЕЛИ имущества
                                        </a>
                                    </li>

                                    <li>
                                        Справка Росгидрометеоцентра с указанием перечня поврежденных деталей ТС(копия)
                                    </li>

                                    <li>
                                        Решение страховой компании о признании страхового случая с указанием стоимости
                                        годных остатков ТС (копия)
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Доверенность на представление интересов в страховой компании с правом подписи
                                        документов, выданная организацией своему представителю (выдается в Стоун по запросу
                                        клиента)
                                    </li>
                                    <li>
                                        Распорядительное письмо с указанием формы страхового возмещения (выдается в Стоун по
                                        запросу клиента)
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>


                    <p style="text-align:justify;">
                        В течение 5 рабочих дней
                    </p>

                    <p style="text-align:justify;">
                        117105, г.Москва, ул.Нагатинская, д.1
                        (495)981-19-90, доб.315 или доб.5463
                    </p>


                    <p style="text-align:justify;"><b>5. Получить страховую выплату</b></p>


                    <p style="text-align:justify;">
                        Получить направление на ремонт или страховую выплату
                    </p>

                    <p style="text-align:justify;">
                        В сроки согласно правилам страхования Страховой компании
                    </p>


                </div>
            </div>
        </div>




        <div ref="tab5" class="accordion-item" :class="tab === 5 && 'active'">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button @click="setTab(5)" class="accordion-button" :class="tab !== 5 && 'collapsed'">ПОВРЕЖДЕНИЕ
                    НЕУСТАНОВЛЕННЫМИ ЛИЦАМИ</button>
            </h2>
            <div class="accordion-collapse collapse" :class="tab === 5 && 'show'">

                <div class="accordion-body px-3" style="background: #fff;">

                    <p style="text-align:justify;"><b>1. Вызвать сотрудников ОВД</b></p>


                    <p style="text-align:justify;">
                        Позвонить в территориальное отделение полиции и вызвать сотрудника ОВД на место происшествия
                    </p>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Свидетельство о регистрации ТС
                                    </li>

                                    <li>
                                        Водительское удостоверение
                                    </li>

                                    <li>
                                        Полис ОСАГО
                                    </li>

                                    <li>
                                        Путевой лист или доверенность на управление
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Талон-уведомление (Выдается в ОВД при подаче заявления)
                                    </li>
                                    <li>
                                        Справка по форме №3 (Подтверждающая факт обращения в ОВД с указанием полного перечня
                                        поврежденных/украденных элементов ТС)
                                    </li>

                                    <li>
                                        Копия постановления о возбуждении (отказе в возбуждении) уголовного дела по факту
                                        ПДТЛ, заверенная печатью и подписью следователя (дознавателя)
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>


                    <p style="text-align:justify;">
                        Сразу после обнаружения повреждений
                    </p>

                    <p style="text-align:justify;">
                        02, 112, 911 (для звонка с мобильного)
                    </p>


                    <p style="text-align:justify;"><b>2. Позвонить в страховую компанию</b></p>


                    <p style="text-align:justify;">
                        Позвонить в диспетчерскую службу Страховой компаниидля регистрации события, получения консультации,
                        вызова эвакуатора
                    </p>


                    <p style="text-align:justify;">
                        Сразу после обнаружения повреждений
                    </p>


                    <p style="text-align:justify;">
                        Телефон указан в страховом полисе
                    </p>


                    <p style="text-align:justify;"><b>3. Посетить страховую компанию</b></p>

                    <ul>
                        <li>Подать письменное заявление</li>
                        <li>Предъявить автомобиль на осмотр (Для организации помощи в эвакуации ТС обращайтесь в
                            контакт-центр Страховой компании, возможно также, что специалист Страховщика выедет на осмотр к
                            вам)</li>
                    </ul>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                В случае частичного повреждения транспортного средства – предоставить копию паспорта
                                представителя лизингополучателя (для подготовки доверенности на ведение дел в страховой
                                компании)
                            </p>

                            <p style="text-align:justify;">
                                В случае полного уничтожения транспортного средства в результате пожара (тотальная гибель
                                ТС) подать
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Письменное заявление на возмещение вреда (Предоставить в течение 5 рабочих дней)
                                    </li>

                                    <li>
                                        Документы, удостоверяющие личность Страхователя (Выгодоприобретателя) или лица,
                                        являющегося представителем Страхователя (Паспорт гражданина)
                                    </li>

                                    <li>
                                        Свидетельство о регистрации ТСи/илиПаспорт транспортного средства
                                    </li>

                                    <li>
                                        Страховой полис (дополнительные соглашения к полису если выпускались)
                                    </li>

                                    <li>
                                        Водительское удостоверение лица, управлявшего транспортным средством в момент
                                        наступления ДТП
                                    </li>

                                    <li>
                                        Доверенность на право управления транспортным средством или путевой лист
                                    </li>

                                    <li>
                                        Талон технического осмотра ТС
                                    </li>

                                    <li>
                                        Для юридического лица - доверенность на представление интересов организации в
                                        страховой компании с правом подписи документов (выдается в Стоун по запросу клиента)
                                    </li>


                                    <li>
                                        Если транспортное средство находится в лизинге – предоставить договор лизинга
                                        (копия)
                                    </li>

                                    <li>
                                        Копия акта приема-передачи к договору лизинга или копия договора купли-продажи
                                    </li>

                                    <li>
                                        Распорядительное письмо Выгодоприобретателя (выдается в Стоун по запросу клиента)
                                    </li>

                                    <li>
                                        Документы из компетентных органов
                                    </li>

                                    <ul>
                                        <li>Справка по форме №3 (Подтверждающая факт обращения в ОВД с указанием полного
                                            перечня поврежденных элементов ТС. Выдается по запросу Страховой компании)</li>
                                        <li>
                                            Протокол происшествия (оригинал)
                                        </li>
                                        <li>
                                            Справка Росгидрометеоцентра - с указанием перечня поврежденных деталей
                                            ТС(оригинал)
                                        </li>
                                    </ul>

                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Получить запрос на получение справки формы №3 в ОВД
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <p style="text-align:justify;">
                        В течение 5 календарных дней
                    </p>

                    <p style="text-align:justify;">
                        Адрес подачи заявления уточняется у Страховой компании
                        Телефон указан в страховом полисе
                    </p>

                    <p style="text-align:justify;"><b>4. Посетить лизинговую компанию</b></p>

                    <p style="text-align:justify;">
                        Сообщить о страховом случае в лизинговую компанию
                    </p>

                    <ul>
                        <li>Получить доверенность на представление интересов по страховому случаю от собственника ТС</li>
                        <li>При подозрении на возможную тотальную гибель ТС подать уведомление о страховом случае</li>
                    </ul>


                    <div class="row">
                        <div class="col-md-6">
                            <p style="text-align:justify;">
                                <b>Предъявить</b>
                            </p>

                            <p style="text-align:justify;">
                                В случае частичного повреждения транспортного средства – предоставить копию паспорта
                                представителя лизингополучателя (для подготовки доверенности на ведение дел в страховой
                                компании)
                            </p>

                            <p style="text-align:justify;">
                                В случае полного уничтожения транспортного средства в результате ПДТЛ (тотальная гибель ТС)
                                подать
                            </p>

                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Уведомительное письмо в свободной форме в ООО «СТОУН-XXI» о страховом событии с
                                        указанием исх.№, даты письма, номера договора лизинга, гос.номера ТС (Передается в
                                        оригинале в течение 5 рабочих дней с момента наступления страхового случая)
                                    </li>
                                    <li>
                                        Справка по форме №3 (Подтверждающая факт обращения в ОВД с указанием полного перечня
                                        поврежденных/украденных элементов ТС) (копия)
                                    </li>
                                    <li>
                                        Решение страховой компании о признании страхового случая с указанием стоимости
                                        годных остатков ТС (копия)
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <p style="text-align:justify;"><b>Получить</b></p>
                            <div class="mt-3" style="text-align:justify;">
                                <ul>
                                    <li>
                                        Доверенность на представление интересов в страховой компании с правом подписи
                                        документов, выданная организацией своему представителю (выдается в Стоун по запросу
                                        клиента)
                                    </li>
                                    <li>
                                        Распорядительное письмо с указанием формы страхового возмещения (выдается в Стоун по
                                        запросу клиента)
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <p style="text-align:justify;">
                        В течение 5 рабочих дней
                    </p>

                    <p style="text-align:justify;">
                        117105, г.Москва, ул.Нагатинская, д.1
                        (495)981-19-90, доб.315 или доб.5463
                    </p>

                    <p style="text-align:justify;"><b>5. Получить страховую выплату</b></p>

                    <p style="text-align:justify;">
                        Получить направление на ремонт или страховую выплату
                    </p>

                    <p style="text-align:justify;">
                        В сроки согласно правилам страхования Страховой компании
                    </p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'insurance.info',
    data() {
        return {
            tab: null
        }
    },
    methods: {
        setTab(tab) {
            this.tab = tab === this.tab ? null : tab

            setTimeout(() => {
                const top = this.$refs['tab'+tab].offsetTop;;
                window.scrollTo(0, top);
            }, 0)
        }
    }
}

</script>